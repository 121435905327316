import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DataGridPro, GridColDef, gridVisibleSortedRowIdsSelector } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { randomId } from '@mui/x-data-grid-generator';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CommentIcon from '@mui/icons-material/Comment';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { ContextFilter } from "context/ContextFilter";
import {isInternalRole, isPortRole, isCustomerRole} from 'components/config/Roles';
import {checkSecurity} from 'components/config/Security';
import ExportPopin from '../ExportPopin'
import axios from 'api/axios';

const GETMISSIONLIST_URL = 'mission/list';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PortMissionsList(props) {

  const [JLBInternalMode] = React.useState(isInternalRole(localStorage.getItem("role")));
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const {visibleRows, setVisibleRows} = useContext(ContextFilter);
  const {isDownload, setIsDownload} = useContext(ContextFilter);
  const [isExportPopinOpen, setIsExportPopinOpen] = React.useState(false);
  const exportPopinPortRef = useRef("exportPopinPort");

  const {loadingGrid, setLoadingGrid} = useContext(ContextFilter);
  const {setDataGridLoaded} = useContext(ContextFilter);
  
  const {filterModel} = useContext(ContextFilter);
  const {filterDatas} = useContext(ContextFilter);
  const {filterStatusArray} = useContext(ContextFilter);
  const {category} = useContext(ContextFilter);
  const {searchTxt} = useContext(ContextFilter);

  const {rows, setRows} = useContext(ContextFilter);
  const {setTotalRows} = useContext(ContextFilter);
  const {vesselRows, setVesselRows} = useContext(ContextFilter);
  const {surveyRows, setSurveyRows} = useContext(ContextFilter);
  const {principalRows, setPrincipalRows} = useContext(ContextFilter);
  const {missionRows, setMissionRows} = useContext(ContextFilter);
  const {portRows, setPortRows} = useContext(ContextFilter);
  const {insuredRows, setInsuredRows} = useContext(ContextFilter);
  const {setShipperRows} = useContext(ContextFilter);
  const {receiverRows, setReceiverRows} = useContext(ContextFilter);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsDownload(false);
    setOpenSnackBar(false);
  };


  // CHECK SECURITY
  const navigate = useNavigate();

  useEffect(() => {
    checkSecurity(navigate, "PortDashboard");
    getMissionList();
  }, []);
  // END SECURITY

  const RenderType = (props) => {
    const { value } = props;
    return (<div className="typeMissionGridColor" style={{backgroundColor: value}}></div>
    );
  };

  RenderType.propTypes = {
    /**
     * If true, the cell is the active element.
     */
    hasFocus: PropTypes.bool.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.instanceOf(Number),
  };

  const RenderNotif = (props) => {
    var notif = false;
    if (typeof props != "undefined") notif = props;
    if(notif){
      return (
        <span className="puceNotifBorder">
          <Badge className="puceNotif" badgeContent="" variant="dot" overlap="circular"></Badge>
        </span>
      );
    }
  };

  const RenderStatus = (props) => {
    return (<div className="statusMissionGridColor" style={{backgroundColor: props.row.status.statut_color}}>
      {props.row.status.statut_type}
      {(JLBInternalMode) && (RenderNotif(props.row.has_unread_internal_notification))}
    </div>);
  };

  const RenderDate = (props) => {
    const { created } = props.row;
    return (moment(created).format("DD MMM YY"));
  };

  const RenderReceiver = (props) => {
    var txt = "";
    var prefix = "";

    for(var i=0; i < props.row.receiverSearch.length; i++) {
      txt = txt + prefix + props.row.receiverSearch[i];
      prefix = "\n\r";
    }

    if (props.row.receiverSearch.length > 0)
    {
      return (<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <IconButton>
                    <FormatListBulletedIcon />
                  </IconButton>
              </Tooltip>);
    }
    else return (<></>);
  };

  const RenderBL= (props) => {
    var txt = "";
    var prefix = "";

    for(var i=0; i < props.row.b_o_l.length; i++) {
      txt = txt + prefix + props.row.b_o_l[i];
      prefix = "\n\r";
    }

    if (props.row.b_o_l.length > 0)
    {
      return (<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <IconButton>
                    <FormatListBulletedIcon />
                  </IconButton>
              </Tooltip>);
    }
    else return (<></>);
  };

  const RenderCommentInternal = (props) => {
    var txt = "";
    if (props.row.internal_note) txt = props.row.internal_note;

    if (txt.length > 0)
    {
      return(<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <CommentIcon fontSize="small" />
             </Tooltip>
      );
    }
    else return (<></>);
  }

  const RenderCommentPublic = (props) => {
    var txt = "";
    if (props.row.public_note) txt = props.row.public_note;

    if (txt.length > 0)
    {
      return(<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <CommentIcon fontSize="small" />
             </Tooltip>
      );
    }
    else return (<></>);
  }

  function getStatusId(params) {
    return `${params.row.status.statut_type}`;
  }

  const [refJLBTXTLabel, setRefJLBTXTLabel] = useState('Ref JLB');
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: !props?.visibleColumns?.idColumn,
    },
    {
      field: 'survey_handler',
      headerName: 'Survey handler',
      hide: !props?.visibleColumns?.surveyHandlerColumn,
    },
    {
      field: 'missionColor',
      headerName: '',
      sortable: false,
      flex:1,
      width:5,
      minWidth:5,
      maxWidth:5,
      padding:0,
      type:'number',
      renderCell: RenderType,
      hide: !props?.visibleColumns?.missionColorColumn,
    },
    {
      field: 'ref',
      headerName: 'Ref JLB',
      width:145,
      hide: !props?.visibleColumns?.refJLBColumn,
    },
    {
      field: 'internal_note',
      headerName: '',
      sortable: false,
      flex:1,
      width:40,
      minWidth:40,
      maxWidth:40,
      padding:0,
      renderCell: RenderCommentInternal,
      hide: isCustomerRole(localStorage.getItem("role")) ? true : !props?.visibleColumns?.internalNoteColumn,
    },
    {
      field: 'public_note',
      headerName: '',
      sortable: false,
      flex:1,
      width:40,
      minWidth:40,
      maxWidth:40,
      padding:0,
      renderCell: RenderCommentPublic,
      hide: isCustomerRole(localStorage.getItem("role")) ? false : !props?.visibleColumns?.publicNoteColumn,
    },
    {
      field: 'missionType',
      headerName: 'Mission Type',
      type:'number',
      hide: !props?.visibleColumns?.missionTypeColumn,
    },
    {
      field: 'missionTypeLabel',
      headerName: 'Type of mission',
      width:170,
      hide: isCustomerRole(localStorage.getItem("role")) ? false : !props?.visibleColumns?.missionTypeLabelColumn,
    },
    {
      field: 'created',
      headerName: 'Creation date',
      width:110,
      type:'date',
      align:'center',
      renderCell: RenderDate,
      hide: !props?.visibleColumns?.createdDateColumn,
    },
    {
      field: 'status',
      headerName: 'Status',
      width:64,
      type:'number',
      align:'center',
      renderCell: RenderStatus,
      valueGetter: getStatusId,
      hide: !props?.visibleColumns?.statusColumn,
    },
    {
      field: 'port',
      headerName: 'Port',
      flex:1,
      minWidth:110,
      maxWidth:150,
      hide: !props?.visibleColumns?.portColumn,
    },
    {
      field: 'principals',
      headerName: 'Principals',
      flex:1,
      minWidth:140,
      hide: isCustomerRole(localStorage.getItem("role")) ? true : !props?.visibleColumns?.principalsColumn,
    },
    {
      field: 'principalsRef',
      headerName: 'Your réf.',
      flex:1,
      minWidth:140,
      hide: isCustomerRole(localStorage.getItem("role")) ? false : !props?.visibleColumns?.principalsRefColumn,
    },
    {
      field: 'insured',
      headerName: 'Insured',
      flex:1,
      minWidth:140,
      hide: !props?.visibleColumns?.insuredColumn,
    },
    {
      field: 'vessel',
      headerName: 'Vessel',
      flex:1,
      minWidth:140,
      hide: !props?.visibleColumns?.vesselColumn,
    },
    {
      field: 'tonnage',
      headerName: 'Tonnage',
      flex:0.7,
      minWidth:100,
      type:'number',
      hide: !props?.visibleColumns?.tonnageColumn,
    },
    {
      field: 'receiver',
      headerName: 'Receiver',
      flex:1,
      minWidth:80,
      sortable: false,
      renderCell: RenderReceiver,
      hide: !props?.visibleColumns?.receiverColumn,
    },
    {
      field: 'receiverSearch',
      headerName: 'Receiver search',
      flex:1,
      minWidth:100,
      sortable: false,
      hide: !props?.visibleColumns?.receiverSearchColumn,
    },
    {
      field: 'cargo',
      headerName: 'Cargo',
      flex:1,
      minWidth:100,
      sortable: false,
      hide: !props?.visibleColumns?.cargoColumn,
    },
    {
      field: 'trader',
      headerName: 'Trader',
      flex:1,
      minWidth:100,
      sortable: false,
      hide: !props?.visibleColumns?.traderColumn,
    },
    {
      field: 'shipper',
      headerName: 'Shipper',
      flex:1,
      minWidth:100,
      sortable: false,
      hide: !props?.visibleColumns?.shipperColumn,
    },
    {
      field: 'contractNumber',
      headerName: 'Contract Number',
      flex:0.7,
      minWidth:190,
      hide: !props?.visibleColumns?.contractNumberColumn,
    },
    {
      field: 'b_o_l',
      headerName: 'Bill of lading',
      flex:0.7,
      minWidth:140,
      sortable: false,
      renderCell: RenderBL,
      hide: !props?.visibleColumns?.billOfLadingColumn,
    },

    {
      field: 'warehouse',
      headerName: 'Warehouse',
      flex:1,
      minWidth:140,
      hide: !props?.visibleColumns?.warehouseColumn,
    },
  ];

  const handleRowClick = (params) => {
    navigate("/missiondetails?id="+params.row.id+"&key="+randomId(), { replace: true });
  };

  function getUnique(arr, index) {
    const unique = arr
         .map(e => e[index])

         // store the keys of the unique objects
         .map((e, i, final) => final.indexOf(e) === i && i)

         // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

     return unique;
  }
  const handleClickExport = async (e) => {
    if (props.archivedList != '1' && isPortRole(localStorage.getItem("role"))) {
      setIsExportPopinOpen(!isExportPopinOpen);
    } else if (isPortRole(localStorage.getItem("role"))) {
      await exportPopinPortRef.current.exportFiles();
    }
  }

  const getMissionList = async (e) => {
    try {
      setLoadingGrid(true);
        const config = {
          headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            all: 1,
          },
       };
        
        if (!isCustomerRole(localStorage.getItem("role"))) {
          if (props.archivedList == 1 ) {
            config.params.archived_missions = 1;
          } else {
            config.params.archived_missions = 0;
          }
        }
          
        if (isCustomerRole(localStorage.getItem("role"))) {
          config.params.id_principal = parseInt(localStorage.getItem("id_company"));
        }
        
        if (isPortRole(localStorage.getItem("role"))) {
          config.params.id_local_office = parseInt(localStorage.getItem("id_company"));
        }
        
        const url = GETMISSIONLIST_URL;

        const response = await axios.get(url, config);

        var rowsFromApi = [];
        var vessels = [];
        var surveys = [];
        var principals = [];
        var missions = [];
        var ports = [];
        var insured = [];
        var shippers = [];
        var receivers = [];
        var idRow = 0;

        for(var i=0; i < response.data.length; i++) {

          if (isCustomerRole(localStorage.getItem("role")))
          {
            if (parseInt(localStorage.getItem("id_company")) != parseInt(response.data[i].principal.id))
            {
                continue;
            }
          }
          if (isPortRole(localStorage.getItem("role")))
          {
            if (parseInt(localStorage.getItem("id_company")) != parseInt(response.data[i].local_office.id))
            {
                continue;
            }
          }
          idRow = rowsFromApi.length;
          rowsFromApi[idRow] = {};
          rowsFromApi[idRow].id = response.data[i].id;
          rowsFromApi[idRow].missionType = response.data[i].mission_type.id;
          missions[missions.length] = response.data[i].mission_type;
          rowsFromApi[idRow].missionTypeLabel = response.data[i].mission_type.label;
          rowsFromApi[idRow].missionColor = response.data[i].mission_type.color;
          if (response.data[i].port) rowsFromApi[idRow].ref = response.data[i].mission_type.nomenclature+"-"+response.data[i].port.nomenclature+"-"+pad(response.data[i].id);
          else rowsFromApi[idRow].ref = response.data[i].mission_type.nomenclature+"-<XXX>-"+pad(response.data[i].id);
          rowsFromApi[idRow].created = response.data[i].created_at;
          if (isCustomerRole(localStorage.getItem("role")))
          {
            if (response.data[i].status) rowsFromApi[idRow].status = { statut_type: response.data[i].status.status.public_status.status.value, statut_color: response.data[i].status.status.public_status.status.color};
            else rowsFromApi[idRow].status = { statut_type: '0', statut_color: 'red'};
          } else {
            if (response.data[i].status) rowsFromApi[idRow].status = { statut_type: response.data[i].status.status.value, statut_color: response.data[i].status.status.color};
            else rowsFromApi[idRow].status = { statut_type: '0', statut_color: 'red'};
          }

          if (response.data[i].port)
          {
            rowsFromApi[idRow].port = response.data[i].port.label;
            ports[ports.length] = response.data[i].port;
          }

          if (response.data[i].principal_ref) rowsFromApi[idRow].principalsRef = response.data[i].principal_ref;

          rowsFromApi[idRow].principals = response.data[i].principal.name;
          principals[principals.length] = response.data[i].principal;
          if (response.data[i].insured)
          {
            rowsFromApi[idRow].insured = response.data[i].insured.name;
            insured[insured.length] = response.data[i].insured;
          }
          else rowsFromApi[idRow].insured = "";
          if (response.data[i].shipper)
          {
            rowsFromApi[idRow].shipper = response.data[i].shipper.name;
            shippers[shippers.length] = response.data[i].shipper;
          }
          if (response.data[i].contract_number)
          {
            rowsFromApi[idRow].contractNumber = response.data[i].contract_number;
          }
          else rowsFromApi[idRow].contractNumber = "";
          if (response.data[i].vessel)
          {
            rowsFromApi[idRow].vessel = response.data[i].vessel.name;
            vessels[vessels.length] = response.data[i].vessel;
          }
          else rowsFromApi[idRow].vessel  = "";
          rowsFromApi[idRow].survey_handler = response.data[i].survey_handler.firstname+" "+response.data[i].survey_handler.lastname;
          surveys[surveys.length] = response.data[i].survey_handler;

          var contentRowLoop = [];
          if (response.data[i].receivers)
          {
            for (var j=0; j < response.data[i].receivers.length; j++) {
              if (response.data[i].receivers[j].receiver)
              {
                contentRowLoop[j] = response.data[i].receivers[j].receiver.name;
                receivers[receivers.length] = response.data[i].receivers[j].receiver;
              }
            }
          }
          rowsFromApi[idRow].receiver = contentRowLoop;
          rowsFromApi[idRow].receiverSearch = contentRowLoop;

          var contentRowLoopBL = [];
          if (response.data[i].bills_of_lading)
          {
            for (var j=0; j < response.data[i].bills_of_lading.length; j++) {
              contentRowLoopBL[j] = response.data[i].bills_of_lading[j].number;
            }
          }
          rowsFromApi[idRow].b_o_l = contentRowLoopBL;

          if (response.data[i].vessel_tonnage) rowsFromApi[idRow].tonnage = Number(response.data[i].vessel_tonnage);
          if (response.data[i].warehouse) rowsFromApi[idRow].warehouse = response.data[i].warehouse.label;
          if (response.data[i].cargo) rowsFromApi[idRow].cargo = response.data[i].cargo.label;
          if (response.data[i].trader) rowsFromApi[idRow].trader = response.data[i].trader.name;
          rowsFromApi[idRow].public_note = response.data[i].public_note;
          rowsFromApi[idRow].internal_note = response.data[i].internal_note;
          rowsFromApi[idRow].has_unread_internal_notification = response.data[i].has_unread_internal_notification;
        }

        setRows(rowsFromApi);
        setTotalRows(rowsFromApi);

        vessels.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setVesselRows(getUnique(vessels,'id'));
        surveys.sort((a, b) => ((a.firstname + " " + a.lastname) > (b.firstname + " " + b.lastname)) ? 1 : -1);
        setSurveyRows(getUnique(surveys,'id'));
        principals.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setPrincipalRows(getUnique(principals,'id'));
        missions.sort((a, b) => (a.label > b.label) ? 1 : -1);
        setMissionRows(getUnique(missions,'id'));
        ports.sort((a, b) => (a.label > b.label) ? 1 : -1);
        setPortRows(getUnique(ports,'id'));
        insured.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setInsuredRows(getUnique(insured,'id'));
        shippers.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setShipperRows(getUnique(shippers,'id'));
        receivers.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setReceiverRows(getUnique(receivers,'id'));
        if (props.initFilterMissions) props.initFilterMissions(rowsFromApi);

    } catch (err) {
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        setOpenSnackBar(true);
        console.log(err);
      }
    } finally
    {
      setLoadingGrid(false);
      setDataGridLoaded(true);
    }
  }

  function CustomNoRowsOverlay() {
    return (<div></div>);
  }

  function pad(num) {
      var s = "000000" + num;
      return s.substr(s.length-6);
  }

  return (
      <>
        <DataGridPro sx={{ height: '100%', width: '100%' }}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          initialState={{
            pagination: {
              pageSize: 15,
            },
            sorting: {
              sortModel: [{ field: 'created', sort: 'desc' }],
            },
          }}
          loading={loadingGrid}
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[8, 15, 25, 50, 100]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          onRowClick={handleRowClick}
          filterModel={filterModel}
          onFilterModelChange={props.rowsSelected}
          onStateChange={(state) => {
            const newRows = gridVisibleSortedRowIdsSelector(state);
            setVisibleRows(newRows);
          }}
        />
        <div id="listExport">
            <Button size="small" variant="outlined" startIcon={<FileDownloadIcon />} onClick={handleClickExport}>Export</Button>
        </div>
        <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Technical error ! Please try again or contact our support.
          </Alert>
        </Snackbar>
        <Snackbar open={isDownload} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              File saved in your downloads folder.
            </Alert>
        </Snackbar>
        <ExportPopin
            ref={exportPopinPortRef}
            exportUrl='mission/export/port'
            open={isExportPopinOpen}
            setOpen={setIsExportPopinOpen}
            archivedList={props.archivedList}
            
            insuredRows={insuredRows}
            missionTypeRows={missionRows}
            portRows={portRows}
            principalRows={principalRows}
            receiverRows={receiverRows}
            surveyRows={surveyRows}
            vesselRows={vesselRows}
            
            filterDatas={filterDatas}
            filterStatusArray={filterStatusArray} 
            filterCategory={category}
            filterSearchTxt={searchTxt}
            
            visibleRows={visibleRows}
            setIsDownload={setIsDownload}
            setOpenSnackbar={setOpenSnackBar}
          />
      </>
  );
}

PortMissionsList.propTypes = {
  visibleColumns: PropTypes.shape({
    idColumn: PropTypes.bool,
    surveyHandlerColumn: PropTypes.bool,
    missionColorColumn: PropTypes.bool,
    refJLBColumn: PropTypes.bool,
    internalNoteColumn: PropTypes.bool,
    publicNoteColumn: PropTypes.bool,
    missionTypeColumn: PropTypes.bool,
    missionTypeLabelColumn: PropTypes.bool,
    createdDateColumn: PropTypes.bool,
    statusColumn: PropTypes.bool,
    portColumn: PropTypes.bool,
    principalsColumn: PropTypes.bool,
    principalsRefColumn: PropTypes.bool,
    insuredColumn: PropTypes.bool,
    vesselColumn: PropTypes.bool,
    tonnageColumn: PropTypes.bool,
    receiverColumn: PropTypes.bool,
    receiverSearchColumn: PropTypes.bool,
    cargoColumn: PropTypes.bool,
    traderColumn: PropTypes.bool,
    shipperColumn: PropTypes.bool,
    contractNumberColumn: PropTypes.bool,
    billOfLadingColumn: PropTypes.bool,
    warehouseColumn: PropTypes.bool
  })
};