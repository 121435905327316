import React, { useState, useEffect, useContext } from "react";
import { Stack } from "react-bootstrap";

import { DataGridPro } from "@mui/x-data-grid-pro";
import FormControl from "@mui/material/FormControl";

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsNotification } from "./ContextMissionDetailsNotification";

import MissionNotificationLog from "./MissionDetailsNotificationLog";
import MissionInternalNotification from "./MissionDetailsInternalNotification";


export default function MissionDetailsNotificationZone() {

  //generic
  const {openSnackBar, setOpenSnackBar} = useContext(ContextMissionDetails);
  const {snackBarType, setSnackBarType} = useContext(ContextMissionDetails);
  const {snackBarMessage, setSnackBarMessage} = useContext(ContextMissionDetails);

  //workflow
  const {canRead} = useContext(ContextMissionWorkflow);

  //notif data
  const [rows, setRows] = useState([]);
  const [extendedRows, setExtendedRows] = useState([]);
  const getNotificationsDetailsHeight = React.useCallback(() => 250, []);

  const {rowsNotificationLog, setRowsNotificationLog} = useContext(ContextMissionDetailsNotification);
  const {rowsInternalNotification, setRowsInternalNotification} = useContext(ContextMissionDetailsNotification);
  const {nInternalNotifNoRead, setNRowsInternalNotifNoRead} = useContext(ContextMissionDetailsNotification);

  //general data : markAsRead
  const {general} = useContext(ContextMissionDetails);
  const canMarkAsRead = Object.keys(general).length > 0 ? general.can_write : 0;


  useEffect(() => {
    var myRows = [];
    if (canRead("notiflog_block")) {
      myRows.push({ id: 1, title: "Notifications Log" });
    }
    if (canRead("internalnotif_block")) {
      myRows.push({ id: 2, title: "Internal Notifications" });
    }
    setRows(myRows);
  }, []);

  useEffect(() => {
    if (nInternalNotifNoRead > 0) {
      setExtendedRows([2]);
    }
  }, [nInternalNotifNoRead]);


  //function interactive
  const updateInternalNotif = (id_notif, marked_as_read, read_on) => {
    var nRowsInternalNotifNoRead = 0;
    setRowsInternalNotification(rowsInternalNotification.map((item) => {
      if (item.id == id_notif) {
        return {...item, id:id_notif, marked_as_read:marked_as_read, read_on:read_on};
      }else{
        if(item.marked_as_read != "1") nRowsInternalNotifNoRead++;
      }
      return item;
    }));
    setNRowsInternalNotifNoRead(nRowsInternalNotifNoRead);
  }

  function handleDetailPanelExpandedRowsChange(event) {
    const expandedRowIds = event;
    setExtendedRows(expandedRowIds);
  }

  function CustomNoRowsOverlay() {
    return <div className="customNoRowsOverlay"></div>;
  }

  const getNotificationsDetailPanelContent = (row) => {
    switch (row.title) {
      case "Notifications Log":
        return (
          <div>
            {canRead("notiflog_block") ? (
              <Stack>
                <MissionNotificationLog rows={rowsNotificationLog} />
              </Stack>
            ) : (
              ""
            )}
          </div>
        );
      case "Internal Notifications":
        return (
          <div>
            {canRead("internalnotif_block") ? (
              <Stack>
                <MissionInternalNotification
                  rows={rowsInternalNotification}
                  canMarkAsRead={canMarkAsRead}
                  updateInternalNotif={updateInternalNotif}
                  setSnackBarMessage={setSnackBarMessage}
                  setSnackBarType={setSnackBarType}
                  setOpenSnackBar={setOpenSnackBar}
                />
              </Stack>
            ) : (
              ""
            )}
          </div>
        );
      default:
        return null;
    }
  };


  return (
    <div>
      <FormControl
        sx={{ m: 1, width: "100%", height: 1 }}
        size="small"
        id="datagrid-notifications"
      >
        <DataGridPro
          detailPanelExpandedRowIds={extendedRows}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          onDetailPanelExpandedRowIdsChange={
            handleDetailPanelExpandedRowsChange
          }
          rows={rows}
          columns={[{ field: "title", headerName: "Sections", flex: 1 }]}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          getDetailPanelHeight={getNotificationsDetailsHeight}
          getDetailPanelContent={(params) =>
            getNotificationsDetailPanelContent(params.row)
          }
          hideFooter
        />
      </FormControl>
    </div>
  );
}
