import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { isCustomerRole } from 'components/config/Roles';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";

import MissionDetailsSubInvoiceForm from './MissionDetailsSubInvoiceForm';
import MissionDetailsSubInvoiceView from "./MissionDetailsSubInvoiceView";


const MissionDetailsSubInvoice = forwardRef((props, ref) => {

  //config
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);
  const {controlFieldsSubInvoice, setControlFieldsSubInvoice} = useContext(ContextMissionWorkflow);
  const {toogleBlockOnEditAndCheckBlockOnWorkflowError} = useContext(ContextMissionWorkflow);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const {blockEdit, setBlockEdit} = useContext(ContextMissionDetails);


  //useEffect
  useEffect(() => {
    toogleBlockOnEditAndCheckBlockOnWorkflowError(ref, onEdit, setOnEdit, controlFieldsSubInvoice);
  }, [blockEdit, controlFieldsError]);


  if (!JLBCustomerMode && ((canWrite("invoiced_by") || canRead("invoiced_by")) 
  || (canWrite("subInvoice_block") || canRead("subInvoice_block")))) {
    return (
      <div id="block_subinvoice" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
        <h4>Subcontracting Invoices</h4>

        {(!onEdit) ? <MissionDetailsSubInvoiceView/> : <MissionDetailsSubInvoiceForm/> }
        
      </div>
    );
  }
});

export default MissionDetailsSubInvoice;
