import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import 'styles/globalPortalDashboard.css';
import 'styles/DashboardList.css';
import 'bootstrap/dist/css/bootstrap.css';

import {checkSecurity} from 'components/config/Security';
import { FilterProvider } from "context/ContextFilter";
import CocoaDepartureMissionsListFilter from "./CocoaDepartureMissionsListFilter";
import CocoaDepartureMissionsList  from './CocoaDepartureMissionsList'
import Menu from '../Menu';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CocoaDepartureDashboard = () => {
    // CHECK SECURITY
    const navigate = useNavigate();

    useEffect(() => {
      checkSecurity(navigate, "CocoaDepartureDashboard");
    }, []);
    // END SECURITY

    const filterRef = useRef("filterRef");

    function initFilterMissions(totalsRowParam){
      filterRef.current.initFilter(totalsRowParam)
    }

    return (
      <div className='FULL-CONTENT DASHBOARD'>
        <FilterProvider>
          <div className="content">
            <CocoaDepartureMissionsListFilter
                ref={filterRef} 
                cocoaDeparture={true} 
                // Set visible filters
                visibleFilters={{ 
                  missionTypeFilter: true, 
                  portFilter: true, 
                  principalsFilter: true, 
                  insuredFilter: true, 
                  shipperFilter: true, 
                  cancelFilter: true, 
                }}
                // Set visible categories 
                visibleCategories={{ 
                  refJLBCategory: true, 
                  portCategory: true,
                  traderCategory: true, 
                  insuredCategory: true,
                  shipperCategory: true, 
                  contractNumberCategory: true,
              }}  
            />
            <div className="contentList">
              <CocoaDepartureMissionsList 
                initFilterMissions={initFilterMissions}
                cocoaDeparture={true} 
                // Set visible columns
                visibleColumns={{
                  missionColorColumn: true,
                  refJLBColumn: true,
                  internalNoteColumn: true,
                  createdDateColumn: true,
                  portColumn: true,
                  principalsColumn: true,
                  insuredColumn: true,
                  tonnageColumn: true,
                  traderColumn: true,
                  shipperColumn: true,
                  contractNumberColumn: true,
                }}
            />
            </div>
          </div>
        </FilterProvider>
        <Menu /> 
      </div>
    )
}

export default CocoaDepartureDashboard
