import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import {checkSecurity} from 'components/config/Security';
import Menu from './section/Menu';

import { MissionDetailsProvider } from "context/ContextMissionDetails";
import { MissionWorkflowProvider } from "context/ContextMissionWorkflow";
import { MissionDetailsTreatmentProvider } from "context/ContextMissionDetailsTreatment";
import { MissionDetailsReceiverBLProvider } from "./section/MissionDetails/ReceiverBL/ContextMissionDetailsReceiverBL";
import { MissionDetailsHeaderProvider } from './section/MissionDetails/Header/ContextMissionDetailsHeader';
import { MissionDetailsGeneralProvider } from './section/MissionDetails/General/ContextMissionDetailsGeneral';
import { MissionDetailsNoteProvider } from './section/MissionDetails/Note/ContextMissionDetailsNote';
import { MissionDetailsSubInvoiceProvider } from './section/MissionDetails/SubInvoice/ContextMissionDetailsSubInvoice';
import { MissionDetailsInvoiceProvider } from './section/MissionDetails/Invoice/ContextMissionDetailsInvoice';
import { MissionDetailsNotificationProvider } from './section/MissionDetails/Notification/ContextMissionDetailsNotification';

import MissionDetailsHeader from './section/MissionDetails/Header/MissionDetailsHeader';
import MissionDetailsCanceled from './section/MissionDetails/MissionDetailsCanceled';
import MissionDetailsFloatingSaveButton from './section/MissionDetails/MissionDetailsFloatingSaveButton';
import MissionDetailsWorkflowSaveButton from './section/MissionDetails/MissionDetailsWorkflowSaveButton';
import MissionDetailsWorkflowMailing from './section/MissionDetails/Mailing/MissionDetailsWorkflowMailing';
import MissionDetailsGeneral from './section/MissionDetails/General/MissionDetailsGeneral';
import MissionDetailsBL from "./section/MissionDetails/ReceiverBL/BL/MissionDetailsBL";
import MissionDetailsReceiver from './section/MissionDetails/ReceiverBL/Receiver/MissionDetailsReceiver';
import MissionDetailsNote from "./section/MissionDetails/Note/MissionDetailsNote";
import MissionDetailsInvoice from './section/MissionDetails/Invoice/MissionDetailsInvoice';
import MissionDetailsSubInvoice from './section/MissionDetails/SubInvoice/MissionDetailsSubInvoice';
import MissionDetailsProtis from "./section/MissionDetails/Protis/MissionDetailsProtis";
import MissionDetailsFiles from './section/MissionDetails/File/MissionDetailsFiles';
import MissionDetailsNotification from './section/MissionDetails/Notification/MissionDetailsNotification';
import MissionDetailsSnackBar from './section/MissionDetails/MissionDetailsSnackBar';
import MissionDetailsInfosPopin from './section/MissionDetails/Popin/MissionDetailsInfosPopin';

import 'styles/globalPortalDashboard.css';
import 'styles/CreateMission.css';
import 'styles/MissionDetails.css';
import 'bootstrap/dist/css/bootstrap.css';


const MissionDetails = (props) => {

  //init config
  const navigate = useNavigate();

  //init composant ref
  const contentRef = useRef(null);
  const headerRef = useRef("headerComp");
  const generalRef = useRef("generalComp");
  const receiverRef = useRef("receiverComp");
  const blRef = useRef("blComp");
  const noteRef = useRef("noteComp");
  const invoiceRef = useRef("invoiceComp");
  const subInvoiceRef = useRef("subInvoiceComp");
  const fileRef = useRef("fileForm");
  const protisRef = useRef("protisForm");

  //init context params
  const contextParams = {
    update: props.update,
    refs: {
      contentRef: contentRef,
      headerRef: headerRef,
      generalRef: generalRef,
      receiverRef: receiverRef,
      blRef: blRef,
      noteRef: noteRef,
      invoiceRef: invoiceRef,
      subInvoiceRef: subInvoiceRef,
      fileRef: fileRef,
      protisRef: protisRef
    }
  };

  //init content
  const [sticky, setSticky] = useState(false);


  useEffect(() => {
    checkSecurity(navigate, "MissionDetails");
  }, []);


  const onScrollContent = e => {
    if (Number(e.currentTarget.scrollTop) > 53) setSticky(true);
    else setSticky(false);
  };
  

  return (
    <div className='FULL-CONTENT DASHBOARD'>
      <MissionDetailsProvider value={contextParams}>
        <MissionWorkflowProvider>
          <MissionDetailsTreatmentProvider>
            <MissionDetailsHeaderProvider>
              <MissionDetailsGeneralProvider>
                <MissionDetailsReceiverBLProvider>
                  <MissionDetailsNoteProvider>
                    <MissionDetailsSubInvoiceProvider>
                      <MissionDetailsInvoiceProvider>
                        <MissionDetailsNotificationProvider>
                          <div className="content" onScroll={onScrollContent}>
                            <div ref={contentRef}>
                              <div>
                                <MissionDetailsHeader floating={sticky}/>
                                <MissionDetailsCanceled/>
                                <MissionDetailsGeneral ref={generalRef}/>

                                <MissionDetailsReceiver ref={receiverRef}/>
                                <MissionDetailsBL ref={blRef}/>

                                <MissionDetailsNote ref={noteRef}/>
                                <MissionDetailsSubInvoice ref={subInvoiceRef}/>
                                <MissionDetailsInvoice ref={invoiceRef}/>
                                <MissionDetailsFiles ref={fileRef}/>
                                <MissionDetailsProtis/>
                                <MissionDetailsNotification/>
                                <MissionDetailsWorkflowMailing/>
                                <MissionDetailsWorkflowSaveButton/>
                              </div>
                              <MissionDetailsSnackBar/>
                              <MissionDetailsInfosPopin/>
                              <MissionDetailsFloatingSaveButton/>
                            </div>
                          </div>
                        </MissionDetailsNotificationProvider>
                      </MissionDetailsInvoiceProvider>
                    </MissionDetailsSubInvoiceProvider>
                  </MissionDetailsNoteProvider>
                </MissionDetailsReceiverBLProvider>
              </MissionDetailsGeneralProvider>
            </MissionDetailsHeaderProvider>
          </MissionDetailsTreatmentProvider>
        </MissionWorkflowProvider>
      </MissionDetailsProvider>
      <Menu /> 
    </div>
  );
};

export default MissionDetails;
