import React, { useEffect, useContext } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';

import momentDate from 'moment';

import { DataGridPro, DataGridDatePickerCustom, DataGridSelectCustom, DataGridTextCustom } from 'components/page/object/DataGridProCustom';

import { ContextMissionDetails } from "context/ContextMissionDetails";


export default function UnaffectedRowsGrid(props) {

  const [rowsBL, setRowsBL] = React.useState([]);

  //context
  const { handleRowEditStart } = useContext(ContextMissionDetails);
  const { handleRowEditStop } = useContext(ContextMissionDetails);
  const { CustomNoRowsOverlay } = useContext(ContextMissionDetails);


  useEffect(() => {
    setRowsBL(props.rows);
  }, [props.rows]);


  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    props.setRowsBLGlobal(props.rowsBLGlobal.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns: GridColDef[] = [
    {
      field: 'signature_place', headerName: 'Signature Place', sortable: false, width: 150, type: "singleSelect",
      editable: (props.canWrite("bl_block") ? true : false),
      valueOptions: props.countries,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );
        if (option) return option.label;
      },
      renderCell: (cellValues) => {
        if (props.canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            read={props.canRead('bl_block')}
            write={props.canWrite('bl_block')}
            control={props.workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'bl_date', headerName: 'BL Date', sortable: false, width: 160, type: "date",
      editable: (props.canWrite("bl_block") ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        if (props.canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={props.canRead('bl_block')}
            write={props.canWrite('bl_block')}
            control={props.workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'bol', headerName: 'BL number', sortable: false, width: 150,
      editable: (props.canWrite("bl_block") ? true : false),
      valueParser: (value: GridCellValue, params: GridCellParams) => {
        return value.toUpperCase();
      },
      renderCell: (cellValues) => {
        if (props.canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            read={props.canRead('bl_block')}
            write={props.canWrite('bl_block')}
            control={props.workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'type_of_intervention', headerName: 'Type of intervention', sortable: false, width: 250, type: "singleSelect",
      editable: (props.canWrite("bl_block") ? true : false),
      valueOptions: props.typesOfIntervention,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        if (option) return option.label;
      },
      renderCell: (cellValues) => {
        if (props.canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            read={props.canRead('bl_block')}
            write={props.canWrite('bl_block')}
            control={props.workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'quantity', headerName: 'Cocoa - to be sold (kg)', sortable: false, width: 180, type: 'number',
      editable: (props.canWrite("bl_block") ? true : false),
      renderCell: (cellValues) => {
        if (props.canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            type="decimal"
            read={props.canRead('bl_block')}
            write={props.canWrite('bl_block')}
            control={props.workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'deadline_date', headerName: 'Deadline', sortable: false, width: 160, type: 'date',
      editable: (props.canWrite("bl_block") ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        if (props.canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={props.canRead('bl_block')}
            write={props.canWrite('bl_block')}
            control={props.workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'delivery_date', headerName: 'Delivery (cocoa)', sortable: false, width: 160, type: 'date',
      editable: (props.canWrite("bl_block") ? true : false),
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        if (props.canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={props.canRead('bl_block')}
            write={props.canWrite('bl_block')}
            control={props.workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'smv', headerName: 'SMV (EUR)', sortable: false, width: 180, type: 'number',
      editable: (props.canWrite("bl_block") ? true : false),
      renderCell: (cellValues) => {
        if (props.canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            type="decimal"
            read={props.canRead('bl_block')}
            write={props.canWrite('bl_block')}
            control={props.workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
    {
      field: 'note', headerName: 'Comment', sortable: false, flex: 1,
      editable: (props.canWrite("bl_block") ? true : false),
      renderCell: (cellValues) => {
        if (props.canWrite("bl_block") && (cellValues.value == "" || cellValues.value == null)) return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            fullWidth
            multiline
            read={props.canRead('bl_block')}
            write={props.canWrite('bl_block')}
            control={props.workflowLoaded}
            processRowUpdate={processRowUpdate}
          />
        )
      }
    },
  ];


  if (rowsBL.length > 0) {
    return (
      <div className="unaffectedRowsDiv">
        <label>Unaffected Bills of Lading</label>
        <DataGridPro
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          density='compact'
          hideFooter={true}
          rows={rowsBL}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            toolbar: { setRowsBL },
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </div>
    );
  }
}
