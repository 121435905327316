import React, {useState, useContext} from 'react';

import { ContextMissionDetails } from "context/ContextMissionDetails";

import MissionDetailsNotificationZone from './MissionDetailsNotificationZone';

import {isCustomerRole} from 'components/config/Roles';


const MissionDetailsNotification = (props) => {

  //config
  const {update} = useContext(ContextMissionDetails);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));


  if (update == 1 && !JLBCustomerMode) {
    return (
      <div id="block_notification" className="contentDetail block_details">
        <h4>Notifications</h4>
        <MissionDetailsNotificationZone/>
      </div>
    );
  }
}

export default MissionDetailsNotification
