import React, { useEffect, SyntheticEvent } from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import DesktopDatePickerCustom from 'components/select/DesktopDatePickerCustom';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { DataGridPro, GridToolbarContainer, DataGridDatePickerCustom, DataGridSelectCustom, DataGridTextCustom } from 'components/page/object/DataGridProCustom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import momentDate from 'moment';


export default function BLDateRow(props) {

  const [blDate, setBlDate] = React.useState("");
  const signaturePlace = props.signaturePlace;
  const [rowsBL, setRowsBL] = React.useState([]);
  const typesOfIntervention = props.typesOfIntervention;
  //new param for default extand
  const [expanded, setExpanded] = React.useState((!!props.blDateRow.isExpanded != false ? props.blDateRow.isExpanded : props.isExpanded));

  useEffect(() => {
    setBlDate(props.blDateRow.value);
    setRowsBL(props.blDateRow.bl_rows);
  }, [props.blDateRow]);

  function CustomNoRowsOverlay() {
    return (<div className="customNoRowsOverlay"></div>);
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const processRowUpdateBL = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    props.setRowsBLGlobal(props.rowsBLGlobal.map((row) => (row.id === newRow.id ? updatedRow : row)));
    setRowsBL(rowsBL.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const deleteRowBL = (event, id) => {
    props.setRowsBLGlobal(props.rowsBLGlobal.filter((row) => row.id !== id));
    setRowsBL(rowsBL.filter((row) => row.id !== id));
    if (id.toString().length < 16) props.setRowsBLDeleted((oldRowsBl) => [...oldRowsBl, { id: id }]);
  };

  const duplicateRowBL = (event, values) => {
    const id = randomId();
    const bol = values.row.bol.toUpperCase().trim();
    props.setRowsBLGlobal((oldRows) => [...oldRows, {
      id: id,
      signature_place: signaturePlace,
      signaturePlaceRowId: signaturePlace.id,
      dateRowId: props.blDateRow.id,
      bl_date: blDate,
      bol: '',
      type_of_intervention: values.row.type_of_intervention,
      beans_type: values.row.beans_type,
      quantity: values.row.quantity,
      deadline_date: values.row.deadline_date,
      delivery_date: values.row.delivery_date,
      smv: values.row.smv,
      note: values.row.note,
      isExpanded: true
    }]);
    setRowsBL((oldRows) => [...oldRows, {
      id: id,
      signature_place: signaturePlace,
      signaturePlaceRowId: signaturePlace.id,
      dateRowId: props.blDateRow.id,
      bl_date: blDate,
      bol: '',
      type_of_intervention: values.row.type_of_intervention,
      beans_type: values.row.beans_type,
      quantity: values.row.quantity,
      deadline_date: values.row.deadline_date,
      delivery_date: values.row.delivery_date,
      smv: values.row.smv,
      note: values.row.note,
      isExpanded: true
    }]);
  };

  const changeBlDate = (value) => {
    setBlDate(value);
    for (var i = 0; i < rowsBL.length; i++) {
      props.setRowsBLGlobal(props.rowsBLGlobal.map((row) => {
        if (row.id == rowsBL[i].id) {
          row.bl_date = value;
        }
        return row;
      }));
    }
  }

  //function for change state of extand of row
  const rowExpandUpdate = (isExpanded = false) => {
    props.setRowsBLDate((prevState) => {
      // Find the index of the object you want to update
      const index = prevState.findIndex(item => item.id === props.blDateRow.id);
      if (index !== -1) {
        // Create a new array with the updated object
        const updatedArray = [...prevState];
        updatedArray[index] = {
          ...updatedArray[index],
          // Update the property you want to change
          isExpanded: isExpanded
        };
        return updatedArray; // Return the updated array
      }
      // If the object with the given ID is not found, return the previous state
      return prevState;
    });

    for (var i = 0; i < rowsBL.length; i++) {
      props.setRowsBLGlobal(props.rowsBLGlobal.map((row) => {
        if (row.id == rowsBL[i].id) {
          row.isExpanded = isExpanded;
        }
        return row;
      }));
    }
  }

  //function for change state of extand of accordion
  const handleChange = (toggle) => (event: SyntheticEvent) => {
    setExpanded(!toggle);
  }

  var canWrite = props.canWrite("bl_block");
  const EditToolbarBL = () => {
    const handleClick = () => {
      const id = randomId();
      var data = {
        id: id,
        signature_place: signaturePlace,
        signaturePlaceRowId: signaturePlace.id,
        dateRowId: props.blDateRow.id,
        bl_date: blDate,
        bol: '',
        type_of_intervention: '',
        beans_type: '',
        quantity: '',
        deadline_date: '',
        delivery_date: '',
        smv: '',
        note: '',
        isExpanded: true
      };
      if (props.rowsBLGlobal) {
        props.setRowsBLGlobal((oldRows) => [...oldRows, data]);
      } else {
        props.setRowsBLGlobal([data]);
      }
      setRowsBL((oldRows) => [...oldRows, data]);
      if (props.blDateRow.length == 0) {
        rowExpandUpdate(true);
      }
    };

    if (canWrite) {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              Add Bill of lading
            </Button>
          </div>
        </GridToolbarContainer>
      );
    } else {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <div className="iconAddButton">
              Bill of lading
            </div>
          </div>
        </GridToolbarContainer>
      );
    }
  }

  var listColumn = [];
  if (canWrite) {
    listColumn.push({
      field: 'id', headerName: '', editable: false, sortable: false, height: 35,
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton aria-label="delete" onClick={(event) => {
              deleteRowBL(event, cellValues.id);
            }}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="duplicate" onClick={(event) => {
              duplicateRowBL(event, cellValues);
            }}>
              <ContentCopyIcon />
            </IconButton>
          </>
        );
      }
    });
  }
  listColumn.push({
    field: 'bol', headerName: 'BL number', sortable: false, minWidth: 120,
    editable: (canWrite ? true : false),
    valueParser: (value: GridCellValue, params: GridCellParams) => {
      return value.toString().toUpperCase();
    },
    renderCell: (cellValues) => {
      if (canWrite && (cellValues.value == "" || cellValues.value == null)) return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      )
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridTextCustom
          {...params}
          read={props.canRead('bl_block')}
          write={props.canWrite('bl_block')}
          control={props.workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'type_of_intervention', headerName: 'Type of intervention', sortable: false, width: 150, type: "singleSelect",
    valueOptions: typesOfIntervention,
    editable: (canWrite ? true : false),
    valueFormatter: ({ id: rowId, value, field, api }) => {
      const colDef = api.getColumn(field);
      const option = colDef.valueOptions.find(
        ({ value: optionValue }) => value === optionValue
      );

      if (option) return option.label;
    },
    renderCell: (cellValues) => {
      if (canWrite && (cellValues.value == "" || cellValues.value == null)) return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      )
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridSelectCustom
          {...params}
          read={props.canRead('bl_block')}
          write={props.canWrite('bl_block')}
          control={props.workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'quantity', headerName: 'Cocoa - to be sold (kg)', sortable: false, width: 180, type: 'number',
    editable: (canWrite ? true : false),
    renderCell: (cellValues) => {
      if (canWrite && (cellValues.value == "" || cellValues.value == null)) return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      )
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridTextCustom
          {...params}
          type="decimal"
          read={props.canRead('bl_block')}
          write={props.canWrite('bl_block')}
          control={props.workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'beans_type', headerName: 'Type of beans', sortable: false, width: 150, type: "singleSelect",
    valueOptions: props.beansTypes,
    editable: (canWrite ? true : false),
    valueFormatter: ({ id: rowId, value, field, api }) => {
      const colDef = api.getColumn(field);
      const option = colDef.valueOptions.find(
        ({ value: optionValue }) => value === optionValue
      );

      if (option) return option.label;
    },
    renderCell: (cellValues) => {
      if (canWrite && (cellValues.value == "" || cellValues.value == null)) return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      )
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridSelectCustom
          {...params}
          read={props.canRead('bl_block')}
          write={props.canWrite('bl_block')}
          control={props.workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'deadline_date', headerName: 'Deadline', sortable: false, width: 160, type: 'date',
    editable: (canWrite ? true : false),
    valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
    renderCell: (cellValues) => {
      if (canWrite && (cellValues.value == "" || cellValues.value == null)) return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      )
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridDatePickerCustom
          {...params}
          read={props.canRead('bl_block')}
          write={props.canWrite('bl_block')}
          control={props.workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'delivery_date', headerName: 'Delivery (cocoa)', sortable: false, width: 160, type: 'date',
    editable: (canWrite ? true : false),
    valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
    renderCell: (cellValues) => {
      if (canWrite && (cellValues.value == "" || cellValues.value == null)) return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      )
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridDatePickerCustom
          {...params}
          read={props.canRead('bl_block')}
          write={props.canWrite('bl_block')}
          control={props.workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'smv', headerName: 'SMV (EUR)', sortable: false, width: 160, type: 'number',
    editable: (canWrite ? true : false),
    renderCell: (cellValues) => {
      if (canWrite && (cellValues.value == "" || cellValues.value == null)) return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      )
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridTextCustom
          {...params}
          type="decimal"
          read={props.canRead('bl_block')}
          write={props.canWrite('bl_block')}
          control={props.workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  }, {
    field: 'note', headerName: 'Comment', sortable: false, flex: 1,
    editable: (canWrite ? true : false),
    renderCell: (cellValues) => {
      if (canWrite && (cellValues.value == "" || cellValues.value == null)) return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      )
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridTextCustom
          {...params}
          fullWidth
          multiline
          read={props.canRead('bl_block')}
          write={props.canWrite('bl_block')}
          control={props.workflowLoaded}
          processRowUpdate={processRowUpdateBL}
        />
      )
    }
  });
  const columnsBL: GridColDef[] = listColumn;

  return (
    <Accordion className="signaturePlaceRowAccordion" expanded={expanded} onChange={handleChange(expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {blDate ? momentDate(blDate).format("DD MMM YY") : "New Date"}
      </AccordionSummary>
      <AccordionDetails>
        <DesktopDatePickerCustom
          label="BL Date"
          value={blDate}
          onChange={changeBlDate}

          read={props.canRead("bl_block")}
          write={props.canWrite("bl_block")}
          required={props.isRequired("bl_block")}
          controlFieldsStatus={props.workflowLoaded}
        />
        <span className={!props.canWrite("bl_block") ? "spacerBlDate justRead" : "spacerBlDate"}></span>

        <DataGridPro
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          density='compact'
          hideFooter={true}
          rows={rowsBL}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdateBL}
          columns={columnsBL}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            toolbar: { setRowsBL },
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: EditToolbarBL,
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}