import React, { useRef, useState, useEffect, createContext } from "react";

import { useNavigate } from "react-router-dom";
import momentDate from 'moment';

import axios from 'api/axios';


const ContextMissionDetails = createContext({
  update: 0,
  refs: {
    contentRef: null,
    headerRef: null,
    generalRef: null,
    receiverRef: null,
    blRef: null,
    noteRef: null,
    invoiceRef: null,
    subInvoiceRef: null,
    fileRef: null,
    protisRef: null
  }
});

const MissionDetailsProvider = ({ children, value }) => {

  //init config
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const actionSave  = queryParams.get('actionSave');

  //API URL
  const GETMISSION = 'mission';

  //composant ref
  const contentRef = value.refs.contentRef;
  const headerRef = value.refs.headerRef;
  const generalRef = value.refs.generalRef;
  const receiverRef = value.refs.receiverRef;
  const blRef = value.refs.blRef;
  const noteRef = value.refs.noteRef;
  const invoiceRef = value.refs.invoiceRef;
  const subInvoiceRef = value.refs.subInvoiceRef;
  const fileRef = value.refs.fileRef;
  const protisRef = value.refs.protisRef;

  //composant company ref
  const companyPrincipalsSelectRef = useRef("principalsSelectRef");
  const companyTraderSelectRef = useRef("traderSelectRef");
  const companyInsuredSelectRef = useRef("insuredSelectRef");
  const companyLocalOfficeSelectRef = useRef("localOfficeSelectRef");
  const companyWarehouseKeeperSelectRef = useRef("warehouseKeeperSelectRef");
  
  //init param
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("");
  function setSnackErrorMsg(data) {
    var errorMsg = "Technical error ! Please try again or contact our support.";
    /*if(typeof data != "undefined"){
      var responseMsg = "";
      var errorData = Array.from(new Map(Object.entries(data)));
      if(Array.isArray(errorData) && errorData.length >= 1){
        errorData.map((err, i) => {
          if(typeof err != String){
            err.map((msg, k) => {
              if(Array.isArray(msg)){
                msg.map((txt, idx) => {
                  responseMsg += "\n" + txt;
                });
              }
            });
          }else{
            responseMsg += "\n" + err;
          }
        });
      }else{
        responseMsg += "\n" + errorData;
      }
      errorMsg = errorMsg + responseMsg;
    }*/

    return errorMsg;
  }

  const [blockEdit, setBlockEdit] = useState([
    {'blockRef' : generalRef, 'reload' : false, 'onEdit' : false},
    {'blockRef' : headerRef, 'reload' : false, 'onEdit' : false},
    {'blockRef' : receiverRef, 'reload' : false, 'onEdit' : false},
    {'blockRef' : blRef, 'reload' : false, 'onEdit' : false},
    {'blockRef' : noteRef, 'reload' : false, 'onEdit' : false},
    {'blockRef' : invoiceRef, 'reload' : false, 'onEdit' : false},
    {'blockRef' : subInvoiceRef, 'reload' : false, 'onEdit' : false},
    {'blockRef' : protisRef, 'reload' : false, 'onEdit' : false}
  ]);

  const [blockSave, setBlockSave] = useState([
    {'blockRef' : generalRef, 'workflowLabel' : null, 'allowCreation' : true, 'order' : 0, 'onSave' : {'start' : false, 'done' : false, 'error' : false}},
    {'blockRef' : headerRef, 'workflowLabel' : null, 'allowCreation' : true, 'order' : 1, 'onSave' : {'start' : false, 'done' : false, 'error' : false}},
    {'blockRef' : receiverRef, 'workflowLabel' : 'receiver_block', 'allowCreation' : true, 'order' : 2, 'onSave' : {'start' : false, 'done' : false, 'error' : false}},
    {'blockRef' : blRef, 'workflowLabel' : 'bl_block', 'allowCreation' : true, 'order' : 3, 'onSave' : {'start' : false, 'done' : false, 'error' : false}},
    {'blockRef' : noteRef, 'workflowLabel' : ['internal_note', 'public_note'], 'allowCreation' : true, 'order' : 4, 'onSave' : {'start' : false, 'done' : false, 'error' : false}},
    {'blockRef' : invoiceRef, 'workflowLabel' : 'invoice_block', 'allowCreation' : true, 'order' : 5, 'onSave' : {'start' : false, 'done' : false, 'error' : false}},
    {'blockRef' : subInvoiceRef, 'workflowLabel' : ['subInvoice_block', 'invoiced_by'], 'allowCreation' : true, 'order' : 6, 'onSave' : {'start' : false, 'done' : false, 'error' : false}},
    {'blockRef' : protisRef, 'workflowLabel' : null, 'allowCreation' : false, 'order' : 7, 'onSave' : {'start' : false, 'done' : false, 'error' : false}}
  ]);

  //INIT FUNC RELOAD
  const setReloadBlockData = (blockRef, reload = false) => {
    //cancel edit for this blockRef
    var blocks = blockEdit.map((block) => {
      if (block.blockRef === blockRef) block = {...block, 'reload': reload};
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }
  //INIT FUNC EDIT
  const setCancelEdit = (reload = false) => {
    //cancel edit for this blockRef
    var blocks = blockEdit.map((block) => {
      if (block.onEdit === true) {
        block = {...block, 'onEdit': false};
        if (reload) block = {...block, 'reload': true};
      }
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }
  const setCancelBlockEdit = (blockRef, reload = false) => {
    //cancel edit for this blockRef
    var blocks = blockEdit.map((block) => {
      if (block.blockRef === blockRef) {
        block = {...block, 'onEdit': false};
        if (reload) block = {...block, 'reload': true};
      }
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }
  const setActiveEdit = () => {
    //cancel edit for this blockRef
    var blocks = blockEdit.map((block) => {
      block = {...block, 'onEdit': true};
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }
  const setActiveBlockEdit = (blockRef) => {
    //active edit for this blockRef
    var blocks = blockEdit.map((block) => {
      if (block.blockRef === blockRef) block = {...block, 'onEdit': true};
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }

  //init mission
  var missionIdParam = parseInt(queryParams.get('id'));
  if (isNaN(missionIdParam)) missionIdParam = 0;
  const update = missionIdParam > 0 ? 1 : value.update;

  const [idMission, setIdMision] = useState(missionIdParam);
  const [missionRef, setMissionRef] = useState('-');

  const [canceledAt, setCanceledAt] = useState(null);
  const [missionCanceled, setMissionCanceled] = useState(false);

  //mission header
  const [oldPinned, setOldPinned] = useState(0);
  const [pinned, setPinned] = useState(0);

  const [createdMissionDate, setCreatedMissionDate] = useState("");
  const [modifiedMissionDate, setModifiedMissionDate] = useState("");

  const [dateReminder, setDateReminder] = useState(momentDate().add(15, 'days'));
  const [dateReminderChanged, setDateReminderChanged] = useState(false);

  //mission header : status
  const [filterStatusArrayOld, setFilterStatusArrayOld] = useState([]);
  const [missionStatus, setMissionStatus] = useState(0);

  //mission data
  const [missionType, setMissionType] = useState(0);
  const [portId, setPortId] = useState(0);
  const [surveyHandler, setSurveyHandler] = useState(0);
  const [principalsId, setPrincipalsId] = useState(0);
  const [principalsAlias, setPrincipalsAlias] = useState("");

  const [internalComment, setInternalComment] = useState("");
  const [publicComment, setPublicComment] = useState("");
  const [photosComment, setPhotosComment] = useState("");
  const [latestSyncAt, setLatestSyncAt] = useState("");

  const [currentStage, setCurrentStage] = useState(0);

  const [invoicer, setInvoicer] = useState(0);
  const [invoicesNote, setInvoicesNote] = useState("");
  const [rowsReceiversInvoice, setRowsReceiversInvoice] = useState([]);

  const [dateBegining, setDateBegining] = useState(null);
  const [dateCompletion, setDateCompletion] = useState(null);

  //composant data
  const [bls, setBLs] = useState([]);
  const [general, setGeneral] = useState({});
  const [receivers, setReceivers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [subInvoices, setSubInvoices] = useState([]);
  const [notificationLog, setNotificationLog] = useState([]);
  const [internalNotification, setInternalNotification] = useState([]);
  const [nInternalNotifNoRead, setNRowsInternalNotifNoRead] = React.useState([]);

  //treatment
  const [saveIsFinished, setSaveIsFinished] = useState(false);
  const [reloadFileBlock, setReloadFileBlock] = useState(false);


  //useEffect
  useEffect(() => {
    if (update == 1) {
      if (idMission === 0) {
        navigate('/createMission', { replace: true });
      } else if (!!actionSave != false) {
        if (actionSave == 1) {
          setSnackBarMessage("Mission saved.");
          setSnackBarType("success");
        } else {
          setSnackBarMessage("Problem saving mission.");
          setSnackBarType("error");
        }
        setOpenSnackBar(true);
      }
    }

    // reload variables if id set
    if (idMission > 0) {
      //Get details mission
      getMissionDetail();
    } else {
      setActiveEdit();
    }
  }, []);

  useEffect(() => {
    if (canceledAt != null) {
      setMissionCanceled(true);
    }
  }, [canceledAt]);


  //function
  const getMissionDetail = async () => {
    setLoadingUpdate(false);
    try {
      var data = new FormData();
      var jsonRequestData = JSON.stringify(Object.fromEntries(data));

      var config = {
        url: GETMISSION+"?id_mission="+idMission,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestData
      };
      
      const reponseGet = await axios(config);

      //INIT general data
      var gnrlData = structuredClone(reponseGet.data);
      delete gnrlData.invoices;
      delete gnrlData.receivers;
      delete gnrlData.bills_of_lading;
      delete gnrlData.notifications_log;
      delete gnrlData.internal_notifications;
      delete gnrlData.subcontractor_invoices;
      setGeneral(gnrlData);

      //INIT initValues
      //INIT receiver data
      setReceivers(reponseGet.data.receivers);

      //INIT bill of lading
      setBLs(reponseGet.data.bills_of_lading);

      //INIT invoice data
      setInvoices(reponseGet.data.invoices);

      //INIT sub invoice data
      setSubInvoices(reponseGet.data.subcontractor_invoices);

      //INIT notification log data
      setNotificationLog(reponseGet.data.notifications_log);

      //INIT notification log data
      setInternalNotification(reponseGet.data.internal_notifications);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingUpdate(true);
    }
  }

  // REFRESH SELECT COMPANY
  function companySelectRefresh(){
    var blockOnEdit = blockEdit.findIndex(obj => (obj.blockRef === generalRef && obj.onEdit === true));
    if (blockOnEdit >= 0) {
      companyPrincipalsSelectRef.current.getList();
      companyTraderSelectRef.current.getList();
      companyInsuredSelectRef.current.getList();
      companyLocalOfficeSelectRef.current.getList();
      companyWarehouseKeeperSelectRef.current.getList();
    }
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  function CustomNoRowsOverlay() {
    return (<div className="customNoRowsOverlay"></div>);
  }


  return (
    <ContextMissionDetails.Provider
      value=
      {{
        //config context
        update,
        getMissionDetail,
        saveIsFinished, setSaveIsFinished,

        //composant data ref
        contentRef, headerRef, generalRef, receiverRef, blRef, noteRef, invoiceRef, subInvoiceRef, fileRef, protisRef,
        companyPrincipalsSelectRef, companyTraderSelectRef, companyInsuredSelectRef, companyLocalOfficeSelectRef, companyWarehouseKeeperSelectRef,

        //composant company ref
        companySelectRefresh,

        //generic data
        blockEdit, setBlockEdit,
        blockSave, setBlockSave,
        setReloadBlockData, setCancelEdit, setCancelBlockEdit, setActiveBlockEdit,

        loading, setLoading,
        loadingUpdate, setLoadingUpdate,

        openSnackBar, setOpenSnackBar,
        snackBarType, setSnackBarType,
        snackBarMessage, setSnackBarMessage,
        setSnackErrorMsg,

        idMission, setIdMision,
        missionRef, setMissionRef,

        canceledAt, setCanceledAt,
        missionCanceled, setMissionCanceled,
        
        //header data
        oldPinned, setOldPinned,
        pinned, setPinned,

        createdMissionDate, setCreatedMissionDate,
        modifiedMissionDate, setModifiedMissionDate,

        filterStatusArrayOld, setFilterStatusArrayOld,
        missionStatus, setMissionStatus,
        dateReminderChanged, setDateReminderChanged,
        dateReminder, setDateReminder,

        //mission data
        dateBegining, setDateBegining,
        dateCompletion, setDateCompletion,

        missionType, setMissionType,
        portId, setPortId,
        surveyHandler, setSurveyHandler,
        principalsId, setPrincipalsId,
        principalsAlias, setPrincipalsAlias,

        //comment
        internalComment, setInternalComment,
        publicComment, setPublicComment,
        photosComment, setPhotosComment,
        latestSyncAt, setLatestSyncAt,
        currentStage, setCurrentStage,
        nInternalNotifNoRead, setNRowsInternalNotifNoRead,

        invoicesNote, setInvoicesNote,
        invoicer, setInvoicer,
        rowsReceiversInvoice, setRowsReceiversInvoice,

        //composant
        bls, setBLs,
        general, setGeneral,
        invoices, setInvoices,
        receivers, setReceivers,
        subInvoices, setSubInvoices,
        notificationLog, setNotificationLog,
        internalNotification, setInternalNotification,

        //treatment
        reloadFileBlock, setReloadFileBlock,

        //html
        CustomNoRowsOverlay,
        handleRowEditStart,
        handleRowEditStop
      }}
    >
      {children}
    </ContextMissionDetails.Provider>
  );
};

export { MissionDetailsProvider, ContextMissionDetails };
