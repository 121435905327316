import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {isAdminRole, isPortRole, isCustomerRole} from 'components/config/Roles';

import { ContextMissionDetails } from "./ContextMissionDetails";

import axios from 'api/axios';


const ContextMissionWorkflow = createContext();

const MissionWorkflowProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const [JLBPortMode] = useState(isPortRole(localStorage.getItem("role")));

  //API URL
  const GETWORKFLOW= 'workflow';
  const GETMISSIONWORKFLOW= 'mission/workflow';
  const WORKFLOWBUTTONCLICK= 'mission/workflow/button';

  //composant ref
  const contentRef = useContext(ContextMissionDetails);
  const fileFormRef = useContext(ContextMissionDetails);

  //init mission
  const { getMissionDetail } = useContext(ContextMissionDetails);
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);
  const {filterStatusArrayOld, setFilterStatusArrayOld} = useContext(ContextMissionDetails);
  
  //init param
  const {loading, setLoading} = useContext(ContextMissionDetails);
  const {loadingUpdate, setLoadingUpdate} = useContext(ContextMissionDetails);

  const {openSnackBar, setOpenSnackBar} = useContext(ContextMissionDetails);
  const {snackBarMessage, setSnackBarMessage} = useContext(ContextMissionDetails);
  const {snackBarType, setSnackBarType} = useContext(ContextMissionDetails);
  const {setSnackErrorMsg} = useContext(ContextMissionDetails);

  //workflow state
  const [workflowFind, setWorkflowFind] = useState(false);
  const [workflowLoaded, setWorkflowLoaded] = useState(false);
  const [manualSettingsLoaded, setManualSettingsLoaded] = useState(false);
  const [fieldsSettingsLoaded, setFieldsSettingsLoaded] = useState(false);

  const [controlFields, setControlFields] = useState(Array());
  const [controlButtons, setControlButtons] = useState(Array());
  const [controlDocuments, setControlDocuments] = useState(Array());

  const [checkWorkflowData, setCheckWorkflowData] = useState(true);
  const [controlFieldsError, setControlFieldsError] = useState([]);

  const [openInfosPopin, setOpenInfosPopin] = useState(false);
  const [infosPopinType, setInfosPopinType] = useState("");
  const [infosPopinMsg, setInfosPopinMsg] = useState("");
  const [infosPopinTitle, setInfosPopinTitle] = useState("");

  const [workflowMailingContent, setWorkflowMailingContent] = useState('');
  const [workflowMailingListContacts, setWorkflowMailingListContacts] = useState([]);
  const [workflowMailingConfirmOpen, setWorkflowMailingConfirmOpen] = useState(false);
  const [workflowMailingCustomiseOpen, setWorkflowMailingCustomiseOpen] = useState(false);

  //treatment
  const [idButtonWorkflow, setIdButtonWorkflow] = useState(null);
  const [controlFieldsBL, setControlFieldsBL] = useState([]);
  const [controlFieldsNote, setControlFieldsNote] = useState([]);
  const [controlFieldsGeneral, setControlFieldsGeneral] = useState([]);
  const [controlFieldsReceiver, setControlFieldsReceiver] = useState([]);
  const [controlFieldsInvoice, setControlFieldsInvoice] = useState([]);
  const [controlFieldsSubInvoice, setControlFieldsSubInvoice] = useState([]);
  
  const {setActiveBlockEdit} = useContext(ContextMissionDetails);
  const {blockEdit, setBlockEdit} = useContext(ContextMissionDetails);
  const {saveIsFinished, setSaveIsFinished} = useContext(ContextMissionDetails);


  //useEffect
  useEffect(() => {
    //load conf basic field
    if (!workflowLoaded && !JLBPortMode) {
      var controlFieldsInt = Array();
      controlFieldsInt.push({id:'id_port', required:1});
      controlFieldsInt.push({id:'id_principal', required:1});
      controlFieldsInt.push({id:'id_mission_type', required:1});
      controlFieldsInt.push({id:'id_survey_handler', required:1});
      setControlFields(controlFieldsInt);
    }
    // reload variables if id set
    if (idMission > 0) {
      if (JLBPortMode) {
        initPortflow();
      }
      //Get Workflow configuration
      getMissionWorkflow();
    }
  }, []);

  useEffect(()=> { 
    setFieldsSettingsLoaded(workflowLoaded || manualSettingsLoaded);
  },[workflowLoaded, manualSettingsLoaded]);

  //if id button, launch validation workflow step
  useEffect(() => {
    if (saveIsFinished && workflowLoaded && checkWorkflowData && idButtonWorkflow != null) {
      var button = controlButtons.find(obj => obj.id === idButtonWorkflow);
      if (button) {
        if (button?.trigger_external_notif != null) {
          setWorkflowMailingConfirmOpen(true);
        } else {
          workflowStep();
        }
      }
    } else if (!checkWorkflowData) {
      contentRef.contentRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [workflowLoaded, checkWorkflowData, idButtonWorkflow, saveIsFinished]);

  
  //data config workflow
  //MAPPING FIELD'S KEY => NAME
  const controlFieldsMapping = []; 
  //general
  controlFieldsMapping["mission_type"] = "id_mission_type";
  controlFieldsMapping["port"] = "id_port";
  controlFieldsMapping["principal"] = "id_principal";
  controlFieldsMapping["principals_ref"] = "principal_ref";
  controlFieldsMapping["survey_handler"] = "id_survey_handler";
  controlFieldsMapping["local_office"] = "id_local_office";
  controlFieldsMapping["local_surveyor"] = "id_local_surveyor";
  controlFieldsMapping["vessel"] = "id_vessel";
  controlFieldsMapping["insured"] = "id_insured";
  controlFieldsMapping["trader"] = "id_trader";
  controlFieldsMapping["eta"] = "estimated_time_arrival";
  controlFieldsMapping["etb"] = "estimated_time_berthing";
  controlFieldsMapping["date_completion_operations"] = "operations_completion_date";
  controlFieldsMapping["current_stage"] = "id_current_stage";
  //receiver
  controlFieldsMapping["receiver_item"] = "id_receiver";
  controlFieldsMapping["receiver_item_tonnage"] = "r.tonnage";
  controlFieldsMapping["receiver_item_cargo"] = "id_cargo";
  controlFieldsMapping["receiver_item_mission_ending"] = "id_mission_ending";
  //receiver lot
  controlFieldsMapping["lot_item_packaging"] = "id_packaging";
  controlFieldsMapping["lot_item_origin"] = "id_cargo_origin";
  controlFieldsMapping["lot_item_type_of_loading"] = "id_loading_type";
  controlFieldsMapping["lot_item_brand"] = "id_cargo_brand";
  controlFieldsMapping["lot_item_quality"] = "id_cargo_quality";
  controlFieldsMapping["lot_item_quantity"] = "packaging_quantity";
  controlFieldsMapping["lot_item_net_tonnage"] = "net_tonnage";
  controlFieldsMapping["lot_item_gross_tonnage"] = "gross_tonnage";
  controlFieldsMapping["lot_item_empty_spare_packaging_percentage"] = "empty_spare_packaging_percentage";
  //invoice
  controlFieldsMapping["invoice_note"] = "invoices_note";


  //workflow function
  function triggerInfosPopin(title, msg, type) {
    //open error poppin
    setOpenInfosPopin(true);
    setInfosPopinMsg(msg);
    if (typeof type == "string")
      setInfosPopinType(type);
    if (typeof title == "string")
      setInfosPopinTitle(title);
  }


  function canRead(key) {
    if ((!workflowFind && !JLBPortMode) || isAdminRole(localStorage.getItem("role"))) return 1;
    var itemId = controlFields.findIndex(obj => obj.id.toUpperCase() === key.toUpperCase());
    if (itemId >= 0) return controlFields[itemId].read;
    return 0;
  }

  function canWrite(key) {
    if (missionCanceled) return 0;
    if ((!workflowFind && !JLBPortMode) || isAdminRole(localStorage.getItem("role"))) return 1;
    var itemId = controlFields.findIndex(obj => obj.id.toUpperCase() === key.toUpperCase());
    if (itemId >= 0) return controlFields[itemId].write;
    return 0;
  }

  function canUpload(id) {
    if (missionCanceled) return false;
    if (!workflowFind) return true;
    var itemId = controlDocuments.findIndex(obj => obj.id_file_type === id);
    if (itemId >= 0) return true;
    return false;
  }

  function isRequired(key, elmt) {
    switch (elmt) {
      case 'file':
        var itemId = controlDocuments.findIndex(obj => obj.id_file_type === key);
        if (itemId >= 0) return Boolean(controlDocuments[itemId].required);
      break;

      default:
        var itemId = controlFields.findIndex(obj => obj.id.toUpperCase() === key.toUpperCase());
        if (itemId >= 0) return Boolean(controlFields[itemId].required);
      break;
    }
    return false;
  }

  function checkControlFieldsErrorExist(fieldKey, multiFields = false) {
    return !multiFields ? getControlFieldsErrorState(fieldKey) : checkControlMultiFieldsErrorExist(fieldKey);
  }
  function checkControlMultiFieldsErrorExist(fieldKey) {
    var itemId = controlFieldsError.findIndex(obj => obj.id.toUpperCase().substr(0, fieldKey.length) === fieldKey.toUpperCase());
    return (itemId >= 0);
  }
  function getControlFieldsErrorState(fieldKey) {
    var itemId = controlFieldsError.findIndex(obj => obj.id.toUpperCase() === fieldKey.toUpperCase());
    if (itemId >= 0) return controlFieldsError[itemId].error;
    return false;
  }
  function getControlFieldsErrorMsg(fieldKey) {
    var itemId = controlFieldsError.findIndex(obj => obj.id.toUpperCase() === fieldKey.toUpperCase());
    if (itemId >= 0) return controlFieldsError[itemId].helpertext;
    return "";
  }
  //control error by block
  function checkBlockOnWorkflowError(blockRef, controlFieldsMapping = [], multiFields = false) {
    if (controlFieldsError.length > 0) {
      var blockOnEdit = blockEdit.findIndex(obj => (obj.blockRef === blockRef && obj.onEdit === false));
      if (blockOnEdit >= 0) {
        controlFieldsMapping.map((fields) => {
          var blockOnWorkflowError = checkControlFieldsErrorExist(fields, multiFields);
          if (blockOnWorkflowError !== false) {
            setActiveBlockEdit(blockRef);
          }
        });
      }
    }
  }
  //toogle display on edit by block
  function toogleBlockOnEditAndCheckBlockOnWorkflowError(blockRef, currentEdit, setCurrentEdit, controlFieldsMapping = [], multiFields = false) {
    //maj onEdit state
    var blockOnEdit  = blockEdit.findIndex(obj => (obj.blockRef === blockRef && obj.onEdit === true));
    var newStateEdit = blockOnEdit >= 0 ? true : false;
    if (currentEdit != newStateEdit) {
      var fieldsBlockInError = false;
      if (controlFieldsError.length > 0) {
        controlFieldsMapping.map((fields) => {
          var blockOnWorkflowError = checkControlFieldsErrorExist(fields, multiFields);
          if (blockOnWorkflowError !== false) {
            fieldsBlockInError = true;
            if (!newStateEdit) {
              setActiveBlockEdit(blockRef);
            }
          }
        });
      }
      if (!fieldsBlockInError || newStateEdit) {
        setCurrentEdit(newStateEdit);
      }
    }
  }

  async function requiredFieldsErrorLog(itemKey, itemVal, errorKey) {
    //check field is a controlled field
    var fieldControl = controlFields.findIndex(obj => obj.id.toUpperCase() === itemKey.toUpperCase());
    if (fieldControl >= 0) {
      //delete error field log if exist
      var errorId  = controlFieldsError.findIndex(obj => obj.id.toUpperCase() === errorKey.toUpperCase());
      if (errorId >= 0) controlFieldsError.splice(errorId, 1);
      //check valid data
      if (isRequired(itemKey) && (!!itemVal == false || itemVal == "null" || itemVal == "0")) {
        //init error msg
        controlFieldsError.push({id: errorKey, error: true, helpertext: 'Required value'});
        //init false not valid workflow
        setCheckWorkflowData(false);
      }
    }
  }

  async function controlBasicFieldsData(data) {
    if (typeof data != "undefined") {
      for (const item of data.entries()) {
        var itemKey = item[0], itemVal = item[1], errorKey = item[0];
        //check&log required field
        requiredFieldsErrorLog(itemKey, itemVal, errorKey);
      }
      setControlFieldsError(controlFieldsError);
    } else {
      //init false not valid workflow
      setCheckWorkflowData(false);
    }
  }

  async function controlFieldsData(data) {
    if (workflowLoaded) {
      if (typeof data != "undefined") {
        for (const item of data.entries()) {
          var itemKey = item[0], itemVal = item[1], errorKey = item[0];
          //check&log required field
          requiredFieldsErrorLog(itemKey, itemVal, errorKey);
        }
        setControlFieldsError(controlFieldsError);
      } else {
        //init false not valid workflow
        setCheckWorkflowData(false);
      }
    }
  }

  async function controlMultipleFieldsData(data) {
    if (workflowLoaded) {
      if (typeof data != "undefined") {
        for (const item of data) {
          var itemKey = item.key, itemVal = item.val, errorKey = item.key+item.id;
          //check&log required field
          requiredFieldsErrorLog(itemKey, itemVal, errorKey);
        }
        setControlFieldsError(controlFieldsError);
      } else {
        //init false not valid workflow
        setCheckWorkflowData(false);
      }
    }
  }

  async function controlDocumentsData(data) {
    if (workflowLoaded) {
      //check required type exist
      var requiredDoc = controlDocuments.findIndex(obj => obj.required === 1);
      if (requiredDoc >= 0) {
        if (!!data != false) {
          var docError = [], docTypes = fileFormRef.current.getFileTypesList();
          for (const item of controlDocuments) {
            var itemType = item.id_file_type, itemRequired = item.required;
            //check data doc type required exist
            if (itemRequired) {
              var checkData = data.findIndex(obj => obj.type === itemType);
              //if not doc required then "log" error
              if (checkData < 0) {
                var docTypeIndex = docTypes.findIndex(obj => obj.value === itemType);
                docError.push(docTypes[docTypeIndex].label);
              }
            }
          }
        } else {
          //get label of type
          var docError = [];
          controlDocuments.map((elmt, i) => {
            if(elmt.required)
              docError.push(elmt.label);
          });
        }

        //open error poppin if error exist
        if (docError.length > 0) {
          //set error msg
          var errorFileTypesList = docError.join("\n- ");
          var errorMsg = "- " + errorFileTypesList;
          //open error poppin
          triggerInfosPopin("Files required", errorMsg, "error");
          //init false not valid workflow
          setCheckWorkflowData(false);
        }
      }
    }
  }

  async function controlFieldsErrorTruncate(controlFieldsMapping = null) {
    if (controlFieldsMapping != null && controlFieldsMapping.length > 0) {
      if (controlFieldsError.length > 0) {
        var fieldsError = structuredClone(controlFieldsError);
        controlFieldsMapping.map((fields) => {
          if (fieldsError.length > 0) {
            fieldsError = fieldsError.filter(obj => obj.id.toUpperCase() !== fields.toUpperCase());
          }
        });
        if (fieldsError.length != controlFieldsError.length) {
          setControlFieldsError(fieldsError);
        }
      }
    } else {
      setControlFieldsError([]);
    }
  }

  //data function
  function initPortflow() {
    var controlFieldsInt = Array();
    //GENERAL
    controlFieldsInt.push({id:'id_mission_type', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'id_port', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'id_survey_handler', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'id_local_office', required: 0, read: 0, write: 0});
    controlFieldsInt.push({id:'id_local_surveyor', required: 0, read: 0, write: 0});
    controlFieldsInt.push({id:'id_principal', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'principal_ref', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'id_trader', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'id_insured', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'insured_ref', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'id_vessel', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'vessel_tonnage', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'id_warehouse', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'id_control_type', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'estimated_time_arrival', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'estimated_time_berthing', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'date_of_berthing', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'completion_of_discharge', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'date_vessel_departure', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'next_port_of_discharge', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'id_current_stage', required: 0, read: 1, write: 0});
    //RECEIVER
    controlFieldsInt.push({id:'receiver_block', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'id_receiver', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'r.tonnage', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'id_cargo', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'id_mission_ending', required: 0, read: 1, write: 1});
    //RECEIVER LOT
    controlFieldsInt.push({id:'id_packaging', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'id_cargo_origin', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'id_loading_type', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'id_cargo_brand', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'id_cargo_quality', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'id_cargo_variety', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'packaging_quantity', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'net_tonnage', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'gross_tonnage', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'empty_spare_packaging_percentage', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'empty_spare_packaging_quantity', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'number_of_container', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'container_type', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'bills_of_lading', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'companies_stevedore', required: 0, read: 1, write: 1});
    //BL
    controlFieldsInt.push({id:'bl_block', required: 0, read: 1, write: 1});
    //DATES MISSIONS
    controlFieldsInt.push({id:'operations_begining_date', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'operations_completion_date', required: 0, read: 1, write: 0});
    //NOTES
    controlFieldsInt.push({id:'internal_note', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'public_note', required: 0, read: 0, write: 0});
    //REPORT AND INVOICE
    controlFieldsInt.push({id:'invoiced_by', required: 0, read: 1, write: 0});
    controlFieldsInt.push({id:'subInvoice_block', required: 0, read: 1, write: 1});
    controlFieldsInt.push({id:'invoice_block', required: 0, read: 0, write: 0});
    controlFieldsInt.push({id:'invoices_note', required: 0, read: 0, write: 0});
    //FILE
    controlFieldsInt.push({id:'file_block', required: 0, read: 1, write: 1});

    setControlFields(controlFieldsInt);
    setManualSettingsLoaded(true);
  }

  function initWorkflow(data) {
    var item = {};
    var itemIndex = 0;

    //INITIALISATION FIELDS
    var controlFieldsInt = Array();
    for (let indexField = 0; indexField < data.fields.length; indexField++) {
      var key = data.fields[indexField].key;
      var keyName  = (typeof controlFieldsMapping[key] != "undefined" ? controlFieldsMapping[key] : key);
      var required = data.fields[indexField].required;
      var read = 0; var write = 0;
      for (var permission = 0; permission < data.fields[indexField].permissions.length; permission++) {
        if (data.fields[indexField].permissions[permission].user_group.role == localStorage.getItem("role")) {
          read  = data.fields[indexField].permissions[permission].read;
          write = data.fields[indexField].permissions[permission].write;
          break;
        }
      }
      item = {id:keyName, required:required, read:read, write:write};
      controlFieldsInt.push(item);
    }

    //INITIALISATION BUTTONS
    var controlButtonsInt = Array();
    for (let indexButton = 0; indexButton < data.buttons.length; indexButton++) {
      var key = data.buttons[indexButton].key;
      for (var permission = 0; permission < data.buttons[indexButton].permissions.length; permission++) {
        if (data.buttons[indexButton].permissions[permission].user_group.role == localStorage.getItem("role")
        || isAdminRole(localStorage.getItem("role"))) {
          itemIndex = controlButtonsInt.findIndex(obj => obj.id === key);
          //retrieve triggers list
          var trigger = null, trigger_action = null;
          data.buttons[indexButton]?.triggers?.map((obj) => {
            if (obj?.action?.notification?.type == 'notif_email') {
              if (obj?.action?.notification?.to?.length > 0) {
                Boolean(obj?.action?.notification?.customisable) && obj?.action?.notification?.to?.some(toj => isCustomerRole(toj.user_group.role)) 
                ? trigger = true
                : trigger = false;
              }
              if (Boolean(obj?.action?.notification?.customisable) && obj?.action?.notification?.to?.some(toj => isCustomerRole(toj.user_group.role))) {
                trigger_action = obj?.action;
              }
            }
           });

          item = {id:data.buttons[indexButton].id, key:key, label:data.buttons[indexButton].label, trigger_action: trigger_action, trigger_external_notif: trigger};

          if (itemIndex < 0) controlButtonsInt.push(item);
          else controlButtonsInt[itemIndex] = item;

          break;
        }
      }
    }

    //INITIALISATION DOCUMENTS
    var controlDocumentsInt = Array();
    for (let indexDoc = 0; indexDoc < data.documents.length; indexDoc++) {
      var key = data.documents[indexDoc].key;
      for (var permission = 0; permission < data.documents[indexDoc].permissions.length; permission++) {
        if (data.documents[indexDoc].permissions[permission].user_group.role == localStorage.getItem("role")
        || isAdminRole(localStorage.getItem("role"))) {
          itemIndex = controlDocumentsInt.findIndex(obj => obj.id === key);
          item = {
            key:key, 
            id:data.documents[indexDoc].id, 
            label:data.documents[indexDoc].label, 
            required:data.documents[indexDoc].required,
            id_file_type:data.documents[indexDoc].id_file_type
          };

          if (itemIndex < 0) controlDocumentsInt.push(item);
          else controlDocumentsInt[itemIndex] = item;

          break;
        }
      }
    }
    
    setControlFields(controlFieldsInt);
    setControlButtons(controlButtonsInt);
    setControlDocuments(controlDocumentsInt);
  }

  const getWorkflow = async (missionType) => {
    try {
      var config = {
        url: GETWORKFLOW+"?id_mission_type="+missionType,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        }
      };

      const reponseGet = await axios(config);
      reponseGet.data.data.steps.sort((a, b) => (a.status.value > b.status.value) ? 1 : -1);
      
      var currentStatus = 2;  //status:2 of start of creation mission
      if(idMission > 0) currentStatus = parseInt(filterStatusArrayOld[0]);
      var index = currentStatus-1;

      //INITIALISATION FIELDS - BUTTONS - DOCUMENTS
      initWorkflow(reponseGet.data.data.steps[index]);
      setWorkflowFind(true);
      setWorkflowLoaded(true);
      navigate(window.location, { replace: false });
    } catch (err) {
      setWorkflowFind(false);
      setWorkflowLoaded(false);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
    }
  }

  const getMissionWorkflow = async () => {
    try {
      if (idMission != 0) {
        var data = new FormData();
        var jsonRequestData = JSON.stringify(Object.fromEntries(data));

        var config = {
          url: GETMISSIONWORKFLOW+"?id_mission="+idMission,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestData,
        };

        const reponseGet = await axios(config);
        reponseGet.data.data.workflow.steps.sort((a, b) => (a.status.value > b.status.value) ? 1 : -1);

        var currentStatus = reponseGet.data.data.mission_status;
        var index = currentStatus-1;

        //INITIALISATION FIELDS - BUTTONS - DOCUMENTS
        initWorkflow(reponseGet.data.data.workflow.steps[index]);
        setWorkflowFind(true);
        setWorkflowLoaded(true);
      }
    } catch (err) {
      setWorkflowFind(false);
      setWorkflowLoaded(false);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
    }
  }

  const workflowMailingStep = async () => {
    var button = controlButtons.find(obj => obj.id === idButtonWorkflow);
    if (button) {
      if (button?.trigger_external_notif === false) {
        workflowStep(true);
      } else {
        setWorkflowMailingCustomiseOpen(true);
      }
    }
  }

  const workflowStep = async (sendEmail = false) => {
    //CLICK WORKFLOW BUTTON
    setLoading(true);
    setLoadingUpdate(false);
    try {
      var data = new FormData();
      data.append('id_mission', idMission);
      data.append('id_button', idButtonWorkflow);

      //add data mailing if exist
      data.append('send_email', sendEmail ? 1 : 0);
      if (sendEmail) {
        if (workflowMailingListContacts.length > 0) {
          data.append('email_content', workflowMailingContent);
          workflowMailingListContacts.forEach(contact => data.append('email_to[]', contact.email));
        }
      }

      var config = {
        url: WORKFLOWBUTTONCLICK,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: data
      };
      const response = await axios(config);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoading(false);
      setLoadingUpdate(true);
      setSaveIsFinished(false);
      setIdButtonWorkflow(null);
      //reload data api
      await getMissionDetail();
      await getMissionWorkflow();
    }
  }


  return (
    <ContextMissionWorkflow.Provider
      value=
      {{
        workflowFind, setWorkflowFind,
        workflowLoaded, setWorkflowLoaded,
        controlFields, setControlFields,
        controlFieldsError, setControlFieldsError,
        controlDocuments, setControlDocuments,
        controlButtons, setControlButtons,
        checkWorkflowData, setCheckWorkflowData,
        fieldsSettingsLoaded, setFieldsSettingsLoaded,

        controlFieldsNote, setControlFieldsNote,
        controlFieldsGeneral, setControlFieldsGeneral,
        controlFieldsReceiver, setControlFieldsReceiver,
        controlFieldsBL, setControlFieldsBL,
        controlFieldsSubInvoice, setControlFieldsSubInvoice,
        controlFieldsInvoice, setControlFieldsInvoice,
        
        checkBlockOnWorkflowError,
        toogleBlockOnEditAndCheckBlockOnWorkflowError,

        getWorkflow, canRead, canWrite, canUpload, isRequired,
        controlFieldsData, controlMultipleFieldsData, controlDocumentsData, controlBasicFieldsData, controlFieldsErrorTruncate,
        checkControlMultiFieldsErrorExist, getControlFieldsErrorState, getControlFieldsErrorMsg, triggerInfosPopin,
        workflowStep, workflowMailingStep,

        openInfosPopin, setOpenInfosPopin,
        infosPopinType, setInfosPopinType,
        infosPopinMsg, setInfosPopinMsg,
        infosPopinTitle, setInfosPopinTitle,

        idButtonWorkflow, setIdButtonWorkflow,

        workflowMailingContent, setWorkflowMailingContent,
        workflowMailingListContacts, setWorkflowMailingListContacts,
        workflowMailingConfirmOpen, setWorkflowMailingConfirmOpen,
        workflowMailingCustomiseOpen, setWorkflowMailingCustomiseOpen
      }}
    >
      {children}
    </ContextMissionWorkflow.Provider>
  );
};

export { MissionWorkflowProvider, ContextMissionWorkflow };
