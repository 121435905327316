import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";

import axios from 'api/axios';


const ContextMissionDetailsHeader = createContext();

const MissionDetailsHeaderProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const {update} = useContext(ContextMissionDetails);
  const { headerRef } = useContext(ContextMissionDetails);

  //API URL
  const MISSION_URL = 'mission';
  const PINED_URL = MISSION_URL+'/pin';

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextMissionDetails);

  //init id mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  //mission general state
  const {general, setGeneral} = useContext(ContextMissionDetails);

  const {missionRef, setMissionRef} = useContext(ContextMissionDetails);
  const {missionStatus, setMissionStatus} = useContext(ContextMissionDetails);
  const [missionColor, setMissionColor] = useState("none");

  //header
  const [effectiveChange, setEffectiveChange] = useState(0);

  const { oldPinned, setOldPinned } = useContext(ContextMissionDetails);
  const { pinned, setPinned } = useContext(ContextMissionDetails);

  const {createdMissionDate, setCreatedMissionDate} = useContext(ContextMissionDetails);
  const {modifiedMissionDate, setModifiedMissionDate} = useContext(ContextMissionDetails);

  const { dateReminderChanged, setDateReminderChanged } = useContext(ContextMissionDetails);
  const { dateReminder, setDateReminder } = useContext(ContextMissionDetails);
  const [oldDateReminder, setOldDateReminder] = useState(momentDate().add(15, 'days'));
  const [dateReminderTXT, setDateReminderTXT] = useState("-");

  const { filterStatusArrayOld, setFilterStatusArrayOld } = useContext(ContextMissionDetails);
  const [oldFilterStatusArrayOld, setOldFilterStatusArrayOld] = useState([]);
  const [filterStatusArray, setFilterStatusArray] = useState([]);

  const [statusFilter1, setStatusFilter1] = useState(false);
  const [resetStatusFilter1, setResetStatusFilter1] = useState(false);
  const [statusFilter2, setStatusFilter2] = useState(false);
  const [resetStatusFilter2, setResetStatusFilter2] = useState(false);
  const [statusFilter3, setStatusFilter3] = useState(false);
  const [resetStatusFilter3, setResetStatusFilter3] = useState(false);
  const [statusFilter4, setStatusFilter4] = useState(false);
  const [resetStatusFilter4, setResetStatusFilter4] = useState(false);
  const [statusFilter5, setStatusFilter5] = useState(false);
  const [resetStatusFilter5, setResetStatusFilter5] = useState(false);
  const [statusFilter6, setStatusFilter6] = useState(false);
  const [resetStatusFilter6, setResetStatusFilter6] = useState(false);
  const [statusFilter7, setStatusFilter7] = useState(false);
  const [resetStatusFilter7, setResetStatusFilter7] = useState(false);
  const [statusFilter8, setStatusFilter8] = useState(false);
  const [resetStatusFilter8, setResetStatusFilter8] = useState(false);
  const [statusFilter9, setStatusFilter9] = useState(false);
  const [resetStatusFilter9, setResetStatusFilter9] = useState(false);
  const [statusFilter10, setStatusFilter10] = useState(false);
  const [resetStatusFilter10, setResetStatusFilter10] = useState(false);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  const {blockEdit, setBlockEdit} = useContext(ContextMissionDetails);
  const {setReloadBlockData} = useContext(ContextMissionDetails);
  const {setActiveBlockEdit} = useContext(ContextMissionDetails);
  const {blockSave, setBlockSave} = useContext(ContextMissionDetails);
  const {setDoneBlockSave} = useContext(ContextMissionDetailsTreatment);
  const {setErrorBlockSave} = useContext(ContextMissionDetailsTreatment);
  const {saveBlockAndCheckWorkflow} = useContext(ContextMissionDetailsTreatment);


  //useEffect
  useEffect(() => {
    if (!(update == 1)) {
      //init status a 2
      setOldFilterStatusArrayOld([2]);
      setFilterStatusArrayOld([2]);
      setFilterStatusArray([2]);
      setStatusFilter2(true);
      setLoadingUpdate(true);
    }
  }, []);

  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {
      //dont update data if edition is in progress
      var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === headerRef && obj.onEdit === false));
      if (blockInEdit >= 0) {
        //(RE)INIT detect change
        setEffectiveChange(1);

        //INIT STATUS
        var statusDataLoad = 2;
        if (general.status) statusDataLoad = general.status.status.value;
        var currentFilter = filterStatusArrayOld.length > 0 
          ? filterStatusArrayOld.length > 1 ? filterStatusArrayOld[1] : filterStatusArrayOld[0]
          : null;
        
        //RELOAD data
        if (reloadData > oldReloadData || (filterStatusArrayOld.length > 0 && currentFilter != statusDataLoad)) {
          setOldReloadData(reloadData);
          //reset status
          if (filterStatusArrayOld.length > 0) {
            setResetStatusFilter1(currentFilter == 1 ? true : false);
            setResetStatusFilter2(currentFilter == 2 ? true : false);
            setResetStatusFilter3(currentFilter == 3 ? true : false);
            setResetStatusFilter4(currentFilter == 4 ? true : false);
            setResetStatusFilter5(currentFilter == 5 ? true : false);
            setResetStatusFilter6(currentFilter == 6 ? true : false);
            setResetStatusFilter7(currentFilter == 7 ? true : false);
            setResetStatusFilter8(currentFilter == 8 ? true : false);
            setResetStatusFilter9(currentFilter == 9 ? true : false);
            setResetStatusFilter10(currentFilter == 10 ? true : false);

            setFilterStatusArray([]);
            setFilterStatusArrayOld([currentFilter]);
          }
        }

        //INIT ref
        if (general.jlb_ref) setMissionRef(general.jlb_ref);
        setMissionColor(general.mission_type.color);

        //INIT dates de creation et de modification
        if (general.created_at) setCreatedMissionDate(momentDate(general.created_at).format("DD MMM YY"));
        else setCreatedMissionDate("-");
        if (general.modified_at) setModifiedMissionDate(momentDate(general.modified_at).format("DD MMM YY"));
        else setModifiedMissionDate("-");

        //INIT date reminder
        if (general.reminder_date) {
          setDateReminder(momentDate(general.reminder_date));
          setOldDateReminder(momentDate(general.reminder_date));
          setDateReminderTXT(momentDate(general.reminder_date).format("DD MMM YY"));
        } else {
          if (general.modified_at) {
            setDateReminder(momentDate(general.modified_at).add(15, 'days'));
            setOldDateReminder(momentDate(general.modified_at).add(15, 'days'));
            setDateReminderTXT(momentDate(general.modified_at).add(15, 'days').format("DD MMM YY"));
          } else {
            setDateReminder(momentDate(general.created_at).add(15, 'days'));
            setOldDateReminder(momentDate(general.created_at).add(15, 'days'));
            setDateReminderTXT(momentDate(general.created_at).add(15, 'days').format("DD MMM YY"));
          }
        }

        // INIT PINED
        setOldPinned(general.pinned ? 1 : 0);
        setPinned(general.pinned ? 1 : 0);

        //INIT STATUS
        setOldFilterStatusArrayOld([statusDataLoad]);
        setFilterStatusArrayOld([statusDataLoad]);
        setFilterStatusArray([statusDataLoad]);
        setMissionStatus(statusDataLoad);
        //
        if (statusDataLoad != 2) {
          setStatusFilter2(false);
          setResetStatusFilter2(true);
        }

        setStatusFilter1(statusDataLoad == 1 ? true : false);
        setStatusFilter2(statusDataLoad == 2 ? true : false);
        setStatusFilter3(statusDataLoad == 3 ? true : false);
        setStatusFilter4(statusDataLoad == 4 ? true : false);
        setStatusFilter5(statusDataLoad == 5 ? true : false);
        setStatusFilter6(statusDataLoad == 6 ? true : false);
        setStatusFilter7(statusDataLoad == 7 ? true : false);
        setStatusFilter8(statusDataLoad == 8 ? true : false);
        setStatusFilter9(statusDataLoad == 9 ? true : false);
        setStatusFilter10(statusDataLoad == 10 ? true : false);
      }
    }
  }, [general, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(headerRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockEdit.findIndex(obj => obj.blockRef === headerRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block edit
  useEffect(() => {
    // >= 2 ? => start at 0 and +1 for initialization of parameters
    //because first initialization of parameters call the useEffect
    if (effectiveChange >= 2) {
      if (dateReminder != oldDateReminder || pinned != oldPinned || filterStatusArrayOld[0] != oldFilterStatusArrayOld[0]) {
        setActiveBlockEdit(headerRef);
      }
    } else {
      setEffectiveChange(effectiveChange+1);
    }
  }, [dateReminder, pinned, filterStatusArrayOld]);

  //init block save
  useEffect(() => {
    saveBlockAndCheckWorkflow(headerRef, saveMissionDetailsHeader);
  }, [blockSave, idMission]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockSave.findIndex(obj => obj.blockRef === headerRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);


  //function interactive
  function setFilterStatus(filter, index, value) {
    if (value == true) {
      if (filterStatusArrayOld.length > 0) {
        var currentFilter = filterStatusArrayOld.length > 1 ? filterStatusArrayOld[1] : filterStatusArrayOld[0];

        setResetStatusFilter1(currentFilter == 1 ? true : false);
        setResetStatusFilter2(currentFilter == 2 ? true : false);
        setResetStatusFilter3(currentFilter == 3 ? true : false);
        setResetStatusFilter4(currentFilter == 4 ? true : false);
        setResetStatusFilter5(currentFilter == 5 ? true : false);
        setResetStatusFilter6(currentFilter == 6 ? true : false);
        setResetStatusFilter7(currentFilter == 7 ? true : false);
        setResetStatusFilter8(currentFilter == 8 ? true : false);
        setResetStatusFilter9(currentFilter == 9 ? true : false);
        setResetStatusFilter10(currentFilter == 10 ? true : false);
      }

      setStatusFilter1(index == 1 ? value : false);
      setStatusFilter2(index == 2 ? value : false);
      setStatusFilter3(index == 3 ? value : false);
      setStatusFilter4(index == 4 ? value : false);
      setStatusFilter5(index == 5 ? value : false);
      setStatusFilter6(index == 6 ? value : false);
      setStatusFilter7(index == 7 ? value : false);
      setStatusFilter8(index == 8 ? value : false);
      setStatusFilter9(index == 9 ? value : false);
      setStatusFilter10(index == 10 ? value : false);

      setFilterStatusArray([]);
      if (filterStatusArray.length > 1) {
        currentFilter = filterStatusArray[1];
        setFilterStatusArray([currentFilter]);
      }

      setFilterStatusArrayOld([currentFilter]);
    }
  }

  //function treatment
  const getDataSaveMissionDetailsHeader = async () => {
    var data = new FormData();
    data.append('id_mission', Number(idMission));

    //OBLIGATOIRE
    data.append('id_status', parseInt(filterStatusArrayOld[0]));

    //INIT date reminder
    if (momentDate().diff(dateReminder, 'days') > 0) {
      data.append('reminder_date', null);
    } else {
      if (dateReminderChanged) {
        data.append('reminder_date', momentDate(dateReminder).format("YYYY-MM-DD"));
      }
    }

    return data;
  }

  const saveMissionDetailsHeader = async () => {
    try {
      var saveHeaderPromises = [];
      var data = await getDataSaveMissionDetailsHeader();

      //API DATA SENT
      var jsonRequestData = JSON.stringify(Object.fromEntries(data));
      var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, 'null');    //PATCH ERREUR NULL

      var config = {
        method: 'put',
        url: MISSION_URL,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestDataFinal
      };

      const response = await axios(config);
      saveHeaderPromises.push(response);

      //Pined missions
      if (pinned != oldPinned) {
        var data = new FormData();
        data.append('id_mission', Number(idMission));
        if (!pinned) data.append('pinned', '0');
        else data.append('pinned', parseInt(pinned));

        var config = {
          url: PINED_URL,
          method: 'post',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data'
          },
          data: data
        };
        const response = await axios(config);
        saveHeaderPromises.push(response);
        
        if (response) {
          setOldPinned(pinned);
        }
      }

      await Promise.all(saveHeaderPromises);
      setDoneBlockSave(headerRef);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setErrorBlockSave(headerRef);
      }
    }
  };


  return (
    <ContextMissionDetailsHeader.Provider
      value=
      {{
        missionColor, setMissionColor,
        dateReminderTXT, setDateReminderTXT,

        filterStatusArray, setFilterStatusArray,
        statusFilter1, setStatusFilter1,
        resetStatusFilter1, setResetStatusFilter1,
        statusFilter2, setStatusFilter2,
        resetStatusFilter2, setResetStatusFilter2,
        statusFilter3, setStatusFilter3,
        resetStatusFilter3, setResetStatusFilter3,
        statusFilter4, setStatusFilter4,
        resetStatusFilter4, setResetStatusFilter4,
        statusFilter5, setStatusFilter5,
        resetStatusFilter5, setResetStatusFilter5,
        statusFilter6, setStatusFilter6,
        resetStatusFilter6, setResetStatusFilter6,
        statusFilter7, setStatusFilter7,
        resetStatusFilter7, setResetStatusFilter7,
        statusFilter8, setStatusFilter8,
        resetStatusFilter8, setResetStatusFilter8,
        statusFilter9, setStatusFilter9,
        resetStatusFilter9, setResetStatusFilter9,
        statusFilter10, setStatusFilter10,
        resetStatusFilter10, setResetStatusFilter10,

        setFilterStatus
      }}
    >
      {children}
    </ContextMissionDetailsHeader.Provider>
  );
};

export { MissionDetailsHeaderProvider, ContextMissionDetailsHeader };
