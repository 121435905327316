import React from 'react';

import momentDate from 'moment';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { DataGridPro } from '@mui/x-data-grid-pro';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import 'styles/CreateMission.css';

export default function MissionNotificationLog(props){
        
    function CustomNoRowsOverlay() {
        return (<div className="customNoRowsOverlay">no email sent</div>);
    }

    const renderMessage = (props) => {
        if (props.row.content != '') {
            return (
                <Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{props.row.content}</span>}
                placement="right">
                <IconButton>
                    <FormatListBulletedIcon />
                </IconButton>
                </Tooltip>
            );
        }
        else return (<></>);
    };

    const columnsNotificationLog : GridColDef[] = [
      { field: 'type',    headerName: 'Notif. type',  editable: false, sortable: false, width: 110 },
      { field: 'date',    headerName: 'Send date',      editable: false, sortable: false, width: 100, type: 'date',
        valueFormatter: params => {if(params?.value != null) return momentDate(params?.value).format("DD MMM YY")} },
      { field: 'sender',  headerName: 'Sender name',    editable: false, sortable: false, minWidth: 150, flex:1 },
      { field: 'to',      headerName: 'Recipient',      editable: false, sortable: false, minWidth: 200, flex:2 },
      { field: 'status',  headerName: 'Status value',   editable: false, sortable: false, minWidth: 150, flex:1 },
      { field: 'label',   headerName: 'Label',          editable: false, sortable: false, minWidth: 250, flex:3 },
      { field: 'content',   headerName: 'Message',      editable: false, sortable: false, minWidth: 80, align: 'center', renderCell: renderMessage }
    ];

    return (
        <div className="datagridLot">
            <DataGridPro
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                autoHeight
                density="compact"
                sx={{ flex: 1 }}
                rows={props.rows}
                columns={columnsNotificationLog}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                hideFooter
            />
        </div>
    );
}
