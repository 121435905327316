import React, { useContext } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import EditOffIcon from '@mui/icons-material/EditOff';

import MissionDetailsWorkflowSaveButton from './MissionDetailsWorkflowSaveButton';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";


const MissionDetailsFormSaveButton = (props) => {

  //config
  const { update } = useContext(ContextMissionDetails);

  //generic
  const {loading, setLoading} = useContext(ContextMissionDetails);

  //handle
  const { handleClickBlockCancel } = useContext(ContextMissionDetailsTreatment);
  const handleClickCancel = async () => {
    //cancel
    if (!!props.blockCancel != false) {
      await props.blockCancel();
    } else if (!!props.blockRef != false) {
      handleClickBlockCancel(props.blockRef)
    }
  }
  const handleClickSave = () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    props.blockSave();
  }


  if (update == 1) {
    return(
      <div className="block_button">
        {!!props.notWorkflowSaveButton === false && <MissionDetailsWorkflowSaveButton show={true}/>}
        <LoadingButton className="cancel" loading={loading} onClick={async ()=> await handleClickCancel()} loadingPosition="start" startIcon={<EditOffIcon/>}> Cancel</LoadingButton>
        <LoadingButton loading={loading} onClick={handleClickSave} loadingPosition="start" startIcon={<SaveIcon/>}> Save</LoadingButton>
      </div>
    );
  }
};

export default MissionDetailsFormSaveButton;
