import React, { useEffect } from 'react';
import axios from 'api/axios';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AddElement from 'components/page/object/AddElement';
import DetailField from 'components/page/object/DetailField';
import {isInternalRole, isPortRole, isCustomerRole} from 'components/config/Roles';
import moment from 'moment';

/* SELECT */
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
/* END SELECT */

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const SelectCustom = (props, ref) => {

  const navigate = useNavigate();

  const [dataFilter, setDataFilter] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackBarType, setSnackBarType] = React.useState("");
  const [duplicate, setDuplicate] = React.useState(false);
  var dataVal = props.dataValue;

  const [JLBCustomerMode] = React.useState(isCustomerRole(localStorage.getItem("role")));

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  /* SELECT */
  const handleChange = (event) => {
    var value = event.target.dataset.value;
    var alias = event.target.dataset.alias;
    var data = null;

    if(event.target.dataset.item != undefined){
      data = JSON.parse(event.target.dataset.item);
    }
    
    props.setDataValue(value, alias, props.targetItem, data);
    if (props.setDataAlias) props.setDataAlias(alias);
  };
  /* END SELECT */

  function setNewElement(data, label, alias){
    setDataFilter((oldRows) => [...oldRows, props.creator(data)]);
    props.setDataValue(data.id, label, props.targetItem, data);
  }

  const getList = async (e) => {
    try {
      if(typeof props.apiParam != "undefined"){
        setLoading(false);
        const config = {
          headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            }
        };

        var getValue = "";
        if (props.apiParam.length > 0) {
          getValue = "?"+props.apiParam+"="+props.apiParamValue;
        }

        //GESTION DU CACHE
        if (props.cacheTTL && localStorage.getItem(props.api+getValue+"TTL")) {
          if (moment.unix(localStorage.getItem(props.api+getValue+"TTL")).unix() <  moment().unix()) {
            localStorage.removeItem(props.api+getValue);
            localStorage.removeItem(props.api+getValue+"TTL");
          }
        }

        if (!localStorage.getItem(props.api+getValue)||!props.cached) {
          var response = await axios.get(props.api+getValue, config);
          localStorage.setItem(props.api+getValue, JSON.stringify(response));
          if (props.cacheTTL) {
            localStorage.setItem(props.api+getValue+"TTL", Number(moment().unix())+Number(props.cacheTTL));
          }
        }else {
          var response = JSON.parse(localStorage.getItem(props.api+getValue));
        }
        //END OF CACHE
        
        var rowsFromApi = [];
        var responseData = Array.isArray(response.data) ? response.data : response.data.data;

        for (var i = 0; i < responseData?.length; i++) {
          rowsFromApi[i] = {};
          rowsFromApi[i] = props.creator(responseData[i]);
        }
        var sort = true;
        if (props.nosort) sort = false;
        if (sort == true) rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
        setDataFilter(rowsFromApi);

        dataVal = props.dataValue;
        if(dataFilter.length > 0) {
          var dataId  = dataFilter.findIndex(obj => obj.id === props.dataValue);
          if(dataId < 0) {
            dataVal = 0;
            props.setDataValue(0, props.defaultAlias, props.targetItem, null);
          }
        }
        if (props.defaultValue) props.setDataValue(props.defaultValue);
      }
    } catch (err) {
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        console.debug(err);
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally
    {
      setLoading(true);
    }
  }

  useEffect(() => {
    if (props.duplicate) setDuplicate(true);
    //if (typeof props.api != "undefined" && props.api.length > 0) getList();
    if (!!props.api == false || props.api.length <= 0) {
      var rowsFromApi = [];
      if (!!props.datas !== false) {
        for(var i=0; i < props.datas.length; i++) {
          rowsFromApi[i] = {};
          rowsFromApi[i] = props.creator(props.datas[i]);
        }
      }
      var sort = true;
      if (props.nosort) sort = false;

      if (sort==true) rowsFromApi.sort((a, b) => (a.label > b.label) ? 1 : -1);
      
      setDataFilter(rowsFromApi);
    
      dataVal = props.dataValue;
      if(dataFilter.length > 0) {
        var dataId  = dataFilter.findIndex(obj => parseInt(obj.id) === parseInt(props.dataValue));
        if(dataId < 0) {
          dataVal = 0;
          props.setDataValue(0, props.defaultAlias, props.targetItem, null);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (typeof props.api != "undefined" && props.api.length > 0) getList();
  }, [props.apiParamValue]);

  useEffect(() => {
    if (!!props.api == false || props.api.length <= 0){
      var rowsFromApi = [];
      if (!!props.datas !== false) {
        for(var i=0; i < props.datas.length; i++) {
          rowsFromApi[i] = {};
          rowsFromApi[i] = props.creator(props.datas[i]);
        }
      }
      var sort = true;
      if (props.nosort) sort = false;

      if (sort==true) rowsFromApi.sort((a, b) => (a.label > b.label) ? 1 : -1);
      
      setDataFilter(rowsFromApi);
    }
    
    if(dataFilter.length > 0) {
      if(props.dataValue > 0 && props.dataValue != dataVal) 
        dataVal = props.dataValue;
      if(parseInt(props.dataValue) != 0 && parseInt(dataVal) != 0){
        var dataId  = dataFilter.findIndex(obj => parseInt(obj.id) === parseInt(props.dataValue));
        if(dataId < 0) {
          dataVal = 0;
          props.setDataValue(0, props.defaultAlias, props.targetItem, null);
        }
      }
    }
  }, [props]);

  var paddingRight = "0px";
  if (props.enableAddButton)
  {
      paddingRight = "30px";
  }

  var size='18%';
  if(props.size)
  {
    if (props.size == 'large')
    {
      size = '95%';
    }
    
    if (props.size == 'medium') 
    {
      size = '40%'
    }
  }

  var canRead = 1;
  var canWrite = 1;
  var isRequired = false;

  if (props.controlFieldsStatus) {
    canRead = props.read;
    canWrite = props.write;
    isRequired = props.required;
  } else if (typeof props.required != "undefined") {
    isRequired = props.required;
  }

  var disableNone = (typeof props.disableNone != "undefined") ? props.disableNone : false;
  if (isRequired) disableNone = "true";

  if (canWrite) {
    return (
    <FormControl className="selectCustom" sx={{ m: 1, width: size, paddingRight: paddingRight}} variant={ props.variant ? props.variant : 'outlined' } size="small" error={props.error} disabled={props.disabled} required={isRequired}>
      {props?.title && (<InputLabel id="demo-select-small">{props.title}</InputLabel>)}
      {Boolean(props.enableAddButton) && (
        <AddElement
          {...props}
          api={props.apiAddButton}
          setNewElement={setNewElement}
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
          setSnackBarType={setSnackBarType}
          duplicate={duplicate}
          datas={dataFilter}
          parentApi={props.api}
          parentApiParam={!!props.apiParam != false ? props.apiParam : ''}
          parentApiParamValue={!!props.apiParamValue != false ? props.apiParamValue : ''}
          resetCache={typeof props.resetCache != "undefined" ? props.resetCache : "0"}
        />
      )}
      <Select
        labelId="select-custom-label"
        id="select-custom-id"
        value={!props.isDuplicate ? dataVal : props.dataValue}
        label={props?.title ? props.title.toUpperCase().trim() : ''}
        error={props.error}
        style={{ 
          backgroundColor: props?.backgroundColor ? props.backgroundColor : '', 
          color: props?.colorText ? props?.colorText : '' 
        }}
      >
      {!Boolean(disableNone) && (<MenuItem value={null} data-alias={props.defaultAlias? props.defaultAlias : "None"} onClick={handleChange}><em>None</em></MenuItem>)}
      {dataFilter.map((dataItem) => (
        <MenuItem key={dataItem.id} value={dataItem.id} data-alias={dataItem.alias} data-item={JSON.stringify(dataItem)} onClick={handleChange}>{dataItem.title.toUpperCase().trim()}</MenuItem>
      ))}
      </Select>
      {((props.error || !!props.infos !== false) && (!!props.helperText != false)) && <FormHelperText sx={{ ml: 0}}>{props.helperText}</FormHelperText>}
      <LinearProgress hidden={loading} />
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackBarType} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </FormControl>
    );
  }

  if (canRead){
    var theValue = "-";
    if (props.dataValue > 0 && dataFilter) {
      var itemId = dataFilter.findIndex(obj => obj.id === Number(props.dataValue));
      if(itemId >= 0){
        theValue = (" "+dataFilter[itemId].labelValue).toUpperCase().trim();
      }
    }

    return (<DetailField title={props.title.toUpperCase().trim()} content={theValue} size={!JLBCustomerMode ? '':'large'} canRead={canRead} />);
  }
}
export default SelectCustom
