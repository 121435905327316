import React, { useContext, forwardRef } from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { ContextMissionDetails } from "context/ContextMissionDetails";


const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MissionDetailsSnackBar = () => {

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextMissionDetails);
  const { snackBarType, setSnackBarType } = useContext(ContextMissionDetails);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextMissionDetails);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };


  return (
    <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Alert onClose={handleCloseSnackbar} severity={snackBarType} sx={{ width: '100%' }}>
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );
}
export default MissionDetailsSnackBar
