import { useNavigate } from "react-router-dom";
import React, {useEffect, useRef, forwardRef } from 'react';
import {checkSecurity} from 'components/config/Security';
import SelectCustom from 'components/select/SelectCustom';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import Menu from '../Menu';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextFieldCustom from 'components/select/TextFieldCustom';
import SaveIcon from '@mui/icons-material/Save';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LoadingButtonCustom from 'components/page/object/LoadingButtonCustom';
import LinearProgress from '@mui/material/LinearProgress';
import momentDate from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InfosPopin from 'components/page/object/InfosPopin';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Button from '@mui/material/Button';
import ProtisForm from "../MissionDetails/Protis/MissionDetailsProtisForm";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'
import Rating from '@mui/material/Rating';

import Chip from '@mui/material/Chip';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import 'styles/globalPortalDashboard.css';
import 'styles/CreateMission.css';
import 'bootstrap/dist/css/bootstrap.css';

import {isAdminRole, isInternalRole, isPortRole, isCustomerRole} from 'components/config/Roles';

import axios from 'api/axios';
const CREATEMISSION_URL = 'mission';
const PINED_URL = 'mission/pin';
const GETMISSION= 'mission';
const GETWORKFLOW= 'workflow';
const GETMISSIONWORKFLOW= 'mission/workflow';
const WORKFLOWBUTTONCLICK= 'mission/workflow/button';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function pad(num) {
    var s = "000000" + num;
    return s.substr(s.length-6);
}

const CocoaDepartureMissionsEdit = (props) => {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const [loadingUpdate, setLoadingUpdate] = React.useState(false);
    const [sticky, setSticky] = React.useState(false);
    const [JLBInternalMode] = React.useState(isInternalRole(localStorage.getItem("role")));
    const [JLBCustomerMode] = React.useState(isCustomerRole(localStorage.getItem("role")));
    const [JLBPortMode] = React.useState(isPortRole(localStorage.getItem("role")));
    const [workflowFind, setWorkflowFind] = React.useState(false);
    const [workflowLoaded, setWorkflowLoaded] = React.useState(false);
    const [manualSettingsLoaded, setManualSettingsLoaded] = React.useState(false);
    const [fieldsSettingsLoaded, setFieldsSettingsLoaded] = React.useState(false);

    const contentRef = useRef(null);

    const companyLocalOfficeSelectRef = useRef("localOfficeSelectRef");
    const companyPrincipalSelectRef = useRef("principalSelectRef");
    const companyTraderSelectRef = useRef("traderSelectRef");
    const companyInsuredSelectRef = useRef("insuredSelectRef");
    const companyShipperSelectRef = useRef("shipperSelectRef");

    const [openSave, setOpenSave] = React.useState(false);
    const handleOpenSave = () => 
    {
      setOpenSave(true);
    }
    const handleCloseSaveDial = () => 
    {
      setOpenSave(false);
    }
    const handleCloseSave = () => 
    {
      setOpenSave(false);
      handleClickSave(false);
    }
    const handleCloseSaveQuit = () => 
    {
      setOpenSave(false);
      handleClickSave(true);
    }
    const handleCloseSaveCancel = () => 
    {
      setOpenSave(false);
      handleClickCancel();
    }

    // CHECK SECURITY
    useEffect(() => {
      checkSecurity(navigate, "CreateMission");

      if (props.update == 1) {
        if (missionIdParam < 1) {
          navigate('/createMission', { replace: true });
        }
        if (queryParams.get('actionSave')) {
          setSnackBarMessage("Mission saved.");
          setSnackBarType("success");
          setOpenSnackBar(true);
        }
      }
      else {
        //init status a 2
        setSurveyHandlerDefault(localStorage.getItem("userID"));
        setSurveyHandler(localStorage.getItem("userID"));
        setLoadingUpdate(true);
      }
      //load conf basic field
      if(!workflowLoaded && !JLBPortMode){
        var controlFieldsInt = Array();
        controlFieldsInt.push({id:'id_port', required:1});
        controlFieldsInt.push({id:'id_principal', required:1});
        controlFieldsInt.push({id:'id_mission_type', required:1});
        controlFieldsInt.push({id:'id_survey_handler', required:1});
        setControlFields(controlFieldsInt);
      }
      // reload variables if id set
      if (missionIdParam > 0) {
        if (JLBPortMode) {
          initPortflow();
        }
        //Get Workflow configuration
        getMissionWorkflow(missionIdParam);
        //Get details mission
        getMissionDetail(missionIdParam);
      }
    }, []);
    // END SECURITY

    useEffect(()=> { 
      setFieldsSettingsLoaded(workflowLoaded || manualSettingsLoaded);
    },[workflowLoaded, manualSettingsLoaded]);

    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [snackBarType, setSnackBarType] = React.useState("");

    const [controlFields, setControlFields] = React.useState(Array());
    const [controlButtons, setControlButtons] = React.useState(Array());
    const [controlDocuments, setControlDocuments] = React.useState(Array());

    const [checkWorkflowData, setCheckWorkflowData] = React.useState(true);
    const [controlFieldsError, setControlFieldsError] = React.useState([]);

    const [openInfosPopin, setOpenInfosPopin] = React.useState(false);
    const [infosPopinType, setInfosPopinType] = React.useState("");
    const [infosPopinMsg, setInfosPopinMsg] = React.useState("");
    const [infosPopinTitle, setInfosPopinTitle] = React.useState("");

    function setSnackErrorMsg(data){
      var errorMsg = "Technical error ! Please try again or contact our support.";
      /*if(typeof data != "undefined"){
        var responseMsg = "";
        var errorData = Array.from(new Map(Object.entries(data)));
        if(Array.isArray(errorData) && errorData.length >= 1){
          errorData.map((err, i) => {
            if(typeof err != String){
              err.map((msg, k) => {
                if(Array.isArray(msg)){
                  msg.map((txt, idx) => {
                    responseMsg += "\n" + txt;
                  });
                }
              });
            }else{
              responseMsg += "\n" + err;
            }
          });
        }else{
          responseMsg += "\n" + errorData;
        }
        errorMsg = errorMsg + responseMsg;
      }*/

      return errorMsg;
    }

    //MAPPING FIELD'S KEY => NAME
    const controlFieldsMapping = []; 
    //general
    controlFieldsMapping["mission_type"] = "id_mission_type";
    controlFieldsMapping["port"] = "id_port";
    controlFieldsMapping["principal"] = "id_principal";
    controlFieldsMapping["principals_ref"] = "principal_ref";
    controlFieldsMapping["survey_handler"] = "id_survey_handler";
    controlFieldsMapping["local_office"] = "id_local_office";
    controlFieldsMapping["local_surveyor"] = "id_local_surveyor";
    controlFieldsMapping["vessel"] = "id_vessel";
    controlFieldsMapping["insured"] = "id_insured";
    controlFieldsMapping["trader"] = "id_trader";
    controlFieldsMapping["eta"] = "estimated_time_arrival";
    controlFieldsMapping["etb"] = "estimated_time_berthing";
    controlFieldsMapping["date_completion_operations"] = "operations_completion_date";
    //receiver
    controlFieldsMapping["receiver_item"] = "id_receiver";
    controlFieldsMapping["receiver_item_tonnage"] = "r.tonnage";
    controlFieldsMapping["receiver_item_cargo"] = "id_cargo";
    controlFieldsMapping["receiver_item_mission_ending"] = "id_mission_ending";
    //receiver lot
    controlFieldsMapping["lot_item_packaging"] = "id_packaging";
    controlFieldsMapping["lot_item_origin"] = "id_cargo_origin";
    controlFieldsMapping["lot_item_type_of_loading"] = "id_loading_type";
    controlFieldsMapping["lot_item_brand"] = "id_cargo_brand";
    controlFieldsMapping["lot_item_quality"] = "id_cargo_quality";
    controlFieldsMapping["lot_item_quantity"] = "packaging_quantity";
    controlFieldsMapping["lot_item_net_tonnage"] = "net_tonnage";
    controlFieldsMapping["lot_item_gross_tonnage"] = "gross_tonnage";
    controlFieldsMapping["lot_item_empty_spare_packaging_percentage"] = "empty_spare_packaging_percentage";


    function canRead(key)
    {
      if ((!workflowFind && !JLBPortMode) || isAdminRole(localStorage.getItem("role"))) return 1;
      var itemId = controlFields.findIndex(obj => obj.id.toUpperCase() === key.toUpperCase());
      if (itemId >= 0) return controlFields[itemId].read;
      return 0;
    }

    function canWrite(key)
    {
      if ((!workflowFind && !JLBPortMode) || isAdminRole(localStorage.getItem("role"))) return 1;
      var itemId = controlFields.findIndex(obj => obj.id.toUpperCase() === key.toUpperCase());
      if (itemId >= 0) return controlFields[itemId].write;
      return 0;
    }

    function canUpload(id)
    {
      if (!workflowFind) return 1;
      var itemId = controlDocuments.findIndex(obj => obj.id_file_type === id);
      if (itemId >= 0) return true;
      return false;
    }

    function isRequired(key, elmt)
    {
      switch(elmt){
        case 'file':
          var itemId = controlDocuments.findIndex(obj => obj.id_file_type === key);
          if (itemId >= 0) return Boolean(controlDocuments[itemId].required);
        break;

        default:
          var itemId = controlFields.findIndex(obj => obj.id.toUpperCase() === key.toUpperCase());
          if (itemId >= 0) return Boolean(controlFields[itemId].required);
        break;
      }
      return false;
    }

    function getControlFieldsErrorState(fieldKey){
      var itemId = controlFieldsError.findIndex(obj => obj.id.toUpperCase() === fieldKey.toUpperCase());
      if(itemId >= 0) return controlFieldsError[itemId].error;
      return false;
    }
    function getControlFieldsErrorMsg(fieldKey){
      var itemId = controlFieldsError.findIndex(obj => obj.id.toUpperCase() === fieldKey.toUpperCase());
      if(itemId >= 0) return controlFieldsError[itemId].helpertext;
      return "";
    }

    function requiredFieldsErrorLog(itemKey, itemVal, errorKey){
      //check field is a controlled field
      var fieldControl = controlFields.findIndex(obj => obj.id.toUpperCase() === itemKey.toUpperCase());
      if(fieldControl >= 0){
        //delete error field log if exist
        var errorId  = controlFieldsError.findIndex(obj => obj.id.toUpperCase() === errorKey.toUpperCase());
        if(errorId >= 0) controlFieldsError.splice(errorId, 1);
        //check valid data
        if(isRequired(itemKey) && (!!itemVal == false || itemVal == "null" || itemVal == "0")){
          //init error msg
          controlFieldsError.push({id: errorKey, error: true, helpertext: 'Required value'});
          //init false not valid workflow
          setCheckWorkflowData(false);
        }
      }
    }

    function controlBasicFieldsData(data){
      if(typeof data != "undefined"){
        for (const item of data.entries()) {
          var itemKey = item[0], itemVal = item[1], errorKey = item[0];
          //check&log required field
          requiredFieldsErrorLog(itemKey, itemVal, errorKey);
        }
        setControlFieldsError(controlFieldsError);
      }else{
        //init false not valid workflow
        setCheckWorkflowData(false);
      }
    }

    function controlFieldsData(data){
      if(workflowLoaded){
        if(typeof data != "undefined"){
          for (const item of data.entries()) {
            var itemKey = item[0], itemVal = item[1], errorKey = item[0];
            //check&log required field
            requiredFieldsErrorLog(itemKey, itemVal, errorKey);
          }
          setControlFieldsError(controlFieldsError);
        }else{
          //init false not valid workflow
          setCheckWorkflowData(false);
        }
      }
    }

    function controlMultipleFieldsData(data){
      if(workflowLoaded){
        if(typeof data != "undefined"){
          for (const item of data) {
            var itemKey = item.key, itemVal = item.val, errorKey = item.key+item.id;
            //check&log required field
            requiredFieldsErrorLog(itemKey, itemVal, errorKey);
          }
          setControlFieldsError(controlFieldsError);
        }else{
          //init false not valid workflow
          setCheckWorkflowData(false);
        }
      }
    }

    function initPortflow() {
      var controlFieldsInt = Array();
      //GENERAL
      controlFieldsInt.push({id:'id_mission_type', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'id_port', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'id_survey_handler', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'id_local_office', required: 0, read: 0, write: 0});
      controlFieldsInt.push({id:'id_local_surveyor', required: 0, read: 0, write: 0});
      controlFieldsInt.push({id:'id_principal', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'principal_ref', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'id_trader', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'id_insured', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'insured_ref', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'id_vessel', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'vessel_tonnage', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'id_warehouse', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'id_control_type', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'estimated_time_arrival', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'estimated_time_berthing', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'date_of_berthing', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'completion_of_discharge', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'date_vessel_departure', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'next_port_of_discharge', required: 0, read: 1, write: 1});
      //RECEIVER
      controlFieldsInt.push({id:'receiver_block', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'id_receiver', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'r.tonnage', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'id_cargo', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'id_mission_ending', required: 0, read: 1, write: 1});
      //RECEIVER LOT
      controlFieldsInt.push({id:'id_packaging', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'id_cargo_origin', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'id_loading_type', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'id_cargo_brand', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'id_cargo_quality', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'id_cargo_variety', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'packaging_quantity', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'net_tonnage', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'gross_tonnage', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'empty_spare_packaging_percentage', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'empty_spare_packaging_quantity', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'number_of_container', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'container_type', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'bills_of_lading', required: 0, read: 1, write: 1});
      //BL
      controlFieldsInt.push({id:'bl_block', required: 0, read: 1, write: 1});
      //DATES MISSIONS
      controlFieldsInt.push({id:'operations_begining_date', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'operations_completion_date', required: 0, read: 1, write: 0});
      //REPORT AND INVOICE
      controlFieldsInt.push({id:'invoiced_by', required: 0, read: 1, write: 0});
      controlFieldsInt.push({id:'subInvoice_block', required: 0, read: 1, write: 1});
      controlFieldsInt.push({id:'invoice_block', required: 0, read: 0, write: 0});
      controlFieldsInt.push({id:'invoice_note', required: 0, read: 0, write: 0});
      //FILE
      controlFieldsInt.push({id:'file_block', required: 0, read: 1, write: 1});

      setControlFields(controlFieldsInt);
      setManualSettingsLoaded(true);
    }

    function initWorkflow(data) {
      var item = {};
      var itemIndex = 0;

      //INITIALISATION FIELDS
      var controlFieldsInt = Array();
      for (let indexField = 0; indexField < data.fields.length; indexField++)
      {
        var key = data.fields[indexField].key;
        var keyName  = (typeof controlFieldsMapping[key] != "undefined" ? controlFieldsMapping[key] : key);
        var required = data.fields[indexField].required;
        var read = 0; var write = 0;
        for (var permission = 0; permission < data.fields[indexField].permissions.length; permission++)
        {
          if (data.fields[indexField].permissions[permission].user_group.role == localStorage.getItem("role"))
          {
            read  = data.fields[indexField].permissions[permission].read;
            write = data.fields[indexField].permissions[permission].write;
            break;
          }
        }
        item = {id:keyName, required:required, read:read, write:write};
        controlFieldsInt.push(item);
      }

      //INITIALISATION BUTTONS
      var controlButtonsInt = Array();
      for (let indexButton = 0; indexButton < data.buttons.length; indexButton++)
      {
        var key = data.buttons[indexButton].key;
        for (var permission = 0; permission < data.buttons[indexButton].permissions.length; permission++)
        {
          if (data.buttons[indexButton].permissions[permission].user_group.role == localStorage.getItem("role")
          || isAdminRole(localStorage.getItem("role")))
          {
            itemIndex = controlButtonsInt.findIndex(obj => obj.id === key);
            item = {id:data.buttons[indexButton].id, key:key, label:data.buttons[indexButton].label};

            if (itemIndex < 0) controlButtonsInt.push(item);
            else controlButtonsInt[itemIndex] = item;

            break;
          }
        }
      }

      //INITIALISATION DOCUMENTS
      var controlDocumentsInt = Array();
      for (let indexDoc = 0; indexDoc < data.documents.length; indexDoc++)
      {
        var key = data.documents[indexDoc].key;
        for (var permission = 0; permission < data.documents[indexDoc].permissions.length; permission++)
        {
          if (data.documents[indexDoc].permissions[permission].user_group.role == localStorage.getItem("role")
          || isAdminRole(localStorage.getItem("role")))
          {
            itemIndex = controlDocumentsInt.findIndex(obj => obj.id === key);
            item = {
              key:key, 
              id:data.documents[indexDoc].id, 
              label:data.documents[indexDoc].label, 
              required:data.documents[indexDoc].required,
              id_file_type:data.documents[indexDoc].id_file_type
            };

            if (itemIndex < 0) controlDocumentsInt.push(item);
            else controlDocumentsInt[itemIndex] = item;

            break;
          }
        }
      }
      
      setControlFields(controlFieldsInt);
      setControlButtons(controlButtonsInt);
      setControlDocuments(controlDocumentsInt);
    }

    const getWorkflow = async (missionType) => {
      try
      {
        var config = {
          url: GETWORKFLOW+"?id_mission_type="+missionType,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          }
        };

        const reponseGet = await axios(config);
        reponseGet.data.data.steps.sort((a, b) => (a.status.value > b.status.value) ? 1 : -1);
        
        var currentStatus = 2;  //status:2 of start of creation mission
        if(missionIdParam > 0) currentStatus = parseInt(filterStatusArrayOld[0]);
        var index = currentStatus-1;

        //INITIALISATION FIELDS - BUTTONS - DOCUMENTS
        initWorkflow(reponseGet.data.data.steps[index]);
        setWorkflowFind(true);
        setWorkflowLoaded(true);
        navigate(window.location, { replace: false });
      }
      catch (err) {
        setWorkflowFind(false);
        setWorkflowLoaded(false);
        if (err.response?.status === 401) {
            navigate("/logout", { replace: true });
        }
      }
    }

    const getMissionWorkflow = async (missionIdParam) => {
      try
      {
        var data = new FormData();
        var jsonRequestData = JSON.stringify(Object.fromEntries(data));

        var config = {
          url: GETMISSIONWORKFLOW+"?id_mission="+missionIdParam,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestData,
        };

        const reponseGet = await axios(config);
        reponseGet.data.data.workflow.steps.sort((a, b) => (a.status.value > b.status.value) ? 1 : -1);

        var currentStatus = reponseGet.data.data.mission_status;
        var index = currentStatus-1;

        //INITIALISATION FIELDS - BUTTONS - DOCUMENTS
        initWorkflow(reponseGet.data.data.workflow.steps[index]);
        setWorkflowFind(true);
        setWorkflowLoaded(true);
      }
      catch (err) {
        setWorkflowFind(false);
        setWorkflowLoaded(false);
        if (err.response?.status === 401) {
            navigate("/logout", { replace: true });
        }
      }
    }

    const getMissionDetail = async (missionIdParam) => {
      try
      {
        setLoadingUpdate(false);
        var data = new FormData();
        var jsonRequestData = JSON.stringify(Object.fromEntries(data));

        var config = {
          url: GETMISSION+"?id_mission="+missionIdParam,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestData,
        };
        
        const reponseGet = await axios(config);
        //init initValues
        // INIT txt mission ref id:
        setIdMisionTxt(pad(reponseGet.data.id));

        //INIT dates de creation et de modification
        if (reponseGet.data.created_at) setCreatedMissionDate(momentDate(reponseGet.data.created_at).format("DD MMM YY"));
        else setCreatedMissionDate("-");
        if (reponseGet.data.modified_at) setModifiedMissionDate(momentDate(reponseGet.data.modified_at).format("DD MMM YY"));
        else setModifiedMissionDate("-");

        //INIT date reminder
        if (reponseGet.data.reminder_date)
        {
          setDateReminder(momentDate(reponseGet.data.reminder_date));
          setDateReminderTXT(momentDate(reponseGet.data.reminder_date).format("DD MMM YY"));
        }
        else
        {
          if (reponseGet.data.modified_at)
          {
            setDateReminder(momentDate(reponseGet.data.modified_at).add(15, 'days'));
            setDateReminderTXT(momentDate(reponseGet.data.modified_at).add(15, 'days').format("DD MMM YY"));
          }
          else
          {
            setDateReminder(momentDate(reponseGet.data.created_at).add(15, 'days'));
            setDateReminderTXT(momentDate(reponseGet.data.created_at).add(15, 'days').format("DD MMM YY"));
          }
        }

        // INIT PINED
        if (reponseGet.data.pinned)
        {
          setOldPinned(1);
          setPinned(1);
        }

        //INIT MISSION TYPE
        setMissionType(reponseGet.data.mission_type.id);
        setMissionAlias(reponseGet.data.mission_type.nomenclature);

        //INIT PORT
        setDatasPort(reponseGet.data.port.id, reponseGet.data.port.label, reponseGet.data.port.nomenclature, reponseGet.data.port);

        //INIT SURVEY HANDLER
        setSurveyHandler(reponseGet.data.survey_handler.id);

        //INIT Local Office
        //TODO label
        if (reponseGet.data.local_office) setDatasOffice(reponseGet.data.local_office.id, reponseGet.data.local_office.name, "")

        //INIT Principal
        if (reponseGet.data.principal) setDatasPrincipal(reponseGet.data.principal.id, reponseGet.data.principal.name, "");

        //INIT Trader
        if (reponseGet.data.trader) setDatasTrader(reponseGet.data.trader.id, reponseGet.data.trader.name, "");

        //INIT Insured
        if (reponseGet.data.insured) setDatasInsured(reponseGet.data.insured.id, reponseGet.data.insured.name, "")
        
        //INIT Shipper
        if (reponseGet.data.shipper) setDatasShipper(reponseGet.data.shipper.id, reponseGet.data.shipper.name, "")

        //INIT Tonnage
        if (reponseGet.data.vessel_tonnage) setTonnage(reponseGet.data.vessel_tonnage);
        
        //INIT Contract Number
        if (reponseGet.data.contract_number) setContractNumber(reponseGet.data.contract_number);
        else setContractNumber('');
        
        //INIT internal_note
        if (reponseGet.data.internal_note) setInternalNote(reponseGet.data.internal_note);
        
        //INIT photos_comment
        if (reponseGet.data.photos_comment) setPhotosComment(reponseGet.data.photos_comment);
        
        //INIT latest_sync_at
        if (reponseGet.data.latest_sync_at) setLatestSyncAt(reponseGet.data.latest_sync_at);

        setLoadingUpdate(true);
      }
      catch (err)
      {
        console.log(err);
        if (err.response?.status === 401) {
            navigate("/logout", { replace: true });
        }
        else
        {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
          setLoadingUpdate(true);
        }
      }
    }

    var missionIdParam = parseInt(queryParams.get('id'));
    if (isNaN(missionIdParam)) missionIdParam = 0;

    const [createdMissionDate, setCreatedMissionDate] = React.useState("");
    const [modifiedMissionDate, setModifiedMissionDate] = React.useState("");

    const [idMission, setIdMision] = React.useState(missionIdParam);
    const [missionIdTxt, setIdMisionTxt] = React.useState(pad(0));
    const [oldPinned, setOldPinned] = React.useState(0);
    const [pinned, setPinned] = React.useState(0);

    const [filterStatusArrayOld, setFilterStatusArrayOld] = React.useState([]);

    //if id button, launch validation workflow step
    useEffect(() => {
      if (workflowLoaded && queryParams.get('idButton')){
        var idButton = queryParams.get('idButton');
        window.history.replaceState({}, document.title, "/" + "updateMission?id="+idMission);
        workflowStep(idButton);
      }
    }, [workflowLoaded, queryParams.get('idButton')]);

    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackBar(false);
    };

    const [missionType, setMissionType] = React.useState("7");
    const [missionAlias, setMissionAlias] = React.useState("COD");
    function createMissionTypeHandler(data) {
      return {id:data.id, title:data.label, alias:data.nomenclature, labelValue:data.label};
    }
    function handleChangeMissionType(MissionTypeId) {
      setMissionType(MissionTypeId);
      if(MissionTypeId > 0) getWorkflow(MissionTypeId);
    }

    const [port, setPort] = React.useState(null);
    const [portId, setPortId] = React.useState(0);
    const [portAlias, setPortAlias] = React.useState("YYY");
    const [portCountryId, setPortCountryId] = React.useState(0);
    function createPortHandler(data) {
      return {label:data.label.toUpperCase().trim(), id:data.id, alias:data.nomenclature, labelValue:data.label.toUpperCase().trim()};
    }
    function setDatasPort(id, label, alias, data)
    {
      setPortId(id);
      setPort(label);
      setPortAlias(alias);
      if(typeof data != "undefined") setPortCountryId(data.id_country);
    }

    const [principal, setPrincipal] = React.useState(null);
    const [principalId, setPrincipalId] = React.useState(0);
    function createPrincipalHandler(data) {
      return {id:data.id, label:data.name.toUpperCase().trim(), alias:data.name, labelValue:data.name.toUpperCase().trim()};
    }
    function setDatasPrincipal(id, label, alias)
    {
      var principal = (label != "" && label != null) ? label.toUpperCase().trim() : label;
      setPrincipalId(id)
      setPrincipal(principal);
    }

    const [surveyHandler, setSurveyHandler] = React.useState(0);
    const [surveyHandlerDefault, setSurveyHandlerDefault] = React.useState(0);
    function createSurveyHandlerHandler(data) {
      return {id:data.id, title:data.firstname + " " + data.lastname, alias:"", labelValue:data.firstname + " " + data.lastname};
    }

    const [localOffice, setLocalOffice] = React.useState(null);
    const [localOfficeId, setLocalOfficeId] = React.useState(0);
    function createLocalOfficeHandler(data) {
      return {id:data.id, label:data.name.toUpperCase().trim(), alias:data.name, labelValue:data.name.toUpperCase().trim()};
    }
    function setDatasOffice(id, label, alias)
    {
      var localOffice = (label != "" && label != null) ? label.toUpperCase().trim() : label;
      setLocalOfficeId(id)
      setLocalOffice(localOffice);
    }

    const [trader, setTrader] = React.useState(null);
    const [traderId, setTraderId] = React.useState(0);
    function createTraderHandler(data) {
      return {id:data.id, label:data.name.toUpperCase().trim(), alias:data.name, labelValue:data.name.toUpperCase().trim()};
    }
    function setDatasTrader(id, label, alias)
    {
      var trader = (label != "" && label != null) ? label.toUpperCase().trim() : label;
      setTraderId(id)
      setTrader(trader);
    }

    const [insured, setInsured] = React.useState(null);
    const [insuredId, setInsuredId] = React.useState(0);
    function createInsuredHandler(data) {
      return {id:data.id, label:data.name.toUpperCase().trim(), alias:data.name, labelValue:data.name.toUpperCase().trim()};
    }
    function setDatasInsured(id, label, alias)
    {
      var insured = (label != "" && label != null) ? label.toUpperCase().trim() : label;
      setInsuredId(id)
      setInsured(insured);
    }
    
    const [shipper, setShipper] = React.useState(null);
    const [shipperId, setShipperId] = React.useState(0);
    function createShipperHandler(data) {
      return {id:data.id, label:data.name.toUpperCase().trim(), alias:data.name, labelValue:data.name.toUpperCase().trim()};
    }
    function setDatasShipper(id, label, alias)
    {
      var shipper = (label != "" && label != null) ? label.toUpperCase().trim() : label;
      setShipperId(id)
      setShipper(shipper);
    }

    const [tonnage, setTonnage] = React.useState(0);
    const changeTonnageHandler = (event) => {
      setTonnage(event.target.value);
    };
    
    const [contractNumber, setContractNumber] = React.useState("");
    const changeContractNumberHandler = (event) => {
      setContractNumber(event.target.value);
    };
    
    const [internalNote, setInternalNote] = React.useState("");
    const changeInternalNoteHandler = (event) => {
      setInternalNote(event.target.value);
    };

    // boutons save/cancel
    const [loading, setLoading] = React.useState(false);
    function handleClickCancel() {
      if (props.update == 1)
      {
        window.location.replace('/dashboard-cocoaDeparture');
      }
      else {
        window.location.reload();
      }
    }
    
    const [photosComment, setPhotosComment] = React.useState("");
    const [latestSyncAt, setLatestSyncAt] = React.useState("");

    const handleClickWorkflow = async (id_button) => {
      handleClickSave(false, id_button);
    }

    const workflowStep = async (id_button) => {
      //CLICK WORKFLOW BUTTON
      if (workflowLoaded && checkWorkflowData && id_button > 0){
        try {
          var data = new FormData();
          data.append('id_mission', idMission);
          data.append('id_button', id_button);
  
          var config = {
            url: WORKFLOWBUTTONCLICK,
            method: 'post',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          };
          const response = await axios(config);
          window.location.reload();
        }
        catch (err) {
          console.log(err);
          if (err.response?.status === 401) {
              navigate("/logout", { replace: true });
          }
          else {
            var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
            setSnackBarMessage(errorMsg);
            setSnackBarType("error");
            setOpenSnackBar(true);
            setLoadingUpdate(true);
          }
        }
        finally {
          setLoading(false);
        }
      }else if(!checkWorkflowData) {
        contentRef.current.scrollIntoView({behavior: 'smooth'});
      }
    };

    const saveMission = async (quit, id_button) => {
        setLoading(true);
        try {
          //init default true valid workflow
          setCheckWorkflowData(true);
          setLoadingUpdate(false);
          var data = new FormData();
          if (props.update == 1) data.append('id_mission', parseInt(idMission));

          //OBLIGATOIRE

          if(canWrite('id_mission_type')) {
            data.append('id_mission_type', parseInt(missionType));
          }
          
          if(canWrite('id_port')) {
            data.append('id_port', parseInt(portId));
          }
          
          if(canWrite('id_survey_handler')) {
            data.append('id_survey_handler', parseInt(surveyHandler));
          }
          
          if(canWrite('id_local_office')){
            if (parseInt(localOfficeId) == 0) data.append('id_local_office', null);
            else data.append('id_local_office', parseInt(localOfficeId));
          }
          
          if(canWrite('id_trader')){
            if (parseInt(traderId) == 0) data.append('id_trader', null);
            else data.append('id_trader', parseInt(traderId));
          }
          
          if(canWrite('id_principal')){
            if (parseInt(principalId) == 0) data.append('id_principal', null);
            else data.append('id_principal', parseInt(principalId));
          }
          
          if(canWrite('id_insured')){
            if (parseInt(insuredId) == 0) data.append('id_insured', null);
            else data.append('id_insured', parseInt(insuredId));
          }
          
          if(canWrite('id_shipper')){
            if (parseInt(shipperId) == 0) data.append('id_shipper', null);
            else data.append('id_shipper', parseInt(shipperId));
          }
          
          if(canWrite('vessel_tonnage')) {
            data.append('vessel_tonnage', Number(tonnage));
          }
          
          if(canWrite('contract_number')) {
            data.append('contract_number', String(contractNumber));
          }
          
          if(canWrite('internal_note')) {
            data.append('internal_note', String(internalNote));
          }

          //INIT date reminder
          if (momentDate().diff(dateReminder, 'days') > 0){
            data.append('reminder_date', null);
          }
          else {
            if (dateReminderChanged){
              data.append('reminder_date', momentDate(dateReminder).format("YYYY-MM-DD"));
            }
          }

          var dataMission = data;

          //API DATA SENT
          var jsonRequestData = JSON.stringify(Object.fromEntries(data));
          var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, 'null');    //PATCH ERREUR NULL

          var config = {
            method: 'post',
            url: CREATEMISSION_URL,
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              'Content-Type': 'application/json'
            },
            data : jsonRequestDataFinal
          };

          if (props.update == 1){
            config.method='put';
          }

          const response = await axios(config);

          //ID registration
          setIdMision(response.data.data.id);
          var idMissionSelect = response.data.data.id;

          //Pined missions
          if (pinned != oldPinned){
            var data = new FormData();
            data.append('id_mission', idMissionSelect);
            if (!pinned) data.append('pinned', '0');
            else data.append('pinned', parseInt(pinned));

            var config = {
              url: PINED_URL,
              method: 'post',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'multipart/form-data',
              },
              data: data,
            };
            const response2 = await axios(config);
          }

          //CHECK WORKFLOW DATA
          controlFieldsData(dataMission);

          //FINALISATION
          var saveCreate=(props.update == 1) ? '' : '&actionSave=1';
          var urlAfterSave=(quit == true) ? 'dashboard-cocoaDeparture' :'updateMissionCocoaDeparture';
          var actionButton=(workflowLoaded && !!id_button != false) ? '&idButton='+id_button : '';
          navigate('/'+urlAfterSave+'?id='+idMissionSelect+saveCreate+actionButton, { replace: quit });

          setSnackBarMessage("Mission saved.");
          setSnackBarType("success");
          setOpenSnackBar(true);
          setLoadingUpdate(true);
        } catch (err) {
          console.log(err);
          if (err.response?.status === 401) {
              navigate("/logout", { replace: true });
          }
          else {
            var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
            setSnackBarMessage(errorMsg);
            setSnackBarType("error");
            setOpenSnackBar(true);
            setLoadingUpdate(true);
            //init false not valid workflow
            setCheckWorkflowData(false);
          }
        }
        finally {
          setLoading(false);
        }
    }

    function handleClickSave(quit, button) {
      setLoading(true);
      //Remove focus from any focused element
      if (document.activeElement) {
        document.activeElement.blur();
      }
      //saving
      if ((missionType == "0") || (portId == "0") || (surveyHandler == "0") || (principalId == "0")) {
        var basicData = new FormData();
        //REQUIRED
        basicData.append('id_mission_type', parseInt(missionType));
        basicData.append('id_port', parseInt(portId));
        basicData.append('id_survey_handler', parseInt(surveyHandler));
        basicData.append('id_principal', parseInt(principalId));
        //CHECK BASIC FIELD
        controlBasicFieldsData(basicData);
        contentRef.current.scrollIntoView({behavior: 'smooth'});
        setLoading(false);
      }else {
        saveMission(quit, button);
      }
    }

    const onScrollContent = e => {
      if (Number(e.currentTarget.scrollTop) > 53) setSticky(true);
      else setSticky(false);
    };

    const [dateReminderChanged, setDateReminderChanged] = React.useState(false);
    const [dateReminder, setDateReminder] = React.useState(momentDate().add(15, 'days'));
    const [dateReminderMax, setDateReminderMax] = React.useState(momentDate().add(30, 'days'));
    const [dateReminderTXT, setDateReminderTXT] = React.useState("-");
    const [openDatePickerReminder, setOpenDatePickerReminder] = React.useState(false);
    const setDateReminderFormat = (value) => {
      setDateReminderChanged(true);
      setDateReminder(value);
      setDateReminderTXT(value.format("DD MMM YY"));
    };

    // REFRESH SELECT COMPANY
    function companySelectRefresh(){
      companyLocalOfficeSelectRef.current.getList();
      companyTraderSelectRef.current.getList();
      companyPrincipalSelectRef.current.getList();
      companyInsuredSelectRef.current.getList();
      companyShipperSelectRef.current.getList();
    }

    const handleOpenFolderClick = () => {
      openSharepointFolderBlank();
    }

  const openSharepointFolderBlank = async() => {
    try{
      var config = {
        url: "mission/files/folderpath?id_mission=" + missionIdParam,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios(config);
      window.open(response.data.data.mission_folder_path, '_blank', 'noreferrer');
    }
    catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

    return (
      <div className="FULL-CONTENT DASHBOARD">
        <div className="content" onScroll={onScrollContent}>
        <div ref={contentRef}>
          <div className="contentHeader">
            <div className="mission-titre">{missionAlias}-{portAlias}-{missionIdTxt}</div>
            {(!JLBPortMode) &&
            <div className="reminderDate">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    className="picker"
                    open={openDatePickerReminder}
                    onOpen={() => setOpenDatePickerReminder(true)}
                    onClose={() => setOpenDatePickerReminder(false)}
                    label="Date begining operations"
                    inputFormat="DD/MM/YYYY"
                    value={dateReminder}
                    onChange={setDateReminderFormat}
                    maxDate={dateReminderMax}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <Chip
                  className="reminderDateSelector"
                  label={dateReminderTXT}
                  size="small"
                  onClick={() => setOpenDatePickerReminder(true)}
                  onDelete={() => setOpenDatePickerReminder(true)}
                  deleteIcon={<NotificationsActiveIcon />}
                  variant="outlined"
                />
            </div>
            }

            {(!JLBPortMode) &&
            <div className="pinned">
              <Rating
                name="mission-pinned"
                size="large"
                max={1}
                value={pinned}
                onChange={(event, newValue) => {
                  setPinned(newValue);
                }}
              />
            </div>
            }

            {Boolean((props.update == 1)) && loadingUpdate && (
              <div className='datesHeader datesHeaderTitle'>
                <div className='titleDate'>Date created :</div>
                <div className='titleDate'>Date modified :</div>
              </div>
            )}
            {Boolean((props.update == 1)) && loadingUpdate && (
              <div className='datesHeader datesHeaderContent'>
                <div className='contentDate'>{createdMissionDate}</div>
                <div className='contentDate'>{modifiedMissionDate}</div>
              </div>
            )}

            <LinearProgress hidden={loadingUpdate}  id='loadingGeneral' />
          </div>

          <div className="contentDetail">
            <div className={sticky ? "floatingHeaderMission visible":"floatingHeaderMission"}>
              <div className="contentHeader">
                <div className="mission-titre">{missionAlias}-{portAlias}-{missionIdTxt}</div>
                <div className="filterStatusSticky">
                </div>
                {(!JLBPortMode) &&
                <div className="reminderDate">
                    <Chip
                      className="reminderDateSelector"
                      label={dateReminderTXT}
                      size="small"
                      onClick={() => setOpenDatePickerReminder(true)}
                      onDelete={() => setOpenDatePickerReminder(true)}
                      deleteIcon={<NotificationsActiveIcon />}
                      variant="outlined"
                    />
                </div>
                }

                {(!JLBPortMode) &&
                <div className="pinned">
                  <Rating
                    name="mission-pinned"
                    size="large"
                    max={1}
                    value={pinned}
                    onChange={(event, newValue) => {
                      setPinned(newValue);
                    }}
                  />
                </div>
                }
                <LinearProgress hidden={loadingUpdate}  id='loadingGeneral' />
              </div>
            </div>

            <div className="floatingSaveButton">
              <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1 }}>
                <SpeedDial
                  ariaLabel="Save menu"
                  sx={{ position: 'absolute', bottom: 16, right: 16 }}
                  icon={loading ? <CircularProgress /> : <SaveRoundedIcon />}
                  open={openSave}
                  onClose={handleCloseSaveDial}
                  onOpen={handleOpenSave}
                  onClick={handleCloseSave}
                  FabProps={{disabled:loading}}
                >
                  <SpeedDialAction
                    key={'Save'}
                    icon={<SaveAsIcon />}
                    tooltipTitle={'Save'}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCloseSave();
                    }}
                  />

                  <SpeedDialAction
                    key={'Save & Quit'}
                    icon={<SaveIcon />}
                    tooltipTitle={'Save & Quit'}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCloseSaveQuit();
                    }}
                  />

                  <SpeedDialAction
                    key={'Quit'}
                    icon={<ExitToAppIcon />}
                    tooltipTitle={'Quit'}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCloseSaveCancel();
                    }}
                  />
                </SpeedDial>
              </Box>
            </div>
            
            <div className="lineContent">
              <SelectCustom disabled
                title="Type"
                api="mission_type/list"
                apiParam=""
                apiParamValue=""
                dataValue={missionType}
                setDataValue={handleChangeMissionType}
                setDataAlias={setMissionAlias}
                defaultAlias="XXX"
                disableNone="true"
                creator={createMissionTypeHandler}
                read={canRead("id_mission_type")}
                write={canWrite("id_mission_type")}
                required={isRequired("id_mission_type")}
                controlFieldsStatus={fieldsSettingsLoaded}
                cached="false"
                error={getControlFieldsErrorState("id_mission_type")}
                helperText={getControlFieldsErrorMsg("id_mission_type")}
               />

              <SelectAutoCustom
                title="Port"
                api="port/list"
                apiParam=""
                apiParamValue=""
                dataValue={port}
                setDataValue={setDatasPort}
                defaultAlias="YYY"
                creator={createPortHandler}
                enableAddButton
                classType="addButtonSelect2"
                apiAddButton="port"
                field1={{field:"label", label:"Label", required: true}}
                field2={{field:"nomenclature", label:"Nomenclature", required: true, alreadyExist: true}}
                field3={{field:"id_country", label:"Country", api:"country/list", initValues: portCountryId, required: true}}
                read={canRead("id_port")}
                write={canWrite("id_port")}
                required={isRequired("id_port")}
                controlFieldsStatus={fieldsSettingsLoaded}
                cached={true}
                cacheTTL="600"
                resetCache="1"
                error={getControlFieldsErrorState("id_port")}
                helperText={getControlFieldsErrorMsg("id_port")}
              />

              <SelectAutoCustom
                title="Principal"
                api="company/list"
                apiParam="company_role"
                apiParamValue="Principals"
                dataValue={principal}
                setDataValue={setDatasPrincipal}
                defaultAlias=""
                creator={createPrincipalHandler}
                enableAddButton={JLBInternalMode}
                ref={companyPrincipalSelectRef}
                companySelectRefresh={companySelectRefresh}
                classType="addButtonSelect2"
                apiAddButton="company"
                field4={
                  { componentName: "CompanyForm", 
                    componentClass: "companyFormClass",
                    buttonDisableControls: true,
                    thirdButton: 
                      {label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId"},
                    initValues:
                      {principals: true, trader: false, insured: false, receiver: false, shipper: false},
                  }}
                read={canRead("id_principal")}
                write={canWrite("id_principal")}
                required={isRequired("id_principal")}
                controlFieldsStatus={fieldsSettingsLoaded}
                cached={true}
                cacheTTL="600"
                resetCache="1"
                error={getControlFieldsErrorState("id_principal")}
                helperText={getControlFieldsErrorMsg("id_principal")}
              />  

              <SelectCustom
                title="Survey handler"
                api="survey_handler/list"
                apiParam=""
                apiParamValue=""
                dataValue={surveyHandler}
                setDataValue={setSurveyHandler}
                setDataAlias=""
                defaultAlias=""
                defaultValue={surveyHandlerDefault}
                creator={createSurveyHandlerHandler}
                read={canRead("id_survey_handler")}
                write={canWrite("id_survey_handler")}
                required={isRequired("id_survey_handler")}
                controlFieldsStatus={fieldsSettingsLoaded}
                cached={true}
                error={getControlFieldsErrorState("id_survey_handler")}
                helperText={getControlFieldsErrorMsg("id_survey_handler")}
                />

              <SelectAutoCustom
                title="Local office"
                api="company/list"
                apiParam="company_role"
                apiParamValue="Local Office"
                dataValue={localOffice}
                setDataValue={setDatasOffice}
                defaultAlias=""
                creator={createLocalOfficeHandler}
                ref={companyLocalOfficeSelectRef}
                companySelectRefresh={companySelectRefresh}
                read={canRead("id_local_office")}
                write={canWrite("id_local_office")}
                required={isRequired("id_local_office")}
                controlFieldsStatus={fieldsSettingsLoaded}
                cached={true}
                error={getControlFieldsErrorState("id_local_office")}
                helperText={getControlFieldsErrorMsg("id_local_office")}
              />

              </div>
              <div className="lineContent">
              
              <SelectAutoCustom
                title="Trader"
                api="company/list"
                apiParam="company_role"
                apiParamValue="Trader"
                dataValue={trader}
                setDataValue={setDatasTrader}
                defaultAlias=""
                creator={createTraderHandler}
                enableAddButton={JLBInternalMode}
                ref={companyTraderSelectRef}
                companySelectRefresh={companySelectRefresh}
                classType="addButtonSelect2"
                apiAddButton="company"
                field4={
                  { componentName: "CompanyForm", 
                    componentClass: "companyFormClass",
                    buttonDisableControls: true,
                    thirdButton: 
                      {label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId"},
                    initValues:
                      {principals: false, trader: true, insured: false, receiver: false, shipper: false},
                  }}
                read={canRead("id_trader")}
                write={canWrite("id_trader")}
                required={isRequired("id_trader")}
                controlFieldsStatus={fieldsSettingsLoaded}
                cached={true}
                cacheTTL="600"
                resetCache="1"
                error={getControlFieldsErrorState("id_trader")}
                helperText={getControlFieldsErrorMsg("id_trader")}
              />

              <SelectAutoCustom
                title="Insured"
                api="company/list"
                apiParam="company_role"
                apiParamValue="Insured"
                dataValue={insured}
                setDataValue={setDatasInsured}
                defaultAlias=""
                creator={createInsuredHandler}
                enableAddButton={JLBInternalMode}
                ref={companyInsuredSelectRef}
                companySelectRefresh={companySelectRefresh}
                classType="addButtonSelect2"
                apiAddButton="company"
                field4={
                  { componentName: "CompanyForm", 
                    componentClass: "companyFormClass",
                    buttonDisableControls: true,
                    thirdButton: 
                      {label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId"},
                    initValues:
                      {principals: false, trader: false, insured: true, receiver: false, shipper: false},
                  }}
                read={canRead("id_insured")}
                write={canWrite("id_insured")}
                required={isRequired("id_insured")}
                controlFieldsStatus={fieldsSettingsLoaded}
                cached={true}
                cacheTTL="600"
                resetCache="1"
                error={getControlFieldsErrorState("id_insured")}
                helperText={getControlFieldsErrorMsg("id_insured")}
              />
              
              <SelectAutoCustom
                title="Shipper"
                api="company/list"
                apiParam="company_role"
                apiParamValue="Shipper"
                dataValue={shipper}
                setDataValue={setDatasShipper}
                defaultAlias=""
                creator={createShipperHandler}
                enableAddButton={JLBInternalMode}
                ref={companyShipperSelectRef}
                companySelectRefresh={companySelectRefresh}
                classType="addButtonSelect2"
                apiAddButton="company"
                field4={
                  { componentName: "CompanyForm", 
                    componentClass: "companyFormClass",
                    buttonDisableControls: true,
                    thirdButton: 
                      {label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId"},
                    initValues:
                      {principals: false, trader: false, insured: false, receiver: false, shipper: true},
                  }}
                read={canRead("id_shipper")}
                write={canWrite("id_shipper")}
                required={isRequired("id_shipper")}
                controlFieldsStatus={fieldsSettingsLoaded}
                cached={true}
                cacheTTL="600"
                resetCache="1"
                error={getControlFieldsErrorState("id_shipper")}
                helperText={getControlFieldsErrorMsg("id_shipper")}
              />
              
              <TextFieldCustom
                id="tonnage"
                label="Tonnage"
                variant="standard"
                value={tonnage}
                type="decimal"
                onChange={changeTonnageHandler}
                read={canRead("vessel_tonnage")}
                write={canWrite("vessel_tonnage")}
                required={isRequired("vessel_tonnage")}
                controlFieldsStatus={fieldsSettingsLoaded}
                error={getControlFieldsErrorState("vessel_tonnage")}
                helperText={getControlFieldsErrorMsg("vessel_tonnage")}
              />
              
              <TextFieldCustom
                id="contract_number"
                label="Contract number"
                variant="standard"
                value={contractNumber}
                onBlur={changeContractNumberHandler}
                read={canRead("contract_number")}
                write={canWrite("contract_number")}
                required={isRequired("contract_number")}
                controlFieldsStatus={fieldsSettingsLoaded}
                error={getControlFieldsErrorState("contract_number")}
                helperText={getControlFieldsErrorMsg("contract_number")}
              />
            </div>

            <div className="lineContent">
                <FormControl className="textField-form-comment" sx={{ m: 1, width: '100%' }} size="small">
                  <TextField
                    id="standard-multiline-internal"
                    label="Internal note"
                    multiline
                    value={internalNote}
                    onChange={changeInternalNoteHandler}
                    variant="standard"
                    fullWidth
                  />
                </FormControl>
            </div>

            <div className="lineContent">
              <Box sx={{ textAlign: 'center' }}>
                <Button 
                  color="primary" 
                  variant="outlined" 
                  startIcon={<FolderOpenIcon />} 
                  onClick={handleOpenFolderClick}>
                  Open Sharepoint Folder
                </Button>
              </Box>
            </div>
            
            { idMission > 0 &&
              <>
                <div className="lineContent separator"/>
                <ProtisForm
                  idMission={idMission}
                  photosComment={photosComment}
                  setPhotosComment={setPhotosComment}
                  latestSyncAt={latestSyncAt}
                  desactivateBlockMode={true}

                  setSnackBarMessage={setSnackBarMessage} 
                  setSnackBarType={setSnackBarType} 
                  setOpenSnackBar={setOpenSnackBar}
                />
              </>
            }
            
            <Box sx={{margin: 'auto'}}>
              <Box sx={{ '& > button': { m: 1 } }} className="loadingButtonBlock">
                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={0}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={1}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={2}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={3}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={4}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={5}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={6}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={7}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded} 
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={8}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={9}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />

                <LoadingButtonCustom 
                  buttons={controlButtons}
                  index={10}
                  icon={<SaveIcon />}
                  loadingPosition="end"
                  loading={loading}
                  controlFieldsStatus={fieldsSettingsLoaded}
                  handleClick={handleClickWorkflow}
                />
              </Box>
            </Box>

          </div>
            <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert onClose={handleCloseSnackbar} severity={snackBarType} sx={{ width: '100%' }}>
                {snackBarMessage}
              </Alert>
            </Snackbar>
            <InfosPopin
              setOpen={setOpenInfosPopin}
              open={openInfosPopin}
              type={infosPopinType}
              title={infosPopinTitle}
              msg={infosPopinMsg}
            />
          </div>
        </div>
        
        <Menu />
      </div>
    )
}

export default CocoaDepartureMissionsEdit
