import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import { randomId } from "@mui/x-data-grid-generator";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import { checkSecurity } from "components/config/Security";
import Menu from "components/page/section/Menu";
import MailingListMembersForm from "components/page/mailing/MailingListMembersForm";

import "styles/globalPortalDashboard.css";
import "styles/CreateMission.css";
import "bootstrap/dist/css/bootstrap.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditMailingList = (props) => {
  // CHECK SECURITY
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  var mailingListIdParam = parseInt(queryParams.get("id"));
  if (isNaN(mailingListIdParam)) mailingListIdParam = 0;

  const mailingListMembersFormRef = useRef("mailingListMembersForm");

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("");

  const [idMailingList, setIdMailingList] = useState(mailingListIdParam);
  const [mailingListTitle, setMailingListTitle] = useState("");
  const [rowsMailingListMembers, setRowsMailingListMembers] = useState([]);
  
  const [isMailingListTitleEmpty, setIsMailingListTitleEmpty] = useState(true);
  const [mailingListMembersFormTreatmentNotValidated, setMailingListMembersFormTreatmentNotValidated ] = useState(true);
  
  useEffect(() => {
    checkSecurity(navigate, "EditMailingList");
    const message = sessionStorage.getItem("mailingListSavedMessage");
    if (message) {
      setSnackBarMessage(message);
      setSnackBarType("success");
      setOpenSnackBar(true);
      sessionStorage.removeItem("mailingListSavedMessage");
    }
    setMailingListMembersFormTreatmentNotValidated(true);
    if (props.update == 0) {
      setIdMailingList(mailingListIdParam);
    }
  }, []);
  
  useEffect(() => {
    setIsMailingListTitleEmpty(mailingListTitle?.trim() === "" ? true : false) 
  }, [mailingListTitle])

  function setSnackErrorMsg(data) {
    var errorMsg = "Technical error ! Please try again or contact our support.";
    return errorMsg;
  }
  
  const handleMailingListTitleChange = (event) => {
    const title = event.target.value;
    setMailingListTitle(event.target.value);

    if (title.trim() === "") {
        setMailingListTitle("");
        return;
    }
  };

  // CREATE/UPDATE COMPANY START
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    // Wait for 1 ms to ensure that the blur event is completed
    await new Promise((resolve) => setTimeout(resolve, 1));
    try {
      mailingListMembersFormRef.current.saveComponent().then(({newElementId}) => {
        setIdMailingList(newElementId)
        // Redirection
        if (props.update == 0) {
          navigate("/updateMailingList?id=" +newElementId+ "&key=" + randomId(), { replace: true });
          sessionStorage.setItem("mailingListSavedMessage", "Mailing list saved.");
        } else {
          setSnackBarMessage("Mailing list saved.");
          setSnackBarType("success");
          setOpenSnackBar(true);
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg =
          !!err.response != false ? setSnackErrorMsg(err.response.data) : "";
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  };

  const handleClickCancel = () => {
    navigate("/mailing-lists", { replace: true, });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <div className="FULL-CONTENT DASHBOARD">
      <div className="content">
        <div className="contentDetail">
          <Stack direction="row">
            <Box sx={{ width: "100%" }}>
              <div className="lineContent">
                <span>
                    {mailingListTitle ? (<h2> <strong>{mailingListTitle}</strong> </h2> ) : ( <h2> <strong>New mailing list</strong> </h2> )}
                </span>
              </div>
                <div className="lineContent">
                  <TextField
                      required
                      id="mailingListTitle"
                      label="Title"
                      type="text"
                      value={mailingListTitle}
                      variant="standard"
                      onChange={handleMailingListTitleChange}
                      style={{ width: "100%" }}
                      error={isMailingListTitleEmpty}
                  />
              </div>
              <div className="lineContent">
                <MailingListMembersForm
                  ref={mailingListMembersFormRef}
                  update={props.update}
                  idMailingList={idMailingList}
                  mailingListTitle={mailingListTitle}
                  setMailingListTitle={setMailingListTitle}
                  rowsMailingListMembers={rowsMailingListMembers}
                  setRowsMailingListMembers={setRowsMailingListMembers}
                  // SAVE BUTTON DISABLE CONTROL
                  setMailingListMembersFormTreatmentNotValidated={setMailingListMembersFormTreatmentNotValidated}
                  // Snackbar
                  setSnackBarMessage={setSnackBarMessage}
                  setSnackBarType={setSnackBarType}
                  setOpenSnackBar={setOpenSnackBar}
                />
              </div>
            </Box>
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <LoadingButton
              onClick={handleClickCancel}
              loadingIndicator="Cancelling operation"
              variant="outlined"
              startIcon={<CloseIcon className="LoadingButtonIcon"/>} 
            >
              Cancel
            </LoadingButton>
            <Tooltip title={/*mailingListMembersFormTreatmentNotValidated ||*/ rowsMailingListMembers.length <= 0 || isMailingListTitleEmpty ? "Some fields are empty or contain errors, please check all red fields before saving" : ""} placement="top">
              <span>
                <LoadingButton
                  onClick={handleClickSave}
                  loadingIndicator="Saving mailing list"
                  variant="contained"
                  startIcon={<SaveIcon className="LoadingButtonIcon"/>} 
                  disabled={/*mailingListMembersFormTreatmentNotValidated ||*/ rowsMailingListMembers.length <= 0 || isMailingListTitleEmpty}
                >
                  Save
                </LoadingButton>
              </span>
            </Tooltip>
          </Stack>
        </div>
      </div>
      <Menu />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackBarType}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditMailingList;
