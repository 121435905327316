import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";

import axios from 'api/axios';


const ContextMissionDetailsProtis = createContext();

const MissionDetailsProtisProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { protisRef } = useContext(ContextMissionDetails);

  //API URL
  const MISSION_URL = 'mission';

  //init id mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  //mission general state
  const {general, setGeneral} = useContext(ContextMissionDetails);

  const {photosComment, setPhotosComment} = useContext(ContextMissionDetails);
  const [oldPhotosComment, setOldPhotosComment] = useState('');
  const [effectiveChange, setEffectiveChange] = useState(0);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  const {blockEdit, setBlockEdit} = useContext(ContextMissionDetails);
  const {setReloadBlockData} = useContext(ContextMissionDetails);
  const {setActiveBlockEdit} = useContext(ContextMissionDetails);
  const {blockSave, setBlockSave} = useContext(ContextMissionDetails);
  const {setDoneBlockSave} = useContext(ContextMissionDetailsTreatment);
  const {setErrorBlockSave} = useContext(ContextMissionDetailsTreatment);
  const {saveBlockAndCheckWorkflow} = useContext(ContextMissionDetailsTreatment);


  //useEffect
  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {
      //(RE)INIT detect change
      setEffectiveChange(1);
      if (reloadData > oldReloadData) {
        setOldReloadData(reloadData);
      }

      //INIT photos_comment
      if (general.photos_comment) {
        setPhotosComment(general.photos_comment);
        setOldPhotosComment(general.photos_comment);
      }
    }
  }, [general, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(protisRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockEdit.findIndex(obj => obj.blockRef === protisRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block edit
  useEffect(() => {
    // >= 2 ? => start at 0 and +1 for initialization of parameters
    //because first initialization of parameters call the useEffect
    if (effectiveChange >= 1) {
      if (photosComment != oldPhotosComment) {
        setActiveBlockEdit(protisRef);
      }
    } else {
      setEffectiveChange(effectiveChange+1);
    }
  }, [photosComment]);

  //init block save
  useEffect(() => {
    saveBlockAndCheckWorkflow(protisRef, saveMissionDetailsProtis);
  }, [blockSave, idMission]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockSave.findIndex(obj => obj.blockRef === protisRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);


  //function treatment
  const getDataSaveMissionDetailsProtis = async () => {
    var data = new FormData();
    data.append('id_mission', Number(idMission));
    data.append('photos_comment', photosComment);

    return data;
  }

  const saveMissionDetailsProtis = async () => {
    try {
      var data = await getDataSaveMissionDetailsProtis();

      //API DATA SENT
      var jsonRequestData = JSON.stringify(Object.fromEntries(data));
      var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, 'null');    //PATCH ERREUR NULL

      var config = {
        method: 'put',
        url: MISSION_URL,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestDataFinal
      };

      const response = await axios(config);
      setDoneBlockSave(protisRef);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setErrorBlockSave(protisRef);
      }
    }
  };


  return (
    <ContextMissionDetailsProtis.Provider
      value=
      {{
      }}
    >
      {children}
    </ContextMissionDetailsProtis.Provider>
  );
};

export { MissionDetailsProtisProvider, ContextMissionDetailsProtis };
