import React from 'react';
import { useNavigate } from "react-router-dom";
import momentDate from 'moment';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { DataGridPro } from '@mui/x-data-grid-pro';
import 'styles/CreateMission.css';

import axios from 'api/axios';
const INTERNAL_NOTIFICATION_READ_URL = 'mission/notification/internal/read';

export default function MissionInternalNotification(props){

    const navigate = useNavigate();

    function CustomNoRowsOverlay() {
        return (<div className="customNoRowsOverlay">no internal notification</div>);
    }

    //launch api to mark as read and reload data
    const handleMarkAsRead = async (id_mission_internal_notif) => {
        try {
            //set data
            var data = {id_mission_internal_notification: id_mission_internal_notif};
            var jsonRequestData = JSON.stringify(data);
            var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, 'null');

            //launch api
            var config = {
                url: INTERNAL_NOTIFICATION_READ_URL,
                method: 'put',
                headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json',
                },
                data: jsonRequestDataFinal,
            };
            const response = await axios(config);

            //reload data
            props.updateInternalNotif(
                id_mission_internal_notif, 
                "1", 
                response.data.read_on);
        } catch (err) {
            console.log(err);
            if (err.response?.status === 401) {
                navigate("/logout", { replace: true });
            }
            else {
                props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
                props.setSnackBarType("error");
                props.setOpenSnackBar(true);
            }
        }
    }

    //check if marked as read, display date of reading or button to mark as read
    const RenderRead = (data) => {
        if(data.row.marked_as_read != "0" && data.row.read_on != ""){
            return momentDate(data.row.read_on).format("DD MMM YY / H:mm");
        }else{
            if(props.canMarkAsRead == true){
                return (<div className="markAsRead">
                    <Button size="small"
                        color="success"
                        variant="contained"
                        className="buttonMarkAsRead"
                        onClick={(event) => {handleMarkAsRead(data.row.id)}}
                    >
                        Mark as viewed
                    </Button>
                </div>);
            }else{
                return "Not viewed";
            }
        }
    };

    //define column and dom
    const columnsInternalNotification : GridColDef[] = [
        { field: 'date',            headerName: 'Date/Time',    editable: false, sortable: false, type: 'date', width: 150,
        valueFormatter: params => {if(params?.value != null) return momentDate(params?.value).format("DD MMM YY / H:mm")}, },
        { field: 'text',            headerName: 'Alert',        editable: false, sortable: false, flex:1 },
        { field: 'marked_as_read',  headerName: '',             editable: false, sortable: false, align: "center", width: 150,
        renderCell: RenderRead },
    ];

    return (
        <div className="datagridLot">
            <DataGridPro
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                autoHeight
                density="compact"
                sx={{ flex: 1 }}
                rows={props.rows}
                columns={columnsInternalNotification}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                hideFooter
            />
        </div>
    );
}