import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DataGridPro, GridColDef, gridVisibleSortedRowIdsSelector } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { randomId } from '@mui/x-data-grid-generator';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CommentIcon from '@mui/icons-material/Comment';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {isInternalRole, isPortRole, isCustomerRole} from 'components/config/Roles';
import Badge from '@mui/material/Badge';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';

import {checkSecurity} from 'components/config/Security';
import axios from 'api/axios';
const GETMISSIONLIST_URL = 'mission/list';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MissionsList(props) {

  const [JLBInternalMode] = React.useState(isInternalRole(localStorage.getItem("role")));

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    props.data.setOpenSnackBar(false);
  };

  // CHECK SECURITY
  const navigate = useNavigate();

  useEffect(() => {
    checkSecurity(navigate, "DashboardList");
    if (isCustomerRole(localStorage.getItem("role"))) setHidePrincipal(true);
    getMissionList();
  }, []);
  // END SECURITY

  const RenderType = (props) => {
    const { value } = props;
    return (<div className="typeMissionGridColor" style={{backgroundColor: value}}></div>
    );
  };

  RenderType.propTypes = {
    /**
     * If true, the cell is the active element.
     */
    hasFocus: PropTypes.bool.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.instanceOf(Number),
  };

  const RenderNotif = (props) => {
    var notif = false;
    if (typeof props != "undefined") notif = props;
    if(notif){
      return (
        <span className="puceNotifBorder">
          <Badge className="puceNotif" badgeContent="" variant="dot" overlap="circular"></Badge>
        </span>
      );
    }
  };

  const RenderStatus = (props) => {
    if (!!props.row.missionCancelReason == false) {
      return (<div className="statusMissionGridColor" style={{backgroundColor: props.row.status.statut_color}}>
        {props.row.status.statut_type}
        {(JLBInternalMode) && (RenderNotif(props.row.has_unread_internal_notification))}
      </div>);
    } else {
      var txt = "";
      if (props.row.missionCancelReason) txt = props.row.missionCancelReason;
  
      if (txt.length > 0)
      {
        return (<div className="statusMissionGridColor" style={{width: 'auto'}}>
          <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
            placement="right">
            <div><CancelIcon fontSize="large" sx={{ color: 'red', opacity: '0.6' }}/></div>
          </Tooltip>
          {(JLBInternalMode) && (RenderNotif(props.row.has_unread_internal_notification))}
        </div>);
      }
    }
  };

  const RenderDate = (props) => {
    const { created } = props.row;
    return (moment(created).format("DD MMM YY"));
  };

  const RenderReceiver = (props) => {
    var txt = "";
    var prefix = "";

    for(var i=0; i < props.row.receiverSearch.length; i++) {
      txt = txt + prefix + props.row.receiverSearch[i];
      prefix = "\n\r";
    }

    if (props.row.receiverSearch.length > 0)
    {
      return (<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <IconButton>
                    <FormatListBulletedIcon />
                  </IconButton>
              </Tooltip>);
    }
    else return (<></>);
  };

  const RenderBL= (props) => {
    var txt = "";
    var prefix = "";

    for(var i=0; i < props.row.b_o_l.length; i++) {
      txt = txt + prefix + props.row.b_o_l[i];
      prefix = "\n\r";
    }

    if (props.row.b_o_l.length > 0)
    {
      return (<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <IconButton>
                    <FormatListBulletedIcon />
                  </IconButton>
              </Tooltip>);
    }
    else return (<></>);
  };

  const RenderCommentInternal = (props) => {
    var txt = "";
    if (props.row.internal_note) txt = props.row.internal_note;

    if (txt.length > 0)
    {
      return(<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <CommentIcon fontSize="small" />
             </Tooltip>
      );
    }
    else return (<></>);
  }

  const RenderCommentPublic = (props) => {
    var txt = "";
    if (props.row.public_note) txt = props.row.public_note;

    if (txt.length > 0)
    {
      return(<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <CommentIcon fontSize="small" />
             </Tooltip>
      );
    }
    else return (<></>);
  }

  function getStatusId(params) {
    if (!!params.row.missionCancelReason == false) {
      return `${params.row.status.statut_type}`;
    } else {
      return 'canceled';
    }
  }

  const [hidePrincipal, setHidePrincipal] = useState(false);
  const [refJLBTXTLabel, setRefJLBTXTLabel] = useState('Ref JLB');
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide:true,
    },
    {
      field: 'survey_handler',
      headerName: 'Survey handler',
      hide:true,
    },
    {
      field: 'missionColor',
      headerName: '',
      sortable: false,
      flex:1,
      width:5,
      minWidth:5,
      maxWidth:5,
      padding:0,
      type:'number',
      renderCell: RenderType,
    },
    {
      field: 'ref',
      headerName: 'Ref JLB',
      width:145,
    },
    {
      field: 'internal_note',
      headerName: '',
      sortable: false,
      flex:1,
      width:40,
      minWidth:40,
      maxWidth:40,
      padding:0,
      renderCell: RenderCommentInternal,
      hide:hidePrincipal,
    },
    {
      field: 'public_note',
      headerName: '',
      sortable: false,
      flex:1,
      width:40,
      minWidth:40,
      maxWidth:40,
      padding:0,
      renderCell: RenderCommentPublic,
      hide:!hidePrincipal,
    },
    {
      field: 'missionType',
      headerName: 'Mission Type',
      hide:true,
      type:'number',
    },
    {
      field: 'missionTypeLabel',
      headerName: 'Type of mission',
      hide:!hidePrincipal,
      width:170,
    },
    {
      field: 'created',
      headerName: 'Creation date',
      width:110,
      type:'date',
      align:'center',
      renderCell: RenderDate,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth:64,
      maxWidth:104,
      type:'number',
      align:'center',
      renderCell: RenderStatus,
      valueGetter: getStatusId,
    },
    {
      field: 'port',
      headerName: 'Port',
      flex:1,
      minWidth:110,
      maxWidth:150,
    },
    {
      field: 'principals',
      headerName: 'Principals',
      flex:1,
      minWidth:140,
      hide: hidePrincipal,
    },
    {
      field: 'principalsRef',
      headerName: 'Your réf.',
      flex:1,
      minWidth:140,
      hide: !hidePrincipal,
    },
    {
      field: 'insured',
      headerName: 'Insured',
      flex:1,
      minWidth:140,
    },
    {
      field: 'vessel',
      headerName: 'Vessel (BL)',
      flex:1,
      minWidth:140,
    },
    {
      field: 'tonnage',
      headerName: 'Tonnage',
      flex:0.7,
      minWidth:100,
      type:'number',
    },
    {
      field: 'receiver',
      headerName: 'Receiver',
      flex:1,
      minWidth:80,
      sortable: false,
      renderCell: RenderReceiver,
    },
    {
      field: 'receiverSearch',
      headerName: 'Receiver search',
      flex:1,
      minWidth:100,
      sortable: false,
      hide:true,
    },
    {
      field: 'cargo',
      headerName: 'Cargo',
      flex:1,
      minWidth:100,
      sortable: false,
      hide:true,
    },
    {
      field: 'trader',
      headerName: 'Trader',
      flex:1,
      minWidth:100,
      sortable: false,
      hide:true,
    },
    {
      field: 'b_o_l',
      headerName: 'Bill of lading',
      flex:0.7,
      minWidth:140,
      sortable: false,
      renderCell: RenderBL,
    },

    {
      field: 'warehouse',
      headerName: 'Warehouse',
      flex:1,
      minWidth:140,
    },
  ];

  const handleRowClick = (params) => {
    navigate("/missiondetails?id="+params.row.id+"&key="+randomId(), { replace: true });
  };

  function getUnique(arr, index) {
    const unique = arr
         .map(e => e[index])

         // store the keys of the unique objects
         .map((e, i, final) => final.indexOf(e) === i && i)

         // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

     return unique;
  }

  const getMissionList = async (e) => {
    try {
      props.data.setLoadingGrid(true);
        const config = {
          headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            all: 1,
          }
        };
        
        if (!isCustomerRole(localStorage.getItem("role"))) {
          if (props.archivedList == 1 ) {
            config.params.archived_missions = 1;
          } else {
            config.params.archived_missions = 0;
          }
        }
        
        if (isCustomerRole(localStorage.getItem("role"))) {
          config.params.id_principal = parseInt(localStorage.getItem("id_company"));
        }
        
        const url = GETMISSIONLIST_URL;

        const response = await axios.get(url, config);

        var rowsFromApi = [];
        var vessels = [];
        var surveys = [];
        var principals = [];
        var missions = [];
        var ports = [];
        var insured = [];
        var receivers = [];
        var idRow = 0;

        for(var i=0; i < response.data.length; i++) {

          if (isCustomerRole(localStorage.getItem("role")))
          {
            if (parseInt(localStorage.getItem("id_company")) != parseInt(response.data[i].principal.id))
            {
                continue;
            }
          }
          idRow = rowsFromApi.length;
          rowsFromApi[idRow] = {};
          rowsFromApi[idRow].id = response.data[i].id;
          rowsFromApi[idRow].missionType = response.data[i].mission_type.id;
          missions[missions.length] = response.data[i].mission_type;
          rowsFromApi[idRow].missionTypeLabel = response.data[i].mission_type.label;
          rowsFromApi[idRow].missionColor = response.data[i].mission_type.color;
          if (response.data[i].canceled_at != null && response.data[i].cancel) {
            rowsFromApi[idRow].missionCancelReason = response.data[i].cancel.reason.label;
          }
          if (response.data[i].port) rowsFromApi[idRow].ref = response.data[i].mission_type.nomenclature+"-"+response.data[i].port.nomenclature+"-"+pad(response.data[i].id);
          else rowsFromApi[idRow].ref = response.data[i].mission_type.nomenclature+"-<XXX>-"+pad(response.data[i].id);
          rowsFromApi[idRow].created = response.data[i].created_at;
          if (isCustomerRole(localStorage.getItem("role"))) {
            if (response.data[i].status) rowsFromApi[idRow].status = { statut_type: response.data[i].status.status.public_status.status.value, statut_color: response.data[i].status.status.public_status.status.color};
            else rowsFromApi[idRow].status = { statut_type: '0', statut_color: 'red'};
          }
          else {
            if (response.data[i].status) rowsFromApi[idRow].status = { statut_type: response.data[i].status.status.value, statut_color: response.data[i].status.status.color};
            else rowsFromApi[idRow].status = { statut_type: '0', statut_color: 'red'};
          }

          if (response.data[i].port)
          {
            rowsFromApi[idRow].port = response.data[i].port.label;
            ports[ports.length] = response.data[i].port;
          }

          if (response.data[i].principal_ref) rowsFromApi[idRow].principalsRef = response.data[i].principal_ref;

          rowsFromApi[idRow].principals = response.data[i].principal.name;
          principals[principals.length] = response.data[i].principal;
          if (response.data[i].insured)
          {
            rowsFromApi[idRow].insured = response.data[i].insured.name;
            insured[insured.length] = response.data[i].insured;
          }
          else rowsFromApi[idRow].insured = "";
          if (response.data[i].vessel)
          {
            rowsFromApi[idRow].vessel = response.data[i].vessel.name;
            vessels[vessels.length] = response.data[i].vessel;
          }
          else rowsFromApi[idRow].vessel  = "";
          rowsFromApi[idRow].survey_handler = response.data[i].survey_handler.firstname+" "+response.data[i].survey_handler.lastname;
          surveys[surveys.length] = response.data[i].survey_handler;

          var contentRowLoop = [];
          if (response.data[i].receivers)
          {
            for (var j=0; j < response.data[i].receivers.length; j++) {
              if (response.data[i].receivers[j].receiver)
              {
                contentRowLoop[j] = response.data[i].receivers[j].receiver.name;
                receivers[receivers.length] = response.data[i].receivers[j].receiver;
              }
            }
          }
          rowsFromApi[idRow].receiver = contentRowLoop;
          rowsFromApi[idRow].receiverSearch = contentRowLoop;

          var contentRowLoopBL = [];
          if (response.data[i].bills_of_lading)
          {
            for (var j=0; j < response.data[i].bills_of_lading.length; j++) {
              contentRowLoopBL[j] = response.data[i].bills_of_lading[j].number;
            }
          }
          rowsFromApi[idRow].b_o_l = contentRowLoopBL;

          if (response.data[i].vessel_tonnage) rowsFromApi[idRow].tonnage = Number(response.data[i].vessel_tonnage);
          if (response.data[i].warehouse) rowsFromApi[idRow].warehouse = response.data[i].warehouse.label;
          if (response.data[i].cargo) rowsFromApi[idRow].cargo = response.data[i].cargo.label;
          if (response.data[i].trader) rowsFromApi[idRow].trader = response.data[i].trader.name;
          rowsFromApi[idRow].public_note = response.data[i].public_note;
          rowsFromApi[idRow].internal_note = response.data[i].internal_note;
          rowsFromApi[idRow].has_unread_internal_notification = response.data[i].has_unread_internal_notification;
        }

        props.data.setRows(rowsFromApi);
        props.data.setTotalRows(rowsFromApi);

        vessels.sort((a, b) => (a.name > b.name) ? 1 : -1);
        props.data.setVesselRows(getUnique(vessels,'id'));
        surveys.sort((a, b) => ((a.firstname + " " + a.lastname) > (b.firstname + " " + b.lastname)) ? 1 : -1);
        props.data.setSurveyRows(getUnique(surveys,'id'));
        principals.sort((a, b) => (a.name > b.name) ? 1 : -1);
        props.data.setPrincipalRows(getUnique(principals,'id'));
        missions.sort((a, b) => (a.label > b.label) ? 1 : -1);
        props.data.setMissionRows(getUnique(missions,'id'));
        ports.sort((a, b) => (a.label > b.label) ? 1 : -1);
        props.data.setPortRows(getUnique(ports,'id'));
        insured.sort((a, b) => (a.name > b.name) ? 1 : -1);
        props.data.setInsuredRows(getUnique(insured,'id'));
        receivers.sort((a, b) => (a.name > b.name) ? 1 : -1);
        props.data.setReceiverRows(getUnique(receivers,'id'));
        if (props.data.initFilterMissions) props.data.initFilterMissions(rowsFromApi);

    } catch (err) {
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        props.data.setOpenSnackBar(true);
        console.log(err);
      }
    } finally
    {
      props.data.setLoadingGrid(false);
      props.data.setDataGridLoaded(true);
    }
  }

  function CustomNoRowsOverlay() {
    return (<div></div>);
  }

  function pad(num) {
      var s = "000000" + num;
      return s.substr(s.length-6);
  }

  return (
      <>
        <DataGridPro sx={{ height: '100%', width: '100%' }}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          initialState={{
            pagination: {
              pageSize: 15,
            },
            sorting: {
              sortModel: [{ field: 'created', sort: 'desc' }],
            },
          }}
          loading={props.data.loadingGrid}
          rows={props.data.rows}
          columns={columns}
          rowsPerPageOptions={[8, 15, 25, 50, 100]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          onRowClick={handleRowClick}
          filterModel={props.data.filterModel}
          onFilterModelChange={props.data.rowsSelected}
          onStateChange={(state) => {
            const newRows = gridVisibleSortedRowIdsSelector(state);
            props.data.setVisibleRows(newRows);
          }}
          getRowClassName={(params) => {
            var className = '';
            if (!!params.row.missionCancelReason != false) {
              className = 'dataGridRowMissionCanceled';
            }
            return className;
          }}
        />
        <Snackbar open={props.data.openSnackBar} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Technical error ! Please try again or contact our support.
          </Alert>
        </Snackbar>
      </>
  );
}
