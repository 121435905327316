import React, { useState, forwardRef, useContext } from 'react';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';

import { DataGridPro } from 'components/page/object/DataGridProCustom';
import { isInternalRole, isPortRole, isCustomerRole } from 'components/config/Roles';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";
import { ContextMissionDetailsSubInvoice } from "./ContextMissionDetailsSubInvoice";


const MissionDetailsSubInvoiceView = forwardRef((props, ref) => {

  //init config
  const {update} = useContext(ContextMissionDetails);
  const {subInvoiceRef} = useContext(ContextMissionDetails);
  const [JLBPortMode] = useState(isPortRole(localStorage.getItem("role")));
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextMissionDetails);

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);

  //mission
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  //Subcontractor Invoices Grid
  const { rowsSubInvoiceView, setRowsSubInvoiceView } = useContext(ContextMissionDetailsSubInvoice);

  //context
  const { CustomNoRowsOverlay } = useContext(ContextMissionDetails);

  //handle
  const {handleClickBlockEdit} = useContext(ContextMissionDetailsTreatment);

  const editFile = (file) => {
    window.open(file.file_path, "_blank");
  };

  //xGrid
  const columnsSubInvoices: GridColDef[] = [
    { field: 'number', headerName: 'Subcontracting Invoice Number', editable: false, sortable: true, width: 250, flex: 1 },
    { field: 'date', headerName: 'Subcontracting Invoice Date', editable: false, sortable: true, type: 'date', width: 250 },
    {
      field: 'file', headerName: 'File', editable: false, sortable: false, width: 250,
      renderCell: (cellValues) => {
        if (cellValues.row.file != null && cellValues.row.file.file_name != undefined) {
          return (
            <>
              <span className="subInvoiceFilenameSpan">{cellValues.row.file.file_name}</span>
              {(JLBInternalMode) && (
                <IconButton aria-label="edit" onClick={(event) => {
                  editFile(cellValues.row.file);
                }}>
                  <EditIcon />
                </IconButton>
              )}
            </>
          );
        }
      }
    }
  ];

  return (
    <div>
      {(Boolean(canRead("subInvoice_block")) && !JLBPortMode) && 
        <div className="lineContent">
          <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-subinvoice">
            <DataGridPro
              disableSelectionOnClick
              disableColumnMenu
              disableColumnFilter
              autoHeight
              hideFooter={true}
              rows={rowsSubInvoiceView}
              columns={columnsSubInvoices}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          </FormControl>
        </div>
      }

      {(update == 1 && loadingUpdate && !missionCanceled && !JLBCustomerMode && !JLBPortMode) && 
      <div className="block_button">
        <LoadingButton className="edit" onClick={()=>handleClickBlockEdit(subInvoiceRef)} loadingPosition="start" startIcon={<EditIcon/>}> Edit</LoadingButton>
      </div>
      }
    </div>
  );
});

export default MissionDetailsSubInvoiceView;
