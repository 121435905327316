import React, {useContext, useState} from 'react';

import { MissionDetailsProtisProvider } from "./ContextMissionDetailsProtis";

import {isCustomerRole} from 'components/config/Roles';
import { ContextMissionDetails } from "context/ContextMissionDetails";
import MissionDetailsProtisForm from './MissionDetailsProtisForm';


const MissionDetailsProtis = (props) => {

  //init config
  const {update} = useContext(ContextMissionDetails);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const {openSnackBar, setOpenSnackBar} = useContext(ContextMissionDetails);
  const {snackBarType, setSnackBarType} = useContext(ContextMissionDetails);
  const {snackBarMessage, setSnackBarMessage} = useContext(ContextMissionDetails);

  //init mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  const {latestSyncAt, setLatestSyncAt} = useContext(ContextMissionDetails);
  const {photosComment, setPhotosComment} = useContext(ContextMissionDetails);
  const {reloadFileBlock, setReloadFileBlock} = useContext(ContextMissionDetails);


  if (update == 1 && !JLBCustomerMode) {
    return (
      <MissionDetailsProtisProvider>
        <MissionDetailsProtisForm
        idMission={idMission}
        latestSyncAt={latestSyncAt}
        photosComment={photosComment}
        setPhotosComment={setPhotosComment}
        missionCanceled={missionCanceled}

        setOpenSnackBar={setOpenSnackBar}
        setSnackBarType={setSnackBarType}
        setSnackBarMessage={setSnackBarMessage}
        setReloadFileBlock={setReloadFileBlock}
        />
      </MissionDetailsProtisProvider>
    );
  }
}

export default MissionDetailsProtis
