
const format2Decimals = (str) => {
    const num = parseFloat(str);
    return Math.round(num * 100) / 100;
}

const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

// Format date
const formatDate = (dateString, includeTime = true) => {
    const dateObject = new Date(dateString);
    
    if (includeTime) {
        return dateObject.toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        }).replace(/, /, ' - ');
    } else {
        return dateObject.toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        });
    }
}; 

export { format2Decimals, formatBytes, formatDate };