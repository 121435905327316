import React, {useState, useEffect, useContext, useCallback} from 'react';

import { useNavigate } from "react-router-dom";

import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import FormControl from '@mui/material/FormControl';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import MissionDetailsFormSaveButton from '../../../MissionDetailsFormSaveButton';
import { useGridApiRef, DataGridPro, DataGridSelectCustom } from 'components/page/object/DataGridProCustom';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsReceiver } from "../ContextMissionDetailsReceiver";
import { ContextMissionDetailsReceiverBL } from "../../ContextMissionDetailsReceiverBL";

import axios from 'api/axios';

export default function MissionDetailsReceiverImportCR(props) {

  //init config
  const navigate = useNavigate();
  const apiRefDashboard  = useGridApiRef();
  const apiRefDamageType = useGridApiRef();

  //API URL
  const IMPORTCR_SAVING_URL_DASHBOARD  = 'mission/import/receiver/cr/dashboard';
  const IMPORTCR_SAVING_URL_DAMAGELOSS = 'mission/import/receiver/cr/damage_loss';

  //generic
  const {triggerInfosPopin} = useContext(ContextMissionWorkflow);
  const {loading, setLoading} = useContext(ContextMissionDetails);
  const {openSnackBar, setOpenSnackBar} = useContext(ContextMissionDetails);
  const {snackBarType, setSnackBarType} = useContext(ContextMissionDetails);
  const {snackBarMessage, setSnackBarMessage} = useContext(ContextMissionDetails);

  //init id mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  //receiver context
  const {generateLotLabel} = useContext(ContextMissionDetailsReceiver);
  const {importCRData, setImportCRData} = useContext(ContextMissionDetailsReceiver);
  const {rowsReceiver, setRowsReceiver} = useContext(ContextMissionDetailsReceiverBL);
  const {importPreviousCRData, setImportPreviousCRData} = useContext(ContextMissionDetailsReceiver);
  const {rowsReceiversDataGrid, setRowsReceiversDataGrid} = useContext(ContextMissionDetailsReceiverBL);

  //context
  const {handleRowEditStop} = useContext(ContextMissionDetails);
  const {handleRowEditStart} = useContext(ContextMissionDetails);
  const {CustomNoRowsOverlay} = useContext(ContextMissionDetails);

  //composant data
  const [listDamageType, setListDamageType] = useState([]);
  const [rowsCRDashboard, setRowsCRDashboard] = useState([]);
  const [rowsCRDamageType, setRowsCRDamageType] = useState([]);
  const [cellModeEditDashboard, setCellModeEditDashboard] = useState(false);
  const [cellModeEditDamageType, setCellModeEditDamageType] = useState(false);


  //useEffect
  useEffect(()=>{
    loadListDamageType();
  }, []);

  useEffect(()=>{
    if (Object.keys(importCRData).length > 0) {
      //dashboard
      var i = 0;
      var rowsFromApi = [];
      var rowsDashboard = importCRData.dashboard;
      var rowsPreviousDashboard = importPreviousCRData.dashboard;
      if (Object.keys(rowsDashboard).length > 0) {
        for (const [key, value] of Object.entries(rowsDashboard)) {
          //check if this xls line has already imported
          var previousDashboard = rowsPreviousDashboard.find(obj => Number(obj.xls_line) === Number(key));
          var alreadyImported = previousDashboard && Object.keys(previousDashboard).length > 0 ? true : false;
          //set array data
          rowsFromApi[i] = {
            id: key,
            xls: key,
            
            receiver_id: alreadyImported ? previousDashboard.id_mission_receiver : value.receiver.id,
            receiver_xls_id: value.receiver.id,
            receiver_value: value.receiver.value,
            lot_id: alreadyImported ? previousDashboard.id_mission_receiver_lot : value.lot.id,
            lot_xls_id: value.lot.id,
            lot_value: value.lot.value,
            stevedore_id: alreadyImported ? (previousDashboard.id_company ?? 0) : value.stevedore.id,
            stevedore_xls_id: value.stevedore.id,
            stevedore_value: value.stevedore.value,

            already_imported: alreadyImported
          };
          //add all datas
          for (const [k, v] of Object.entries(value.datas)) {
            rowsFromApi[i][k] = v == '0' ? String(Number(String(v).replace('%', '')).toFixed(2)).replaceAll(',', ' ') : String(v).replaceAll(',', ' ').replace('%', '');
          }
          i++;
        }
      }
      setRowsCRDashboard(rowsFromApi);

      //Damage type
      var i = 0;
      var rowsFromApi = [];
      var rowsDamageType = importCRData.damageType;
      var rowsPreviousDamageType = importPreviousCRData.damageType;
      if (Object.keys(rowsDamageType).length > 0) {
        for (const [key, value] of Object.entries(rowsDamageType)) {
          //check if this xls line has already imported
          var previousDamageType = rowsPreviousDamageType.find(obj => Number(obj.xls_line) === Number(key));
          var alreadyImported = previousDamageType && Object.keys(previousDamageType).length > 0 ? true : false;
          //set array data
          rowsFromApi[i] = {
            id: key,
            xls: key,
            
            receiver_id: alreadyImported ? previousDamageType.id_mission_receiver : value.receiver.id,
            receiver_xls_id: value.receiver.id,
            receiver_value: value.receiver.value,
            lot_id: alreadyImported ? previousDamageType.id_mission_receiver_lot : value.lot.id,
            lot_xls_id: value.lot.id,
            lot_value: value.lot.value,
            stevedore_id: alreadyImported ? (previousDamageType.id_company ?? 0) : value.stevedore.id,
            type_of_damage_id: value.datas.type_of_damage_id,
            percent_of_loss: String(Number(String(value.datas.percent_of_loss).replace('%', '')).toFixed(2)).replaceAll(',', ' '),

            stevedore_xls_id: value.stevedore.id,
            stevedore_value: value.stevedore.value,

            already_imported: alreadyImported
          };
          i++;
        }
      }
      console.log(rowsFromApi);
      setRowsCRDamageType(rowsFromApi);
    }
  }, [importCRData]);

  //set edit column by default
  useEffect(()=>{
    if (!cellModeEditDashboard) {
      if (Object.keys(apiRefDashboard.current).length > 0) {
        var xGridRows = apiRefDashboard.current.getAllRowIds();
        if (xGridRows.length > 0) {
          for (var i=0; i < xGridRows.length; i++) {
            apiRefDashboard.current.startCellEditMode({ id: xGridRows[i], field: 'receiver_id' });
            apiRefDashboard.current.startCellEditMode({ id: xGridRows[i], field: 'lot_id' });
            apiRefDashboard.current.startCellEditMode({ id: xGridRows[i], field: 'stevedore_id' });
          }
          //error if we start edit mode when is already in edit
          setCellModeEditDashboard(true);
        }
      }
    }
  }, [apiRefDashboard.current]);

  useEffect(()=>{
    if (!cellModeEditDamageType) {
      if (Object.keys(apiRefDamageType.current).length > 0) {
        var xGridRows = apiRefDamageType.current.getAllRowIds();
        if (xGridRows.length > 0) {
          for (var i=0; i < xGridRows.length; i++) {
            apiRefDamageType.current.startCellEditMode({ id: xGridRows[i], field: 'receiver_id' });
            apiRefDamageType.current.startCellEditMode({ id: xGridRows[i], field: 'lot_id' });
            apiRefDamageType.current.startCellEditMode({ id: xGridRows[i], field: 'stevedore_id' });
          }
          //error if we start edit mode when is already in edit
          setCellModeEditDamageType(true);
        }
      }
    }
  }, [apiRefDamageType.current]);


  //interactive function
  const processRowUpdateCRDasboard = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsCRDashboard(rowsCRDashboard.map((row) => (row.xls === newRow.xls ? updatedRow : row)));
    return updatedRow;
  };

  const processRowUpdateCRDamageType = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsCRDamageType(rowsCRDamageType.map((row) => (row.xls === newRow.xls ? updatedRow : row)));
    return updatedRow;
  };

  const loadListDamageType = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };
      const response = await axios.get("mission_receiver_damage_type/list", config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = {
          value: response.data[i].id,
          label: response.data[i].label.toUpperCase().trim()
        };
      }
      rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setListDamageType(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadReceiverLots = (receiverId, rowId, rowsData = 'dashboard') => {
    var receiver = rowsReceiver.find(obj => obj.id === receiverId);

    var i = 0;
    var rowsLots = [];
    if (receiver?.lots.length > 0) {
      receiver.lots.map((lot, index) => {
        //generate lot label
        var lotLabel = generateLotLabel(lot, true);

        rowsLots[i] = {
          value: lot.id,
          label: lotLabel
        };
        i++;
      });
    }

    //check if current lot is in new list
    checkValidityReceiverLot(rowsLots, rowId, rowsData);

    return rowsLots;
  }

  const loadReceiverLotStevedore = (receiverId, receiverLotId) => {
    var receiver = rowsReceiver.find(obj => obj.id === receiverId);
    var receiverLot = receiver?.lots.find(obj => obj.id === receiverLotId);

    var i = 0;
    var rowsStevedores = [];
    if (receiverLot?.companies.length > 0) {
      receiverLot.companies.map((company, index) => {
        rowsStevedores[i] = {
          value: company.id,
          label: company.name
        };
        i++;
      });
    }

    return rowsStevedores;
  }

  const checkValidityReceiverLot = (rowsLots, rowId, rowsData) => {
    var rowsDataCR = rowsData === 'dashboard' ? rowsCRDashboard : rowsCRDamageType;
    var rowData = rowsDataCR.find(obj => obj.id === rowId);
    if (rowData && rowData.lot_id != 0) {
      var lot = rowsLots.find(obj => obj.value === rowData.lot_id);
      if (!lot) {
        const rowsDataCRFunc = rowsData === 'dashboard' ? processRowUpdateCRDasboard : processRowUpdateCRDamageType;
        rowsDataCRFunc({...rowData, ['lot_id']: 0, ['stevedore_id']: 0});
      }
    }
  }

  //treatment function
  const handleClickCancel = async (e) => {
    //re-init all data
    setRowsCRDashboard([]);
    setRowsCRDamageType([]);
    setImportCRData([]);
  }

  const handleClickSave = async (e) => {
    try {
      setLoading(true);
      //check receiver & lot data
      var dataDashboardInvalid  = rowsCRDashboard.some(obj => (obj.receiver_id === 0 || obj.lot_id === 0));
      var dataDamageTypeInvalid = rowsCRDamageType.some(obj => (obj.receiver_id === 0 || obj.lot_id === 0));
      
      if (!dataDashboardInvalid && !dataDamageTypeInvalid) {
        //dashboard
        for (var j = 0; j < rowsCRDashboard.length; j++) {
          var data = new FormData();
          data.append('id_mission', parseInt(idMission));
          data.append('id_mission_receiver', parseInt(rowsCRDashboard[j].receiver_id));
          data.append('id_mission_receiver_lot', parseInt(rowsCRDashboard[j].lot_id));
          data.append('id_company', rowsCRDashboard[j].stevedore_id != 0 ? parseInt(rowsCRDashboard[j].stevedore_id) : null);

          data.append('xls_line', parseInt(rowsCRDashboard[j].xls));
          data.append('manifested_quantity', rowsCRDashboard[j].manifested_quantity.replaceAll(',', '').replaceAll(' ', ''));
          data.append('sound_bags', rowsCRDashboard[j].sound_bags.replaceAll(',', '').replaceAll(' ', ''));
          data.append('torn_before_handlings', rowsCRDashboard[j].torn_before_handlings.replaceAll(',', '').replaceAll(' ', ''));
          data.append('torn_during_handlings', rowsCRDashboard[j].torn_during_handlings.replaceAll(',', '').replaceAll(' ', ''));
          data.append('total_torn', rowsCRDashboard[j].total_torn.replaceAll(',', '').replaceAll(' ', ''));
          data.append('wet_stained', rowsCRDashboard[j].wet_stained.replaceAll(',', '').replaceAll(' ', ''));
          data.append('other_damages_1', rowsCRDashboard[j].other_damages_1.replaceAll(',', '').replaceAll(' ', ''));
          data.append('other_damages_2', rowsCRDashboard[j].other_damages_2.replaceAll(',', '').replaceAll(' ', ''));
          data.append('other_damages_3', rowsCRDashboard[j].other_damages_3.replaceAll(',', '').replaceAll(' ', ''));
          data.append('empty', rowsCRDashboard[j].empty.replaceAll(',', '').replaceAll(' ', ''));
          data.append('lob', rowsCRDashboard[j].lob.replaceAll(',', '').replaceAll(' ', ''));
          data.append('total', rowsCRDashboard[j].total.replaceAll(',', '').replaceAll(' ', ''));
          data.append('shortage_surplus', rowsCRDashboard[j].shortage_surplus.replaceAll(',', '').replaceAll(' ', ''));
  
          //API DATA SENT
          var jsonRequestDataReceiver = JSON.stringify(Object.fromEntries(data));
          var jsonRequestDataFinal = jsonRequestDataReceiver.replace(/"null"/g, 'null');
          var config = {
            url: IMPORTCR_SAVING_URL_DASHBOARD,
            method: 'post',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              'Content-Type': 'application/json'
            },
            data: jsonRequestDataFinal
          };
          await axios(config);
        }

        //damage type
        for (var j = 0; j < rowsCRDamageType.length; j++) {
          var data = new FormData();
          data.append('id_mission', parseInt(idMission));
          data.append('id_mission_receiver', parseInt(rowsCRDamageType[j].receiver_id));
          data.append('id_mission_receiver_lot', parseInt(rowsCRDamageType[j].lot_id));
          data.append('id_mission_receiver_damage_type', rowsCRDamageType[j].type_of_damage_id != 0 ? parseInt(rowsCRDamageType[j].type_of_damage_id) : null);
          data.append('id_company', rowsCRDamageType[j].stevedore_id != 0 ? parseInt(rowsCRDamageType[j].stevedore_id) : null);

          data.append('xls_line', parseInt(rowsCRDamageType[j].xls));
          data.append('percent_of_loss', rowsCRDamageType[j].percent_of_loss.replaceAll(',', '').replaceAll(' ', '').replace('%', ''));
  
          //API DATA SENT
          var jsonRequestDataReceiver = JSON.stringify(Object.fromEntries(data));
          var jsonRequestDataFinal = jsonRequestDataReceiver.replace(/"null"/g, 'null');
          var config = {
            url: IMPORTCR_SAVING_URL_DAMAGELOSS,
            method: 'post',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              'Content-Type': 'application/json'
            },
            data: jsonRequestDataFinal
          };
          await axios(config);
        }

        //success
        setSnackBarMessage("Import CR successfully saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //re-init all data
        setRowsCRDashboard([]);
        setRowsCRDamageType([]);
        setImportCRData([]);
      } else {
        triggerInfosPopin('Import CR datas required', 'For each line, the receiver and his lot is required.', 'error');
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoading(false);
    }
  }

  //xGrid function
  const handleDoubleCellClick = useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  // Prevent from rolling back on escape
  const handleCellKeyDown = useCallback((params, event) => {
    if (['Escape', 'Delete', 'Backspace', 'Enter'].includes(event.key)) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  // Prevent from committing on focus out
  const handleCellFocusOut = useCallback((params, event) => {
    if (params.cellMode === 'edit' && event) {
      event.defaultMuiPrevented = true;
    }
  }, []);


  //xGrid - Dashboard
  //data's headerNamer
  var dataHeaderName = {};
  dataHeaderName['manifested_quantity'] = 'Manifested Quantity';
  dataHeaderName['sound_bags'] = 'Sound bags';
  dataHeaderName['torn_before_handlings'] = 'Torn before handlings';
  dataHeaderName['torn_during_handlings'] = 'Torn during handlings';
  dataHeaderName['total_torn'] = 'Total torn';
  dataHeaderName['wet_stained'] = 'Wet/Stained';
  dataHeaderName['other_damages_1'] = 'Other damages';
  dataHeaderName['other_damages_2'] = 'Other damages';
  dataHeaderName['other_damages_3'] = 'Other damages';
  dataHeaderName['empty'] = 'Empty';
  dataHeaderName['lob'] = 'LOB';
  dataHeaderName['total'] = 'Total';
  dataHeaderName['shortage_surplus'] = 'Shortage/Surplus';

  var listColumnCRDashboard = [];
  listColumnCRDashboard.push(
    {field: 'xls', headerName: 'Xls', align: 'center', sortable: true, width: 50, editable: false, pinnable: true},
    {
      field: 'receiver_id', headerName: 'Receiver', sortable: false, type: 'singleSelect', width: 150, editable: true, pinnable: true,
      valueOptions: rowsReceiversDataGrid,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
        <div className={
          (params.value == 0 || (params.row.already_imported !== false && params.row.receiver_id != params.row.receiver_xls_id)) && params.row.receiver_value != '' 
          ? 'importDataStateCustom error' : 'importDataStateCustom'
        }>
          <span>
            {params.value == 0 
              ? params.row.receiver_value == ''
                ? <Tooltip title="no data" placement="top-start"><WarningIcon color="error"/></Tooltip>
                : <Tooltip title="no match" placement="top-start"><UnpublishedIcon color="error"/></Tooltip>
              : params.row.already_imported === false 
                ? <Tooltip title="match" placement="top-start"><CheckCircleIcon color="success"/></Tooltip>
                : <Tooltip title="previously match" placement="top-start"><CheckCircleOutlineIcon color="success"/></Tooltip>
            }
          </span>
          <DataGridSelectCustom
            {...params}  
            read={true}
            write={true}
            control={false}
            processRowUpdate={processRowUpdateCRDasboard}

            keepCellEditMode={true}
            error={params.value == 0 ? true : false}
            infos={params.value != 0 && (params.row.already_imported !== false && params.row.receiver_id != params.row.receiver_xls_id) ? true : false}
            helperText={(params.value == 0 || (params.row.already_imported !== false && params.row.receiver_id != params.row.receiver_xls_id)) && params.row.receiver_value != '' 
              ? params.row.receiver_value 
              : null
            }
          />
        </div>
        )
      }
    },
    {
      field: 'lot_id', headerName: 'Lot', sortable: false, type: 'singleSelect', width: 150, editable: true, pinnable: true,
      valueOptions: ({ row }) => {
        return !row || row.receiver_id === 0 ? [] : loadReceiverLots(row.receiver_id, row.id);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
        <div className={
          (params.value == 0 || (params.row.already_imported !== false && params.row.lot_id != params.row.lot_xls_id)) && params.row.lot_value != '' 
          ? 'importDataStateCustom error' : 'importDataStateCustom'
        }>
          <span>
            {params.value == 0 
              ? params.row.lot_value == ''
                ? <Tooltip title="no data" placement="top-start"><WarningIcon color="error"/></Tooltip>
                : <Tooltip title="no match" placement="top-start"><UnpublishedIcon color="error"/></Tooltip>
              : params.row.already_imported === false 
                ? <Tooltip title="match" placement="top-start"><CheckCircleIcon color="success"/></Tooltip>
                : <Tooltip title="previously match" placement="top-start"><CheckCircleOutlineIcon color="success"/></Tooltip>
            }
          </span>
          <DataGridSelectCustom
            {...params}  
            read={true}
            write={true}
            control={false}
            processRowUpdate={processRowUpdateCRDasboard}

            keepCellEditMode={true}
            error={params.value == 0 ? true : false}
            infos={params.value != 0 && (params.row.already_imported !== false && params.row.lot_id != params.row.lot_xls_id) ? true : false}
            helperText={(params.value == 0 || (params.row.already_imported !== false && params.row.lot_id != params.row.lot_xls_id)) && params.row.lot_value != '' 
              ? params.row.lot_value 
              : null
            }
          />
        </div>
        )
      }
    },
    {
      field: 'stevedore_id', headerName: 'Stevedore', sortable: false, type: 'singleSelect', width: 150, editable: true, pinnable: true,
      valueOptions: ({ row }) => {
        return !row || row.receiver_id === 0 || row.lot_id === 0 ? [] : loadReceiverLotStevedore(row.receiver_id, row.lot_id);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
        <div className={
          (params.value == 0 || (params.row.already_imported !== false && params.row.stevedore_id != params.row.stevedore_xls_id)) && params.row.stevedore_value != '' 
          ? 'importDataStateCustom error' : 'importDataStateCustom'
        }>
          <span>
            {params.value == 0 
              ? params.row.stevedore_value == ''
                ? <Tooltip title="no data" placement="top-start"><WarningIcon color="error"/></Tooltip>
                : <Tooltip title="no match" placement="top-start"><UnpublishedIcon color="error"/></Tooltip>
              : params.row.already_imported === false 
                ? <Tooltip title="match" placement="top-start"><CheckCircleIcon color="success"/></Tooltip>
                : <Tooltip title="previously match" placement="top-start"><CheckCircleOutlineIcon color="success"/></Tooltip>
            }
          </span>
          <DataGridSelectCustom
            {...params}  
            read={true}
            write={true}
            control={false}
            processRowUpdate={processRowUpdateCRDasboard}

            keepCellEditMode={true}
            error={params.value == 0 ? true : false}
            infos={params.value != 0 && (params.row.already_imported !== false && params.row.stevedore_id != params.row.stevedore_xls_id) ? true : false}
            helperText={(params.value == 0 || (params.row.already_imported !== false && params.row.stevedore_id != params.row.stevedore_xls_id)) && params.row.stevedore_value != '' 
              ? params.row.stevedore_value 
              : null
            }
          />
        </div>
        )
      }
    }
  );
  //add dynamic data
  for (const [field, headerName] of Object.entries(dataHeaderName)) {
    listColumnCRDashboard.push(
      {field: field, headerName: headerName, sortable: true, type: 'number', width: 200, editable: false}
    );
  }
  const columnsCRDashboard:GridColDef[] = listColumnCRDashboard;

  //xGrid - Damage Type
  var listColumnCRDamageType = [];
  listColumnCRDamageType.push(
    {field: 'xls', headerName: 'Xls', align: 'center', sortable: true, width: 50, editable: false, pinnable: true},
    {
      field: 'receiver_id', headerName: 'Receiver', sortable: false, type: 'singleSelect', width: 150, editable: true, pinnable: true,
      valueOptions: rowsReceiversDataGrid,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
        <div className={
          (params.value == 0 || (params.row.already_imported !== false && params.row.receiver_id != params.row.receiver_xls_id)) && params.row.receiver_value != '' 
          ? 'importDataStateCustom error' : 'importDataStateCustom'
        }>
          <span>
            {params.value == 0 
              ? params.row.receiver_value == ''
                ? <Tooltip title="no data" placement="top-start"><WarningIcon color="error"/></Tooltip>
                : <Tooltip title="no match" placement="top-start"><UnpublishedIcon color="error"/></Tooltip>
              : params.row.already_imported === false 
                ? <Tooltip title="match" placement="top-start"><CheckCircleIcon color="success"/></Tooltip>
                : <Tooltip title="previously match" placement="top-start"><CheckCircleOutlineIcon color="success"/></Tooltip>
            }
          </span>
          <DataGridSelectCustom
            {...params}  
            read={true}
            write={true}
            control={false}
            processRowUpdate={processRowUpdateCRDamageType}

            keepCellEditMode={true}
            error={params.value == 0 ? true : false}
            infos={params.value != 0 && (params.row.already_imported !== false && params.row.receiver_id != params.row.receiver_xls_id) ? true : false}
            helperText={(params.value == 0 || (params.row.already_imported !== false && params.row.receiver_id != params.row.receiver_xls_id)) && params.row.receiver_value != '' 
              ? params.row.receiver_value 
              : null
            }
          />
        </div>
        )
      }
    },
    {
      field: 'lot_id', headerName: 'Lot', sortable: false, type: 'singleSelect', width: 150, editable: true, pinnable: true,
      valueOptions: ({ row }) => {
        return !row || row.receiver_id === 0 ? [] : loadReceiverLots(row.receiver_id, row.id, 'damageType');
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
        <div className={
          (params.value == 0 || (params.row.already_imported !== false && params.row.lot_id != params.row.lot_xls_id)) && params.row.lot_value != '' 
          ? 'importDataStateCustom error' : 'importDataStateCustom'
        }>
          <span>
            {params.value == 0 
              ? params.row.lot_value == ''
                ? <Tooltip title="no data" placement="top-start"><WarningIcon color="error"/></Tooltip>
                : <Tooltip title="no match" placement="top-start"><UnpublishedIcon color="error"/></Tooltip>
              : params.row.already_imported === false 
                ? <Tooltip title="match" placement="top-start"><CheckCircleIcon color="success"/></Tooltip>
                : <Tooltip title="previously match" placement="top-start"><CheckCircleOutlineIcon color="success"/></Tooltip>
            }
          </span>
          <DataGridSelectCustom
            {...params}  
            read={true}
            write={true}
            control={false}
            processRowUpdate={processRowUpdateCRDamageType}

            keepCellEditMode={true}
            error={params.value == 0 ? true : false}
            infos={params.value != 0 && (params.row.already_imported !== false && params.row.lot_id != params.row.lot_xls_id) ? true : false}
            helperText={(params.value == 0 || (params.row.already_imported !== false && params.row.lot_id != params.row.lot_xls_id)) && params.row.lot_value != '' 
              ? params.row.lot_value 
              : null
            }
          />
        </div>
        )
      }
    },
    {
      field: 'stevedore_id', headerName: 'Stevedore', sortable: false, type: 'singleSelect', width: 150, editable: true, pinnable: true,
      valueOptions: ({ row }) => {
        return !row || row.receiver_id === 0 || row.lot_id === 0 ? [] : loadReceiverLotStevedore(row.receiver_id, row.lot_id);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
        <div className={
          (params.value == 0 || (params.row.already_imported !== false && params.row.stevedore_id != params.row.stevedore_xls_id)) && params.row.stevedore_value != '' 
          ? 'importDataStateCustom error' : 'importDataStateCustom'
        }>
          <span>
            {params.value == 0 
              ? params.row.stevedore_value == '' 
                ? <Tooltip title="no data" placement="top-start"><WarningIcon color="error"/></Tooltip>
                : <Tooltip title="no match" placement="top-start"><UnpublishedIcon color="error"/></Tooltip>
              : params.row.already_imported === false 
                ? <Tooltip title="match" placement="top-start"><CheckCircleIcon color="success"/></Tooltip>
                : <Tooltip title="previously match" placement="top-start"><CheckCircleOutlineIcon color="success"/></Tooltip>
            }
          </span>
          <DataGridSelectCustom
            {...params}  
            read={true}
            write={true}
            control={false}
            processRowUpdate={processRowUpdateCRDamageType}

            keepCellEditMode={true}
            error={params.value == 0 ? true : false}
            infos={params.value != 0 && (params.row.already_imported !== false && params.row.stevedore_id != params.row.stevedore_xls_id) ? true : false}
            helperText={(params.value == 0 || (params.row.already_imported !== false && params.row.stevedore_id != params.row.stevedore_xls_id)) && params.row.stevedore_value != '' 
              ? params.row.stevedore_value 
              : null
            }
          />
        </div>
        )
      }
    },
    {
      field: 'type_of_damage_id', headerName: 'Type of damage', sortable: false, type: 'singleSelect', flex: 1, editable: false,
      valueOptions: listDamageType,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        return option ? option.label : '';
      }
    },
    {field: 'percent_of_loss', headerName: '% of loss', sortable: true, type: 'number', flex: 1, editable: false,
      valueFormatter: (params) => {
        return `${params.value} %`;
      }
    }
  );
  const columnsCRDamageType: GridColDef[] = listColumnCRDamageType;


  if (Object.keys(importCRData).length > 0) {
    return (
    <div id="blockImportCR">
      <div className="lineContent separator">
        Dashboard
      </div>
      <div className="lineContent">
        <div className="datagridIconLegend">
          <span>
            <CheckCircleIcon color="success" fontSize="small"/> match
          </span>
          <span>
            <CheckCircleOutlineIcon color="success" fontSize="small"/> previously match
          </span>
          <span>
            <UnpublishedIcon color="error" fontSize="small"/> no match
          </span>
          <span>
            <WarningIcon color="error" fontSize="small"/> no data
          </span>
        </div>
        <FormControl className="datagridImportCR" size="small" id="datagrid-importcr-dashboard">
          <DataGridPro
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            hideFooter={true}

            //config specific import
            apiRef={apiRefDashboard}
            getRowHeight={() => 70}
            initialState={{ pinnedColumns: { left: ['xls', 'receiver_id', 'lot_id', 'stevedore_id'] } }}
            onCellDoubleClick={handleDoubleCellClick}
            onCellFocusOut={handleCellFocusOut}
            onCellKeyDown={handleCellKeyDown}
            getRowClassName={(params) => {
              var className = '';
              if (params.row.receiver_id == 0 || params.row.lot_id == 0 || params.row.stevedore_id == 0) {
                className = 'dataGridRowMissionCanceled';
              }
              return className;
            }}

            rows={rowsCRDashboard}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdateCRDasboard}
            columns={columnsCRDashboard}
            experimentalFeatures={{ newEditingApi: true }}
            componentsProps={{
              toolbar: { setRowsCRDashboard }
            }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay
            }}
          />
        </FormControl>
      </div>

      <div className="lineContent">
        Average Loss
      </div>
      <div className="lineContent">
        <FormControl className="datagridImportCR" size="small" id="datagrid-importcr-damagetype">
          <DataGridPro
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            hideFooter={true}

            //config specific import
            apiRef={apiRefDamageType}
            getRowHeight={() => 70}
            initialState={{ pinnedColumns: { left: ['xls', 'receiver_id', 'lot_id', 'stevedore_id'] } }}
            onCellDoubleClick={handleDoubleCellClick}
            onCellFocusOut={handleCellFocusOut}
            onCellKeyDown={handleCellKeyDown}
            getRowClassName={(params) => {
              var className = '';
              if (params.row.receiver_id == 0 || params.row.lot_id == 0 || params.row.stevedore_id == 0) {
                className = 'dataGridRowMissionCanceled';
              }
              return className;
            }}

            rows={rowsCRDamageType}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdateCRDamageType}
            columns={columnsCRDamageType}
            experimentalFeatures={{ newEditingApi: true }}
            componentsProps={{
              toolbar: { setRowsCRDamageType }
            }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay 
            }}
          />
        </FormControl>
      </div>

      <MissionDetailsFormSaveButton blockCancel={async ()=> await handleClickCancel()} blockSave={async ()=> await handleClickSave()} notWorkflowSaveButton={true}/>
    </div>
    );
  }
};
