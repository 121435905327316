import React, { useState, createContext, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { ContextMissionDetails } from "./ContextMissionDetails";
import { ContextMissionWorkflow } from "./ContextMissionWorkflow";

import axios from 'api/axios';


const ContextMissionDetailsTreatment = createContext();

const MissionDetailsTreatmentProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const {update} = useContext(ContextMissionDetails);
  const { getMissionDetail } = useContext(ContextMissionDetails);

  //API URL
  const GETMISSION = 'mission';

  //composant form ref
  const contentRef = useContext(ContextMissionDetails);
  
  //init param
  const {loading, setLoading} = useContext(ContextMissionDetails);
  const {loadingUpdate, setLoadingUpdate} = useContext(ContextMissionDetails);

  const {openSnackBar, setOpenSnackBar} = useContext(ContextMissionDetails);
  const {snackBarMessage, setSnackBarMessage} = useContext(ContextMissionDetails);
  const {snackBarType, setSnackBarType} = useContext(ContextMissionDetails);

  const [blockOnSave, setBlockOnSave] = useState(false);
  const [blockOrderSave, setBlockOrderSave] = useState(0);
  const {blockEdit, setBlockEdit} = useContext(ContextMissionDetails);
  const {blockSave, setBlockSave} = useContext(ContextMissionDetails);
  const {setCancelEdit} = useContext(ContextMissionDetails);
  const {setCancelBlockEdit} = useContext(ContextMissionDetails);
  const {setActiveBlockEdit} = useContext(ContextMissionDetails);

  //workflow state
  const { canWrite } = useContext(ContextMissionWorkflow);
  const {controlBasicFieldsData} = useContext(ContextMissionWorkflow);
  const {controlFieldsErrorTruncate} = useContext(ContextMissionWorkflow);
  const {workflowLoaded, setWorkflowLoaded} = useContext(ContextMissionWorkflow);
  const {checkWorkflowData, setCheckWorkflowData} = useContext(ContextMissionWorkflow);

  //init mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  const {missionType, setMissionType} = useContext(ContextMissionDetails);
  const {portId, setPortId} = useContext(ContextMissionDetails);
  const {surveyHandler, setSurveyHandler} = useContext(ContextMissionDetails);
  const {principalsId, setPrincipalsId} = useContext(ContextMissionDetails);

  const {internalComment, setInternalComment} = useContext(ContextMissionDetails);
  const {publicComment, setPublicComment} = useContext(ContextMissionDetails);
  const {photosComment, setPhotosComment} = useContext(ContextMissionDetails);

  //init data save
  const [reloadMission, setReloadMission] = useState(false);
  const [saveIsInError, setSaveIsInError] = useState(false);
  const {saveIsFinished, setSaveIsFinished} = useContext(ContextMissionDetails);
  const {idButtonWorkflow, setIdButtonWorkflow} = useContext(ContextMissionWorkflow);

  //init data cancelation
  const [missionCancelReason, setMissionCancelReason] = useState(0);
  const [missionCancelComment, setMissionCancelComment] = useState('');

  //handle button
  const handleClickBlockEdit = (blockRef) => {
    setActiveBlockEdit(blockRef);
  }
  const handleClickBlockCancel = (blockRef, reload = true) => {
    setCancelBlockEdit(blockRef, reload);
  }
  const handleClickCancel = async (reload = true) => {
    await controlFieldsErrorTruncate();
    setCancelEdit(reload);
  }
  const handleClickSave = (reload = false, button = null) => {
    setLoading(true);
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    if ((missionType == "0") || (portId == "0") || (surveyHandler == "0") || (principalsId == "0")) {
      var basicData = new FormData();
      //REQUIRED
      basicData.append('id_mission_type', parseInt(missionType));
      basicData.append('id_port', parseInt(portId));
      basicData.append('id_principal', parseInt(principalsId));
      basicData.append('id_survey_handler', parseInt(surveyHandler));
      //CHECK BASIC FIELD
      controlBasicFieldsData(basicData);
      contentRef.contentRef.current.scrollIntoView({behavior: 'smooth'});
      setLoading(false);
    } else {
      setStartSave();
      setReloadMission(reload);
      setIdButtonWorkflow(button);
    }
  }

  //INIT FUNC SAVE
  const setResetSave = () => {
    //reset save for all
    var blocks = blockSave.map((block) => {
      block = {...block, 'onSave': {'start' : false, 'done' : false, 'error' : false}};
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
    setBlockOnSave(false);
    setBlockOrderSave(0);
  }
  const setResetBlockSave = (blockRef) => {
    //reset save for this blockRef
    var blocks = blockSave.map((block) => {
      if (block.blockRef === blockRef) block = {...block, 'onSave': {'start' : false, 'done' : false, 'error' : false}};
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }
  const setStartSave = () => {
    //reset save for all
    var blocks = blockSave.map((block) => {
      block = {...block, 'onSave': {'start' : true, 'done' : false, 'error' : false}};
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
    setBlockOnSave(true);
    setSaveIsInError(false);
    setSaveIsFinished(false);

    setLoading(true);
    setLoadingUpdate(false);
  }
  const setStartBlockSave = (blockRef) => {
    //start save for this blockRef
    var blocks = blockSave.map((block) => {
      if (block.blockRef === blockRef) block = {...block, 'onSave': {'start' : true, 'done' : false, 'error' : false}};
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }
  const setDoneBlockSave = (blockRef) => {
    //done save for this blockRef
    var blocks = blockSave.map((block) => {
      if (block.blockRef === blockRef) {
        block = {...block, 'onSave': {'start' : true, 'done' : true, 'error' : false}};
        setBlockOrderSave(block.order + 1);
      }
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }
  const setDoneBlockMultiSave = (blockRefs = []) => {
    //done save for this multi blockRefs
    var blocks = blockSave.map((block) => {
      var blockOnSave  = blockRefs.findIndex(obj => (obj === block.blockRef));
      if (blockOnSave >= 0) {
        block = {...block, 'onSave': {'start' : true, 'done' : true, 'error' : false}};
      }
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }
  const setErrorBlockSave = (blockRef) => {
    //done save for this blockRef
    var blocks = blockSave.map((block) => {
      if (block.blockRef === blockRef) block = {...block, 'onSave': {'start' : true, 'done' : false, 'error' : true}};
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }


  //useEffect
  //init save mission
  useEffect(() => {
    async function checkBlockSaveInProgress() {
      if (blockOnSave && !saveIsFinished) {
        var saveInProgress = false;
        //validation workflow
        if (!!idButtonWorkflow != false) {
          saveInProgress = true;
        } else {
          //general save then must have one block on edit
          blockSave.map((block) => {
            if (block.onSave.start === true) {
              if (blockEdit.findIndex(obj => (obj.blockRef === block.blockRef && obj.onEdit === true)) >= 0) {
                saveInProgress = true;
              }
            }
          });
        }
        if (saveInProgress === true) {
          //check if save of one block is in error
          var blockIsInError = blockSave.findIndex(obj => (obj.onSave.start === true && obj.onSave.error === true));
          var saveIsInError  = blockIsInError >= 0 ? true : false;
          if (!saveIsInError) {
            //check if save is done for all block
            var saveIsDone = true;
            blockSave.map((block) => {
              //if block is available during creation (or update)
              if (update === 1 || (update !== 1 && block.allowCreation === true)) {
                saveIsDone = saveIsDone && (block.onSave.start === true && block.onSave.done === true);
              }
            });
            if (saveIsDone) {
              setSaveIsFinished(true);
            }
          } else {
            setSaveIsInError(true);
          }
        }
      }
    }
    checkBlockSaveInProgress();
  }, [blockSave, blockOnSave]);

  useEffect(() => {
    if (saveIsFinished) {
      async function checkBlockSaveFinished() {  
        //FINALISATION
        setResetSave();
  
        //redirection if create mission
        if (!(update == 1) && idMission != 0) {
          navigate('/missiondetails?id='+idMission+'&actionSave=1', { replace: reloadMission });
        }
  
        setSnackBarMessage("Mission saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        if (update == 1) {
          await getMissionDetail();
          setCancelEdit(false);
        }
        setLoading(false);
        setLoadingUpdate(true);
      }
      checkBlockSaveFinished();
    }
  }, [saveIsFinished]);

  useEffect(() => {
    if (saveIsInError) {
      async function checkBlockSaveInError() {
        //FINALISATION
        setResetSave();
  
        //redirection if create mission
        if (!(update == 1) && idMission != 0) {
          navigate('/missiondetails?id='+idMission+'&actionSave=0', { replace: reloadMission });
        }

        setSnackBarMessage("Problem saving mission.");
        setSnackBarType("error");
        setOpenSnackBar(true);
        //init false not valid workflow
        setCheckWorkflowData(false);
        //reload data api
        if (update == 1) {
          await getMissionDetail();
          setCancelEdit(false);
        }
        setLoading(false);
        setLoadingUpdate(true);
      }
      checkBlockSaveInError();
    }
  }, [saveIsInError]);


  //function treatment
  //block save
  async function saveBlockAndCheckWorkflow(blockRef, blockFuncSave, blockFuncCheck = null) {
    var blockIndex = blockSave.findIndex(obj => obj.blockRef === blockRef);
    if (blockIndex >= 0) {
      var blockOnSave = blockSave[blockIndex];
      if (blockOnSave.onSave.start === true && blockOnSave.onSave.done === false && blockOnSave.onSave.error === false) {
        if (blockOnSave.order == blockOrderSave) {
          var blockMultiRefs  = [blockRef];
          var blockOnEditSave = blockEdit.findIndex(obj => (obj.blockRef === blockRef && obj.onEdit === true));
          if (blockOnEditSave >= 0) {
            await blockFuncSave();
          } else if (!!idButtonWorkflow != false && blockFuncCheck != null) {
            await blockFuncCheck();
            setDoneBlockSave(blockRef);
          } else {
            setDoneBlockSave(blockRef);
          }

          //jump to next block if the follow is not available at this step of workflow
          if (workflowLoaded) {
            var blockCurrent = blockOnSave;
            do {
              var blockAvailable = true;
              var blockNextOrder = blockSave.findIndex(obj => obj.order === (blockCurrent.order + 1));
              if (blockNextOrder >= 0) {
                blockCurrent = blockSave[blockNextOrder];
                if (!!blockCurrent.workflowLabel !== false) {
                  if (typeof blockCurrent.workflowLabel === 'string') {
                    blockAvailable = Boolean(canWrite(blockCurrent.workflowLabel));
                  } else {
                    var blockIsWrite = blockCurrent.workflowLabel.findIndex(label => canWrite(label));
                    blockAvailable = blockIsWrite >= 0 ? true : false;
                  }
                }
                //if not available, we jump
                if (!blockAvailable) {
                  setDoneBlockSave(blockCurrent.blockRef);
                  blockMultiRefs.push(blockCurrent.blockRef);
                }
              }
            } while (!blockAvailable);
          }

          //done multi block if necessary
          if (blockMultiRefs.length > 1) {
            setDoneBlockMultiSave(blockMultiRefs);
          }
        }
      }
    }
  }
  
  // Cancel mission
  const saveMissionCancel = async (uncancel = false) => {
    setLoading(true);
    setLoadingUpdate(false);
    try {
      var data = new FormData();
      data.append('id_mission', idMission);
      
      if (!uncancel) {
        data.append('id_mission_cancel_reason', missionCancelReason);
        data.append('comment', missionCancelComment);
      } else {
        data.append('uncancel', uncancel);
      }

      var jsonRequestData = JSON.stringify(Object.fromEntries(data));
      var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, 'null');    //PATCH ERREUR NULL
      var jsonRequestDataFinal = jsonRequestDataFinal.replace(/"true"/g, true);    //PATCH ERREUR NULL
      var jsonRequestDataFinal = jsonRequestDataFinal.replace(/"false"/g, false);    //PATCH ERREUR NULL

      var config = {
        url: 'mission/cancel',
        method: 'put',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestDataFinal
      };
      const response = await axios(config);

      setSnackBarMessage("Mission successfully "+ (uncancel ? 'un' : '') +"canceled.");
      setSnackBarType("success");
      setOpenSnackBar(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoading(false);
      setLoadingUpdate(true);
    }
  }


  return (
    <ContextMissionDetailsTreatment.Provider
      value=
      {{
        //handle
        handleClickBlockEdit, handleClickBlockCancel, handleClickCancel, handleClickSave,

        //treament
        blockSave, setBlockSave,
        blockOrderSave, setBlockOrderSave,
        setResetBlockSave, setStartBlockSave, setDoneBlockSave, setErrorBlockSave,
        saveBlockAndCheckWorkflow,

        missionCancelReason, setMissionCancelReason,
        missionCancelComment, setMissionCancelComment,
        saveMissionCancel
      }}
    >
      {children}
    </ContextMissionDetailsTreatment.Provider>
  );
};

export { MissionDetailsTreatmentProvider, ContextMissionDetailsTreatment };
