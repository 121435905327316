import React, { useEffect, useState, forwardRef, useContext } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';

import SelectCustom from 'components/select/SelectCustom';
import { isCustomerRole } from 'components/config/Roles';
import { DataGridPro } from 'components/page/object/DataGridProCustom';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";
import { ContextMissionDetailsInvoice } from "./ContextMissionDetailsInvoice";


const MissionDetailsInvoiceForm = forwardRef((props, ref) => {

  //init config
  const {update} = useContext(ContextMissionDetails);
  const {invoiceRef} = useContext(ContextMissionDetails);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextMissionDetails);
  
  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);

  //mission
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  // Invoices Grid
  const {rowsInvoiceView, setRowsInvoiceView} = useContext(ContextMissionDetailsInvoice);
  const {rowsReceiversInvoice, setRowsReceiversInvoice} = useContext(ContextMissionDetails);
  const { invoicer, setInvoicer } = useContext(ContextMissionDetails);

  const {loadingInvoiceTypes} = useContext(ContextMissionDetailsInvoice);
  const {invoiceTypes, setInvoiceTypes} = useContext(ContextMissionDetailsInvoice);

  //context
  const { CustomNoRowsOverlay } = useContext(ContextMissionDetails);

  //handle
  const {handleClickBlockEdit} = useContext(ContextMissionDetailsTreatment);

  //function interactive
  const {createInvoicersHandler} = useContext(ContextMissionDetailsInvoice);

  //useEffect
  useEffect(() => {
    if (invoiceTypes.length <= 0) {
      loadingInvoiceTypes();
    }
  }, []);


  //xGrid
  const columnsInvoices : GridColDef[] = [
    { field: 'id_receiver', headerName: 'Receiver', editable: false, sortable: true, width:200,
      valueFormatter: params => {
        return !!params?.value != false 
          ? rowsReceiversInvoice[(rowsReceiversInvoice.findIndex(obj => obj.value === (typeof params?.value == 'object' ? params?.value.id : Number(params?.value))))]?.label 
          : "-"
    }},
    { field: 'invoice_type', headerName: 'Invoice Type', editable: false, sortable: true, flex:1,
      valueFormatter: params => {
        return !!params?.value != false 
          ? invoiceTypes[(invoiceTypes.findIndex(obj => obj.value === (typeof params?.value == 'object' ? params?.value.id : Number(params?.value))))]?.label 
          : "-"
    }},
    { field: 'report_number', headerName: 'Report Number', editable: false, sortable: true, flex:1 },
    { field: 'report_signature_date', headerName: 'Report Signature Date', editable: false, sortable: true, flex:1, type: 'date' },
    { field: 'number', headerName: 'JLB Invoice Number', editable: false, sortable: true, flex:1, type: 'number'  },
    { field: 'date', headerName: 'JLB Invoice Date', editable: false, sortable: true, flex:1, type: 'date' },
    { field: 'invoice_sent_date', headerName: 'Sent Date', editable: false, sortable: true, flex:1, type: 'date' },
    { field: 'paid_on', headerName: 'Paid On', editable: false, sortable: true, flex:1, type: 'date' },
  ];


  return (
    <div>
      <div className="lineContent">
        <SelectCustom
          title="Invoiced by"
          api="company/list"
          apiParam="company_role"
          apiParamValue="Invoice"
          dataValue={invoicer}
          setDataValue={setInvoicer}
          defaultAlias=""
          creator={createInvoicersHandler}
          read={canRead("invoiced_by")}
          write={false}
          controlFieldsStatus={true}
        />
      </div>
      <div className="lineContent">
        <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-invoice">
          <DataGridPro
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            autoHeight
            hideFooter={true}
            rows={rowsInvoiceView}
            columns={columnsInvoices}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </FormControl>
      </div>

      {(update == 1 && loadingUpdate && !missionCanceled && !JLBCustomerMode) && 
      <div className="block_button">
        <LoadingButton className="edit" onClick={()=>handleClickBlockEdit(invoiceRef)} loadingPosition="start" startIcon={<EditIcon/>}> Edit</LoadingButton>
      </div>
      }
    </div>
  );
});

export default MissionDetailsInvoiceForm;
