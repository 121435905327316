import React, { useEffect, SyntheticEvent, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGridPro, GridToolbarContainer, DataGridSelectCustom } from 'components/page/object/DataGridProCustom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { randomId } from '@mui/x-data-grid-generator';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { isInternalRole, isPortRole } from 'components/config/Roles';
import { format2Decimals } from 'components/page/object/Utils';
import SelectCustom from 'components/select/SelectCustom';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import TextFieldCustom from 'components/select/TextFieldCustom';

import { ContextMissionDetails } from "context/ContextMissionDetails";

import axios from 'api/axios';

import 'styles/Receivers.css';


export default function MissionDetailsReceiverFormLot(props) {

  //config
  const navigate = useNavigate();

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextMissionDetails);
  const { snackBarType, setSnackBarType } = useContext(ContextMissionDetails);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextMissionDetails);

  const [lotData, setLotData] = React.useState([]);
  const [receiver, setReceiver] = React.useState([]);
  const [rowsBL, setRowsBL] = React.useState([]);
  const [rowsCompaniesStevedore, setRowsCompaniesStevedore] = React.useState([]);
  //new param for default extand
  const [expanded, setExpanded] = React.useState((!!props.lot.isExpanded != false ? props.lot.isExpanded : props.isExpanded));
  const [expandedByError, setExpandedByError] = React.useState(false);

  const [JLBInternalMode] = React.useState(isInternalRole(localStorage.getItem("role")));
  const [PortMode] = React.useState(isPortRole(localStorage.getItem("role")));

  //function interactive
  const { companySelectRefresh } = useContext(ContextMissionDetails);


  useEffect(() => {
    setLotData(props.lot);
  }, [props.lot]);

  useEffect(() => {
    setLotData(props.lot);
  }, [props.controlFieldsError]);

  useEffect(() => {
    setReceiver(props.receiver);
  }, [props.receiver]);

  // Add new BL from BLForm to options 
  useEffect(() => {
    if (props.newRowsBL?.length > 0) {
      const newElements = props.newRowsBL.filter(element => !rowsBL.some(row => row.id === element.id));
      setRowsBL(oldRowsBl => [...oldRowsBl, ...newElements.map(element => ({ id: element.id, value: element.id, label: element.bol }))]);
    }
  }, [props.newRowsBL]);

  // Delete BL from options and from API
  useEffect(() => {
    if (props.deletedRowsBL?.length > 0) {
      // Delete from select options
      setRowsBL(oldRowsBl => oldRowsBl.filter(row => !props.deletedRowsBL.some(deletedRow => deletedRow.id === row.id)));
      props.deletedRowsBL.map((bl) => removeBL(null, bl.id));
    }
  }, [props.deletedRowsBL]);

  useEffect(() => {
    if (lotData && receiver) {
      if ((props.getControlFieldsErrorState("id_loading_type" + lotData.id)) ||
        props.getControlFieldsErrorState("id_packaging" + lotData.id) ||
        props.getControlFieldsErrorState("packaging_quantity" + lotData.id) ||
        props.getControlFieldsErrorState("net_tonnage" + lotData.id) ||
        props.getControlFieldsErrorState("gross_tonnage" + lotData.id) ||
        props.getControlFieldsErrorState("id_cargo_brand" + lotData.id) ||
        props.getControlFieldsErrorState("id_cargo_origin" + lotData.id) ||
        props.getControlFieldsErrorState("id_cargo_quality" + lotData.id) ||
        props.getControlFieldsErrorState("id_cargo_variety" + lotData.id) ||
        props.getControlFieldsErrorState("number_of_container" + lotData.id) ||
        props.getControlFieldsErrorState("container_type" + lotData.id) ||
        props.getControlFieldsErrorState("empty_spare_packaging_percentage" + lotData.id)
      ) {
        if (!props.expandedReceivers.includes(receiver.id)) {
          props.expandParent((prevExpandedReceivers) => [...prevExpandedReceivers, receiver.id]);
        }
        setExpandedByError(true);
      }
    }
  }, [lotData, receiver, props.getControlFieldsErrorState]);

  useEffect(() => {
    if (!expanded && expandedByError) {
      setExpanded(true);
    }
  }, [expandedByError]);

  useEffect(() => {
    setReceiver(props.receiver);
    prepareBlRows();
    loadCompaniesStevedore();
  }, []);

  const loadCompaniesStevedore = async () => {
    try {
      var config = {
        url: 'company/list',
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        params: {
          'company_role': 'Stevedore'
        }
      };

      const response = await axios(config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = {
          id: response.data[i].id,
          value: response.data[i].id,
          label: response.data[i].name
        };
      }
      rowsFromApi.sort((a, b) => (a.label > b.label) ? 1 : -1);
      setRowsCompaniesStevedore(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  function createLoadingType(data) {
    return {
      id: data.id, title: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim(),
      has_packaging: data.has_packaging
    };
  }
  function createBrand(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), labelValue: data.name.toUpperCase().trim() };
  }
  function createPackaging(data) {
    return { id: data.id, title: data.size.toUpperCase().trim(), alias: data.size, labelValue: data.size.toUpperCase().trim(), net_weight: data.net_weight };
  }
  function createOrigin(data) {
    return { id: data.id, title: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim() };
  }
  function createQuality(data) {
    return { id: data.id, title: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim() };
  }
  function createVariety(data) {
    return { id: data.id, title: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim() };
  }
  function createContainerType(data) {
    return { id: data.id, title: data.value, alias: data.value, labelValue: data.value };
  }
  function prepareBlRows() {
    if (!!props.rowsBL != false)
      setRowsBL(props.rowsBL.map((row) => { return { id: row.id, value: row.id, label: row.bol } }));
  }

  const loadingTypeChangeHandler = (id, label, target, data) => {
    var has_packaging = (typeof data != "undefined" && data != null) ? data.has_packaging : "";
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, loading_type: { id: id, label: label, has_packaging: has_packaging } });
  }
  const brandChangeHandler = (id, label, target, data) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, brand: { id: id, label: label } });
  }
  const packagingChangeHandler = (id, label, target, data) => {
    var netWeight = (typeof data != "undefined" && data != null) ? data.net_weight : "";
    var assisted_net_tonnage;
    if (lotData.packaging_quantity > 0 && netWeight > 0) {
      assisted_net_tonnage = format2Decimals((lotData.packaging_quantity * netWeight) / 1000);
    } else {
      assisted_net_tonnage = lotData?.net_tonnage
    }
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, net_tonnage: assisted_net_tonnage, packaging: { id: id, label: label, net_weight: netWeight } });
  }
  const quantityChangeHandler = (event) => {
    var assisted_net_tonnage;
    if (event.target.value > 0 && lotData.packaging?.net_weight > 0) {
      assisted_net_tonnage = format2Decimals((event.target.value * lotData.packaging.net_weight) / 1000);
    } else {
      assisted_net_tonnage = lotData?.net_tonnage
    }
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, net_tonnage: assisted_net_tonnage, packaging_quantity: event.target.value });
  }
  const netTonnageChangeHandler = (event) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, net_tonnage: event.target.value });
  }
  const grossTonnageChangeHandler = (event) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, gross_tonnage: event.target.value });
  }
  const originChangeHandler = (id, label, target, data) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, origin: { id: id, label: label } });
  }
  const qualityChangeHandler = (id, label, target, data) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, quality: { id: id, label: label } });
  }
  const varietyChangeHandler = (id, label, target, data) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, variety: { id: id, label: label } });
  }
  const sparePercentageChangeHandler = (event) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, empty_spare_packaging_percentage: event.target.value });
  }
  const blChangeHandler = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    updatedRow.id = updatedRow.number;
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, bills_of_lading: props.lot.bills_of_lading.map((row) => (row.id === newRow.id ? updatedRow : row)) });
    return updatedRow;
  }
  const companyChangeHandler = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    updatedRow.id = updatedRow.name;
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, companies: props.lot.companies.map((row) => (row.id === newRow.id ? updatedRow : row)) });
    return updatedRow;
  }
  const containerTypeChangeHandler = (id, label, target, data) => {
    var container = typeof label == undefined ? 0 : label;
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, container_type: container });
  }
  const quantityOfPackingPerContainer = (event) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, quantity_of_packing_per_container: event.target.value });
  }
  const numberOfContainerChangeHandler = (event) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, number_of_container: event.target.value });
  }

  const removeBL = (event, id) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, bills_of_lading: props.lot.bills_of_lading.filter((row) => row.id !== id) })
  };

  const removeCompany = (event, id) => {
    props.updateLot(
      props.lot.id,
      props.receiver.id,
      { ...props.lot, companies: props.lot.companies.filter((row) => row.id !== id) })
  };

  function drawLoadingType() {
    return (
      <SelectCustom
        title="Type of Loading"
        api="cargo/loading_type/list"
        apiParam=""
        apiParamValue=""
        dataValue={lotData.loading_type ? lotData.loading_type.id : 0}
        setDataValue={loadingTypeChangeHandler}
        setDataAlias=""
        defaultAlias=""
        creator={createLoadingType}
        cached={true}

        read={props.canRead("id_loading_type")}
        write={props.canWrite("id_loading_type")}
        required={props.isRequired("id_loading_type")}
        controlFieldsStatus={props.workflowLoaded}
        error={props.getControlFieldsErrorState("id_loading_type" + lotData.id)}
        helperText={props.getControlFieldsErrorMsg("id_loading_type" + lotData.id)}

        isDuplicate={lotData.isDuplicate ? lotData.isDuplicate : false}
      />
    );
  }

  function drawPackaging() {
    if (lotData.loading_type && lotData.loading_type.has_packaging == 1) {
      return (
        <SelectCustom
          title="Packaging"
          api="cargo/packaging/list"
          apiParam="id_loading_type"
          apiParamValue={lotData.loading_type.id}
          dataValue={lotData.packaging ? lotData.packaging.id : 0}
          setDataValue={packagingChangeHandler}
          setDataAlias=""
          defaultAlias=""
          creator={createPackaging}
          enableAddButton={JLBInternalMode || PortMode}
          classType="addButtonSelect1"
          apiAddButton="cargo/packaging"
          field1={{ field: "size", label: "Size", required: true }}
          field2={{ field: "net_weight", label: "Net weight", type: "number", required: true }}
          field3={{ field: "id_loading_type", api: "cargo/loading_type/list", label: "Type of loading", initValues: lotData.loading_type.id, required: true }}
          cached={true}
          cacheTTL="600"
          resetCache="1"

          read={props.canRead("id_packaging")}
          write={props.canWrite("id_packaging")}
          required={props.isRequired("id_packaging")}
          controlFieldsStatus={props.workflowLoaded}
          error={props.getControlFieldsErrorState("id_packaging" + lotData.id)}
          helperText={props.getControlFieldsErrorMsg("id_packaging" + lotData.id)}
        />
      );
    }
  }

  function drawNetWeight() {
    if (lotData.loading_type && lotData.loading_type.has_packaging == 1) {
      var value = "";
      if (lotData.packaging)
        value = lotData.packaging.net_weight;
      return (
        <TextFieldCustom disabled={true}
          id={"net_weight_" + lotData.id}
          label="Net weight"
          variant="outlined"
          size="small"
          type="text"
          value={value + "kg"}

          read={props.canRead("net_weight")}
        />
      );
    }
  }

  function drawGrossWeight() {
    if (lotData.loading_type && lotData.loading_type.has_packaging == 1) {
      var gross_weight = 0;
      if (lotData.gross_tonnage && lotData.packaging_quantity) {
        gross_weight = format2Decimals((lotData.gross_tonnage / lotData.packaging_quantity) * 1000);
      }
      return (
        <TextFieldCustom disabled={true}
          id={"gross_weight_" + lotData.id}
          label="Gross weight"
          variant="outlined"
          size="small"
          type="text"
          value={gross_weight + "kg"}

          read={props.canRead("gross_weight")}
        />
      );
    }
  }

  function drawQuantity() {
    if (lotData.loading_type && lotData.loading_type.has_packaging == 1) {
      return (
        <TextFieldCustom
          id={"quantity_" + lotData.id}
          label="Quantity"
          variant="outlined"
          size="small"
          type="number"
          value={lotData.packaging_quantity ? lotData.packaging_quantity : ""}
          onChange={quantityChangeHandler}

          read={props.canRead("packaging_quantity")}
          write={props.canWrite("packaging_quantity")}
          required={props.isRequired("packaging_quantity")}
          controlFieldsStatus={props.workflowLoaded}
          error={props.getControlFieldsErrorState("packaging_quantity" + lotData.id)}
          helperText={props.getControlFieldsErrorMsg("packaging_quantity" + lotData.id)}
        />
      );
    }
  }

  function drawNetTonnage() {
    return (
      <TextFieldCustom
        id={"net_tonnage_" + lotData.id}
        label="Net tonnage"
        variant="outlined"
        size="small"
        type="decimal"
        value={lotData.net_tonnage ? lotData.net_tonnage : ""}
        onChange={netTonnageChangeHandler}

        read={props.canRead("net_tonnage")}
        write={props.canWrite("net_tonnage")}
        required={props.isRequired("net_tonnage")}
        controlFieldsStatus={props.workflowLoaded}
        error={props.getControlFieldsErrorState("net_tonnage" + lotData.id)}
        helperText={props.getControlFieldsErrorMsg("net_tonnage" + lotData.id)}
      />
    );
  }

  function drawGrossTonnage() {
    if (lotData.loading_type && lotData.loading_type.has_packaging == 1) {
      return (
        <TextFieldCustom
          id={"gross_tonnage_" + lotData.id}
          label="Gross tonnage"
          variant="outlined"
          size="small"
          type="decimal"
          value={lotData.gross_tonnage ? lotData.gross_tonnage : ""}
          onChange={grossTonnageChangeHandler}

          read={props.canRead("gross_tonnage")}
          write={props.canWrite("gross_tonnage")}
          required={props.isRequired("gross_tonnage")}
          controlFieldsStatus={props.workflowLoaded}
          error={props.getControlFieldsErrorState("gross_tonnage" + lotData.id)}
          helperText={props.getControlFieldsErrorMsg("gross_tonnage" + lotData.id)}
        />
      );
    }
  }

  function drawBrand() {
    if (lotData.loading_type && lotData.loading_type.has_packaging == 1) {
      if (receiver.cargo && receiver.cargo.id !== "0") {
        return (
          <SelectAutoCustom
            title="Brand"
            api="cargo/brand/list"
            apiParam="id_cargo"
            apiParamValue={receiver.cargo.id}
            dataValue={lotData.brand?.label || lotData.brand?.name || ""}
            setDataValue={brandChangeHandler}
            setDataAlias=""
            defaultAlias=""
            creator={createBrand}
            enableAddButton={JLBInternalMode || PortMode}
            classType="addButtonSelect2"
            apiAddButton="cargo/brand"
            field1={{ field: "name", label: "Name", required: true }}
            field3={{ field: "id_cargo_type", api: "cargo/list", label: "Cargo", initValues: receiver.cargo.id, required: true }}
            cached={true}
            cacheTTL="600"
            resetCache="1"

            read={props.canRead("id_cargo_brand")}
            write={props.canWrite("id_cargo_brand")}
            required={props.isRequired("id_cargo_brand")}
            controlFieldsStatus={props.workflowLoaded}
            error={props.getControlFieldsErrorState("id_cargo_brand" + lotData.id)}
            helperText={props.getControlFieldsErrorMsg("id_cargo_brand" + lotData.id)}
          />
        );
      }
    }
  }

  function drawOrigin() {
    return (
      <SelectCustom
        title="Origin"
        api="cargo/origin/list"
        apiParam=""
        apiParamValue=""
        dataValue={lotData.origin ? lotData.origin.id : 0}
        setDataValue={originChangeHandler}
        setDataAlias=""
        defaultAlias=""
        creator={createOrigin}
        enableAddButton
        classType="addButtonSelect1"
        apiAddButton="cargo/origin"
        field1={{ field: "label", label: "Label", required: true }}
        cached={true}
        cacheTTL="600"
        resetCache="1"

        read={props.canRead("id_cargo_origin")}
        write={props.canWrite("id_cargo_origin")}
        required={props.isRequired("id_cargo_origin")}
        controlFieldsStatus={props.workflowLoaded}
        error={props.getControlFieldsErrorState("id_cargo_origin" + lotData.id)}
        helperText={props.getControlFieldsErrorMsg("id_cargo_origin" + lotData.id)}
      />
    );
  }

  function drawQuality() {
    if (receiver.cargo && receiver.cargo.id !== "0") {
      return (
        <SelectCustom
          size="small"
          title="Quality"
          api="cargo/quality/list"
          apiParam="id_cargo"
          apiParamValue={receiver.cargo.id}
          dataValue={lotData.quality ? lotData.quality.id : 0}
          setDataValue={qualityChangeHandler}
          setDataAlias=""
          defaultAlias=""
          creator={createQuality}
          enableAddButton={JLBInternalMode || PortMode}
          classType="addButtonSelect1"
          apiAddButton="cargo/quality"
          field1={{ field: "label", label: "Label", required: true }}
          field3={{ field: "id_cargo_type", api: "cargo/list", label: "Cargo", initValues: receiver.cargo.id, required: true }}
          cached={true}
          cacheTTL="600"
          resetCache="1"

          read={props.canRead("id_cargo_quality")}
          write={props.canWrite("id_cargo_quality")}
          required={props.isRequired("id_cargo_quality")}
          controlFieldsStatus={props.workflowLoaded}
          error={props.getControlFieldsErrorState("id_cargo_quality" + lotData.id)}
          helperText={props.getControlFieldsErrorMsg("id_cargo_quality" + lotData.id)}
        />
      );
    }
  }

  function drawVariety() {
    if (receiver.cargo && receiver.cargo.id !== "0") {
      return (
        <SelectCustom
          size="small"
          title="Variety"
          api="cargo/variety/list"
          apiParam="id_cargo"
          apiParamValue={receiver.cargo.id}
          dataValue={lotData.variety ? lotData.variety.id : 0}
          setDataValue={varietyChangeHandler}
          setDataAlias=""
          defaultAlias=""
          creator={createVariety}
          enableAddButton={JLBInternalMode || PortMode}
          classType="addButtonSelect1"
          apiAddButton="cargo/variety"
          field1={{ field: "label", label: "Label", required: true }}
          field3={{ field: "id_cargo_type", api: "cargo/list", label: "Cargo", initValues: receiver.cargo.id, required: true }}
          cached={true}
          cacheTTL="600"
          resetCache="1"

          read={props.canRead("id_cargo_variety")}
          write={props.canWrite("id_cargo_variety")}
          required={props.isRequired("id_cargo_variety")}
          controlFieldsStatus={props.workflowLoaded}
          error={props.getControlFieldsErrorState("id_cargo_variety" + lotData.id)}
          helperText={props.getControlFieldsErrorMsg("id_cargo_variety" + lotData.id)}
        />
      );
    }
  }

  function drawNumberOfContainer() {
    /*if(lotData.loading_type && lotData.loading_type.has_packaging == 1)*/
    return (
      <TextFieldCustom
        id={"number_of_container_" + lotData.id}
        label="Number of containers"
        variant="outlined"
        size="small"
        type="number"
        value={lotData.number_of_container}
        onChange={numberOfContainerChangeHandler}

        read={props.canRead("number_of_container")}
        write={props.canWrite("number_of_container")}
        required={props.isRequired("number_of_container")}
        controlFieldsStatus={props.workflowLoaded}
        error={props.getControlFieldsErrorState("number_of_container" + lotData.id)}
        helperText={props.getControlFieldsErrorMsg("number_of_container" + lotData.id)}
      />
    );
  }

  function drawContainerType() {
    /*if(lotData.loading_type && lotData.loading_type.has_packaging == 1)*/
    var dataContainerType = [
      { id: '10"', value: '10"' },
      { id: '20"', value: '20"' },
      { id: '40"', value: '40"' }
    ];

    return (
      <SelectCustom
        title="Container type"
        dataValue={lotData.container_type ? lotData.container_type : 0}
        setDataValue={containerTypeChangeHandler}
        datas={dataContainerType}
        creator={createContainerType}

        read={props.canRead("container_type")}
        write={props.canWrite("container_type")}
        required={props.isRequired("container_type")}
        controlFieldsStatus={props.workflowLoaded}
        error={props.getControlFieldsErrorState("container_type" + lotData.id)}
        helperText={props.getControlFieldsErrorMsg("container_type" + lotData.id)}
      />
    );
  }

  function drawQuantityOfPackingPerContainer() {
    if (lotData.loading_type && lotData.loading_type.has_packaging == 1) {
      return (
        <TextFieldCustom
          id={"quantity_of_packing_per_container_" + lotData.id}
          label="Quantity of packing/container"
          variant="outlined"
          size="small"
          type="decimal"
          value={lotData.quantity_of_packing_per_container ? lotData.quantity_of_packing_per_container : ""}
          onChange={quantityOfPackingPerContainer}

          read={props.canRead("quantity_of_packing_per_container")}
          write={props.canWrite("quantity_of_packing_per_container")}
          required={props.isRequired("quantity_of_packing_per_container")}
          controlFieldsStatus={props.workflowLoaded}
          error={props.getControlFieldsErrorState("quantity_of_packing_per_container" + lotData.id)}
          helperText={props.getControlFieldsErrorMsg("quantity_of_packing_per_container" + lotData.id)}
        />
      );
    }
  }

  function drawSparePercentage() {
    if (lotData.loading_type && lotData.loading_type.has_packaging == 1) {
      return (
        <TextFieldCustom
          id={"empty_spare_packaging_percentage_" + lotData.id}
          label="Empty spare packaging (%)"
          variant="outlined"
          size="small"
          type="number"
          value={lotData.empty_spare_packaging_percentage}
          onChange={sparePercentageChangeHandler}

          read={props.canRead("empty_spare_packaging_percentage")}
          write={props.canWrite("empty_spare_packaging_percentage")}
          required={props.isRequired("empty_spare_packaging_percentage")}
          controlFieldsStatus={props.workflowLoaded}
          error={props.getControlFieldsErrorState("empty_spare_packaging_percentage" + lotData.id)}
          helperText={props.getControlFieldsErrorMsg("empty_spare_packaging_percentage" + lotData.id)}
        />
      );
    }
  }

  function drawSpareQuantity() {
    if (lotData.loading_type && lotData.loading_type.has_packaging == 1) {
      return (
        <TextFieldCustom disabled={true}
          id={"empty_spare_packaging_quantity_" + lotData.id}
          label="Empty spare packaging (Qty)"
          variant="outlined"
          size="small"
          type="text"
          value={format2Decimals(lotData.empty_spare_packaging_percentage * lotData.packaging_quantity / 100)}
          controlFieldsStatus={props.workflowLoaded}

          read={props.canRead("empty_spare_packaging_quantity")}
        />
      );
    }
  }

  function drawToolbarBl() {
    const handleClick = (event) => {
      const id = randomId();
      props.updateLot(
        props.lot.id,
        props.receiver.id,
        { ...props.lot, bills_of_lading: [...props.lot.bills_of_lading, { id: id, label: "" }] });
    };

    if (props.canWrite("bills_of_lading")) {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              Add Bill of lading
            </Button>
          </div>
        </GridToolbarContainer>
      );
    } else {
      return (
        <GridToolbarContainer>
          <div className="iconAddButton">
            Bill of lading
          </div>
        </GridToolbarContainer>
      );
    }
  }

  function drawToolbarCompanies() {
    const handleClick = (event) => {
      const id = randomId();
      props.updateLot(
        props.lot.id,
        props.receiver.id,
        { ...props.lot, companies: [...props.lot.companies, { id: id, label: "" }] });
    };

    if (props.canWrite("companies_stevedore")) {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              Add Company - Stevedore
            </Button>
          </div>
        </GridToolbarContainer>
      );
    } else {
      return (
        <GridToolbarContainer>
          <div className="iconAddButton">
            Company - Stevedore
          </div>
        </GridToolbarContainer>
      );
    }
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  function CustomNoRowsOverlay() {
    return (<div className="customNoRowsOverlay"></div>);
  }

  function drawBLs() {

    if (props.canWrite("bills_of_lading") || props.canRead("bills_of_lading")) {
      var listColumn = [];
      if (props.canWrite("bills_of_lading")) {
        listColumn.push({
          field: 'id', headerName: '', editable: false, sortable: false, width: 55,
          renderCell: (cellValues) => {
            return (
              <>
                <IconButton aria-label="delete" onClick={(event) => {
                  removeBL(event, cellValues.id);
                }}>
                  <DeleteIcon />
                </IconButton>
              </>
            );
          }
        });
      }
      listColumn.push({
        field: 'number', headerName: 'Number', sortable: false, type: "singleSelect", minWidth: 150, flex: 1,
        valueOptions: rowsBL,
        editable: (props.canWrite("bills_of_lading") ? true : false),
        valueFormatter: ({ id: rowId, value, field, api }) => {
          const colDef = api.getColumn(field);
          const option = colDef.valueOptions.find(
            ({ value: optionValue }) => value === optionValue
          );

          if (option) return option.label;
        },
        renderCell: (cellValues) => {
          if (props.canWrite("bills_of_lading") && (cellValues.value == "" || cellValues.value == undefined)) {
            return (
              <Tooltip title="Double click to edit" placement="top">
                <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
              </Tooltip>
            )
          }
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          return (
            <DataGridSelectCustom
              {...params}  
              read={props.canRead('bills_of_lading')}
              write={props.canWrite('bills_of_lading')}
              control={props.workflowLoaded}
              processRowUpdate={blChangeHandler}
            />
          )
        }
      });
      const columns: GridColDef[] = listColumn;

      return (
        <div>
          <DataGridPro className="datagridBL"
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            autoHeight
            density="compact"
            hideFooter={true}
            rows={props.lot.bills_of_lading}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={blChangeHandler}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              Toolbar: drawToolbarBl,
            }}
          />
        </div>
      );
    }
  }

  function drawCompanies() {

    if (props.canWrite("companies_stevedore") || props.canRead("companies_stevedore")) {
      var listColumn = [];
      if (props.canWrite("companies_stevedore")) {
        listColumn.push({
          field: 'id', headerName: '', editable: false, sortable: false, width: 55,
          renderCell: (cellValues) => {
            return (
              <>
                <IconButton aria-label="delete" onClick={(event) => {
                  removeCompany(event, cellValues.id);
                }}>
                  <DeleteIcon />
                </IconButton>
              </>
            );
          }
        });
      }
      listColumn.push({
        field: 'name', headerName: 'Name - Stevedore', sortable: false, type: "singleSelect", minWidth: 150, flex: 1,
        valueOptions: rowsCompaniesStevedore,
        editable: (props.canWrite("companies_stevedore") ? true : false),
        valueFormatter: ({ id: rowId, value, field, api }) => {
          const colDef = api.getColumn(field);
          const option = colDef.valueOptions.find(
            ({ value: optionValue }) => value === optionValue
          );

          if (option) return option.label;
        },
        renderCell: (cellValues) => {
          if (props.canWrite("companies_stevedore") && (cellValues.value == "" || cellValues.value == undefined)) {
            return (
              <Tooltip title="Double click to edit" placement="top">
                <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
              </Tooltip>
            )
          }
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          return (
            <DataGridSelectCustom
              {...params}

              enableAddButton={true}
              setValueOptions={setRowsCompaniesStevedore}
              companySelectRefresh={companySelectRefresh}
              classType="addButtonSelect2"
              apiAddButton="company"
              field4={{
                componentName: "CompanyForm",
                componentClass: "companyFormClass",
                buttonDisableControls: true,
                thirdButton:
                  { label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId" },
                initValues:
                  { principals: false, trader: false, insured: false, receiver: false, shipper: false, warehouseKeeper: false, stevedore: true },
              }}
              cached={true}
              cacheTTL="600"
              resetCache="1"

              read={props.canRead('companies_stevedore')}
              write={props.canWrite('companies_stevedore')}
              control={props.workflowLoaded}
              processRowUpdate={companyChangeHandler}
            />
          )
        }
      });
      const columns: GridColDef[] = listColumn;

      return (
        <div>
          <DataGridPro className="datagridCompanies"
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            autoHeight
            density="compact"
            hideFooter={true}
            rows={props.lot.companies}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={companyChangeHandler}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              Toolbar: drawToolbarCompanies,
            }}
          />
        </div>
      );
    }
  }


  //function for change state of extand
  const handleChange = (toggle) => (event: SyntheticEvent) => {
    setExpanded(!toggle);
  }

  //generate index
  var index_lot = props.index;
  if (typeof props.index !== 'string') index_lot = props.index + 1;


  return (
    <div className="receiverLotDiv">
      <Accordion className="receiverLotAccordion" expanded={expanded} onChange={handleChange(expanded)}>
        <AccordionSummary className="receiverLotAccordionSummary" expandIcon={<ExpandMoreIcon />}>
          {(props.canWrite("receiver_block")) ? (
            <IconButton aria-label="delete" onClick={(event) => {
              props.removeLot(lotData.id, props.receiver.id);
            }}>
              <DeleteIcon />
            </IconButton>
          ) : <div className="receiverIconSpacer"></div>}

          {(props.canWrite("receiver_block")) ? (
            <IconButton aria-label="duplicate" onClick={(event) => {
              event.stopPropagation();
              props.duplicateLot(lotData.id, props.receiver.id);
              setExpanded(false);
            }}>
              <ContentCopyIcon />
            </IconButton>
          ) : <div className="receiverIconSpacer"></div>}

          <div className="receiverLotNumberDiv">Lot {index_lot}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="lineContent noJustify">
            {drawLoadingType()}
            {drawBrand()}
            {drawPackaging()}
            {drawNetWeight()}
            {drawGrossWeight()}
          </div>
          <div className="lineContent noJustify">
            {drawQuantity()}
            {drawNetTonnage()}
            {drawGrossTonnage()}
            {drawOrigin()}
            {drawQuality()}
          </div>
          <div className="lineContent noJustify">
            {drawVariety()}
            {drawNumberOfContainer()}
            {drawContainerType()}
            {drawQuantityOfPackingPerContainer()}
            {drawSparePercentage()}
            {drawSpareQuantity()}
          </div>
          <div className="lineContent noJustify">
            {drawBLs()}
            {drawCompanies()}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}