import React, { useState, useEffect, forwardRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import FormControl from '@mui/material/FormControl';
import { randomId } from '@mui/x-data-grid-generator';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

import momentDate from 'moment';

import { isInternalRole, isPortRole } from 'components/config/Roles';
import { DataGridPro, GridToolbarContainer, DataGridDatePickerCustom, DataGridTextCustom } from 'components/page/object/DataGridProCustom';

import MissionDetailsFilePopin from '../Popin/MissionDetailsFilePopin';
import MissionDetailsFormSaveButton from '../MissionDetailsFormSaveButton';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsSubInvoice } from "./ContextMissionDetailsSubInvoice";


import axios from 'api/axios';


const MissionDetailsSubInvoiceForm = forwardRef((props, ref) => {

  //init config
  const navigate = useNavigate();
  const {subInvoiceRef} = useContext(ContextMissionDetails);
  const [JLBPortMode] = useState(isPortRole(localStorage.getItem("role")));
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextMissionDetails);
  const { snackBarType, setSnackBarType } = useContext(ContextMissionDetails);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextMissionDetails);
  const { setSnackErrorMsg } = useContext(ContextMissionDetails);

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { workflowLoaded, setWorkflowLoaded } = useContext(ContextMissionWorkflow);

  //mission general data
  const { idMission, setIdMision } = useContext(ContextMissionDetails);

  //Subcontractor Invoices Grid
  const {rowsSubInvoice, setRowsSubInvoice} = useContext(ContextMissionDetailsSubInvoice);
  const {rowsSubInvoiceDeleted, setRowsSubInvoiceDeleted} = useContext(ContextMissionDetailsSubInvoice);
  const [openPopin, setOpenPopin] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [currentPopinRow, setPopinRow] = useState();
  const [type, setType] = useState();
  const [right, setRight] = useState(0);
  const rightOfFile = [{ id: 2, label: "Team", can_write: 1, value: 2 }];
  const [typesOfFile, setTypesOfFile] = useState([]);

  //context
  const {handleRowEditStop} = useContext(ContextMissionDetails);
  const {handleRowEditStart} = useContext(ContextMissionDetails);
  const {CustomNoRowsOverlay} = useContext(ContextMissionDetails);

  //handle
  const {saveSubInvoices} = useContext(ContextMissionDetailsSubInvoice);
  const {handleClickCancel} = useContext(ContextMissionDetailsSubInvoice);
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    await saveSubInvoices(true);
    await handleClickCancel();
  };


  //useEffect
  useEffect(() => {
    loadFileTypes();
  }, []);


  //function interactive
  const {processRowUpdateSubInvoice} = useContext(ContextMissionDetailsSubInvoice);

  const deleteRowSubInvoice = (event, id) => {
    setRowsSubInvoice(rowsSubInvoice.filter((row) => row.id !== id));
    if (id.toString().length < 16) setRowsSubInvoiceDeleted((oldRows) => [...oldRows, { id: id }]);
  };

  const handleUploadSubinvoiceClick = (row) => {
    //Open File Drop Zone
    setOpenPopin(true);
    setPopinRow(row);
    //INIT MODAL BEFORE
    setFileUpload(null);
    setErrorFile(false);
  };

  const deleteSubInvoiceFile = (delRow) => {
    delRow.file = null;
    setRowsSubInvoice(rowsSubInvoice.map((row) => (row.id === delRow.id ? delRow : row)));
  };

  const handleUploadedFile = (file) => {
    var rows = rowsSubInvoice.map((row) => {
      if (row.number === currentPopinRow.number && !row.file) {
        row.file = { file_name: file.file, id: file.id, file_path: file.url };
        row.id = row.id;
      }
      return row;
    });
    setRowsSubInvoice(rows);
    saveSubInvoices();
  };

  const loadFileTypes = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };

      const response = await axios.get("file_type/list", config);

      //var rowsFromApiTOI = [{value:-1, label:'None'}];
      var rowsFromApiTOI = [];
      for(var i=0; i < response.data.length; i++) {
        if(response.data[i].label == "SUBCONTRACTING INVOICE"){
          rowsFromApiTOI[rowsFromApiTOI.length] = {value:response.data[i].id, label:response.data[i].label};
          setType(Number(response.data[i].id));
        }
      }
      setTypesOfFile(rowsFromApiTOI);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const editFile = (file) => {
    window.open(file.file_path, "_blank");
  };

  //xGrid
  var listColumnSubInvoice = [], canWriteSubInvoice = canWrite("subInvoice_block");
  if (canWriteSubInvoice) {
    listColumnSubInvoice.push({
      field: 'id', headerName: '', editable: false, sortable: false, width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton aria-label="delete" onClick={(event) => {
            deleteRowSubInvoice(event, cellValues.id);
          }}>
            <DeleteIcon />
          </IconButton>
        );
      }
    });
  }
  listColumnSubInvoice.push({
    field: 'number', headerName: 'Subcontracting Invoice Number', width: 250, sortable: true, flex: 1,
    editable: (canWriteSubInvoice ? true : false),
    renderCell: (cellValues) => {
      if (canWriteSubInvoice && cellValues.value == "") return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      )
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <DataGridTextCustom
          {...params}
          read={canRead('subInvoice_block')}
          write={canWrite('subInvoice_block')}
          control={workflowLoaded}
          processRowUpdate={processRowUpdateSubInvoice}
        />
      )
    }
  },
    {
      field: 'date', headerName: 'Subcontracting Invoice Date', width: 250, sortable: true, type: "date",
      editable: (canWriteSubInvoice ? true : false),
      valueFormatter: params => momentDate(params?.value).format("DD/MM/YYYY"),
      renderCell: (cellValues) => {
        if (canWriteSubInvoice && cellValues.value == "") return (
          <Tooltip title="Double click to edit" placement="top">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </Tooltip>
        )
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            read={canRead('subInvoice_block')}
            write={canWrite('subInvoice_block')}
            control={workflowLoaded}
            processRowUpdate={processRowUpdateSubInvoice}
          />
        )
      }
    });
  if (canWriteSubInvoice) {
    listColumnSubInvoice.push({
      field: 'file', headerName: 'File', editable: false, width: 250, sortable: false,
      renderCell: (cellValues) => {
        //Displaying upload button only if no existing file
        if (cellValues.row.file == null) {
          return (
            <IconButton aria-label="upload" onClick={(event) => {
              handleUploadSubinvoiceClick(cellValues.row);
            }}>
              <UploadFileIcon />
            </IconButton>);
        } else {
          if (cellValues.row.file != null) {
            return (
              <>
                <span className="subInvoiceFilenameSpan">{cellValues.row.file.file_name}</span>
                {(JLBInternalMode) && (
                  <IconButton aria-label="delete" onClick={(event) => {
                    deleteSubInvoiceFile(cellValues.row);
                  }}>
                    <ClearIcon />
                  </IconButton>
                )}

                {(JLBInternalMode) && (
                  <IconButton aria-label="edit" onClick={(event) => {
                    editFile(cellValues.row.file);
                  }}>
                    <EditIcon />
                  </IconButton>
                )}
              </>
            );
          }
        }
      }
    });
  }
  const columnsSubInvoice: GridColDef[] = listColumnSubInvoice;

  function EditToolbarSubInvoice(props) {
    const { setRowsSubInvoice } = props;

    const handleClick = () => {
      const id = randomId();
      setRowsSubInvoice((oldRows) => [...oldRows, { id: id, number: '', date: '', file: null }]);
    };

    if (canWriteSubInvoice) {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              Add Subcontracting Invoice
            </Button>
          </div>
        </GridToolbarContainer>
      );
    } else {
      return (
        <GridToolbarContainer className="DataGridToolbar">
          <div className="toolbarButton left">
            <div className="iconAddButton">
              Subcontracting Invoice
            </div>
          </div>
        </GridToolbarContainer>
      );
    }
  }


  return (
    <div>

      {(Boolean(canWrite("subInvoice_block") || canRead("subInvoice_block")) && !JLBPortMode) && 
        <div>
          <div className="lineContent">
            <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-subinvoice">
              <DataGridPro
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                autoHeight
                hideFooter={true}
                rows={rowsSubInvoice}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdateSubInvoice}
                columns={columnsSubInvoice}
                experimentalFeatures={{ newEditingApi: true }}
                componentsProps={{
                  toolbar: { setRowsSubInvoice },
                }}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: EditToolbarSubInvoice,
                }}
              />
            </FormControl>
          </div>
        
          <MissionDetailsFilePopin 
            idMission={idMission}
            handleUploadedFile={handleUploadedFile}
            setSnackBarMessage={setSnackBarMessage}
            setSnackBarType={setSnackBarType}
            setOpenSnackBar={setOpenSnackBar}
            setOpen={setOpenPopin}
            setFileUpload={setFileUpload}
            setPermission={setRight}
            setType={setType}
            setErrorFile={setErrorFile}
            open={openPopin}
            permission={right}
            type={type}
            fileUpload={fileUpload}
            errorFile={errorFile} 
            filePermissions={rightOfFile}
            typesOfFile={typesOfFile}
            disableTypeNone={'true'}
          />
        </div>
      }

      <MissionDetailsFormSaveButton blockRef={subInvoiceRef} blockSave={handleClickSave}/>

    </div>
  );
});

export default MissionDetailsSubInvoiceForm;
