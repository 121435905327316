import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";

import MissionDetailsGeneralForm from './MissionDetailsGeneralForm';
import MissionDetailsGeneralView from "./MissionDetailsGeneralView";


const MissionDetailsGeneral = forwardRef((props, ref) => {

  //workflow
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);
  const {controlFieldsGeneral, setControlFieldsGeneral} = useContext(ContextMissionWorkflow);
  const {toogleBlockOnEditAndCheckBlockOnWorkflowError} = useContext(ContextMissionWorkflow);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const {blockEdit, setBlockEdit} = useContext(ContextMissionDetails);


  //useEffect
  useEffect(() => {
    toogleBlockOnEditAndCheckBlockOnWorkflowError(ref, onEdit, setOnEdit, controlFieldsGeneral);
  }, [blockEdit, controlFieldsError]);

  
  return (
    <div id="block_general" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
      <h4>General Informations</h4>

      {(!onEdit) ? <MissionDetailsGeneralView/> : <MissionDetailsGeneralForm/> }
      
    </div>
  );
});

export default MissionDetailsGeneral;
