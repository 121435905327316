import React, { useState, useEffect, createContext, useContext } from "react";

import { ContextMissionDetails } from "context/ContextMissionDetails";


const ContextMissionDetailsReceiverBL = createContext();

const MissionDetailsReceiverBLProvider = ({ children }) => {

  //config
  const { blRef } = useContext(ContextMissionDetails);
  const { receiverRef } = useContext(ContextMissionDetails);

  //treatment
  const [reloadDataBL, setReloadDataBL] = useState(0);
  const [oldReloadDataBL, setOldReloadDataBL] = useState(0);
  const [reloadDataReceiver, setReloadDataReceiver] = useState(0);
  const [oldReloadDataReceiver, setOldReloadDataReceiver] = useState(0);
  const [blockBlEdit, setBlockBlEdit] = useState({});
  const [blockReceiverEdit, setBlockReceiverEdit] = useState({});
  const {blockEdit, setBlockEdit} = useContext(ContextMissionDetails);
  const {setReloadBlockData} = useContext(ContextMissionDetails);

  //init receiver data
  const {receivers, setReceivers} = useContext(ContextMissionDetails);

  const [rowsReceiver, setRowsReceiver] = useState([]);
  const [rowsReceiversDataGrid, setRowsReceiversDataGrid] = useState([]);
  const {rowsReceiversInvoice, setRowsReceiversInvoice} = useContext(ContextMissionDetails);

  //init BL data
  const {bls, setBLs} = useContext(ContextMissionDetails);

  const [rowsBL, setRowsBL] = useState([]);
  const [newRowsBL, setNewRowsBL] = useState([]);
  const [deletedRowsBL, setDeletedRowsBL] = useState([]);


  //useEffect
  //INIT receivers
  useEffect(() => {
    //load data
    if (receivers) {
      if (receivers.length > 0) {
        //dont update data if edition is in progress
        var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === receiverRef && obj.onEdit === false));
        if (blockInEdit >= 0) {
          //(RE)INIT detect change
          if (reloadDataReceiver > oldReloadDataReceiver) {
            setOldReloadDataReceiver(reloadDataReceiver);
          }
          
          const rowsFromApiReceivers = receivers;
          const rowsInvoiceReceivers = [], rowsReceiversIds = [], rowsReceiversDataGrid = [];
          for (var i=0; i < rowsFromApiReceivers.length; i++) {
            var rcvrId = (rowsReceiversIds.length > 0) ? rowsReceiversIds.findIndex(obj => obj.id === rowsFromApiReceivers[i].receiver.id) : -1;
            if(rcvrId < 0){
              rowsInvoiceReceivers.push(createReceiversInvoiceHandler(rowsFromApiReceivers[i]));
              rowsReceiversDataGrid.push(createReceiversDataGridHandler(rowsFromApiReceivers[i]));
              rowsReceiversIds.push({id:rowsFromApiReceivers[i].receiver.id});
            }
          }
          rowsFromApiReceivers.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
          setRowsReceiver(rowsFromApiReceivers);
          setRowsReceiversInvoice(rowsInvoiceReceivers);
          setRowsReceiversDataGrid(rowsReceiversDataGrid);
        }
      }
    }
  }, [receivers, reloadDataReceiver]);

  //INIT Bills of lading
  useEffect(() => {
    //load data
    if (bls) {
      //dont update data if edition is in progress
      var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === blRef && obj.onEdit === false));
      if (blockInEdit >= 0) {
        var rowsFromApi = [];
        if (bls.length > 0) {
          for (var i=0; i < bls.length; i++) {
            var type_of_intervention_id = ""; var beans_type_id = ""; var comment = "";
            if (bls[i].type_of_intervention) type_of_intervention_id = bls[i].type_of_intervention.id;
            if (bls[i].beans_type) beans_type_id = bls[i].beans_type.id;
            if (bls[i].note) comment = bls.note;
            rowsFromApi[i] = { 
              id: bls[i].id, 
              bol: bls[i].number, 
              quantity: bls[i].quantity, 
              deadline_date: bls[i].deadline_date, 
              delivery_date: bls[i].delivery_date, 
              smv: bls[i].smv, 
              type_of_intervention: type_of_intervention_id, 
              beans_type: beans_type_id, 
              note: comment, 
              signature_place: bls[i].country, 
              country: bls[i].country, 
              bl_date: bls[i].bl_date,
              isExpanded : false
            };
          }
          rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
        }
        setRowsBL(rowsFromApi);
      }
    }
  }, [bls, reloadDataBL]);

  //init block edit
  useEffect(() => {
    //maj reload state of Receiver
    if (reloadDataReceiver == oldReloadDataReceiver) {
      if (blockReceiverEdit?.reload === true) {
        setReloadDataReceiver(reloadDataReceiver + 1);
        setReloadBlockData(receiverRef, false);
      }
    }
    //maj reload state of BL
    if (reloadDataBL == oldReloadDataBL) {
      if (blockBlEdit?.reload === true) {
        setReloadDataBL(reloadDataBL + 1);
        setReloadBlockData(blRef, false);
      }
    }
  }, [blockBlEdit, blockReceiverEdit]);

  //check block edit
  useEffect(() => {
    //load receiver block
    var blockCurrentIndex  = blockEdit.findIndex(obj => obj.blockRef === receiverRef);
    if (blockCurrentIndex >= 0) {
      if (blockReceiverEdit !== blockEdit[blockCurrentIndex]) {
        setBlockReceiverEdit(blockEdit[blockCurrentIndex]);
      }
    }
    //load bl block
    var blockCurrentIndex  = blockEdit.findIndex(obj => obj.blockRef === blRef);
    if (blockCurrentIndex >= 0) {
      if (blockBlEdit !== blockEdit[blockCurrentIndex]) {
        setBlockBlEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);


  //function interactive
  function createReceiversInvoiceHandler(data) {
    return {value:data.receiver.id, label:data.receiver.name.toUpperCase().trim()};
  }

  function createReceiversDataGridHandler(data) {
    return {value:data.id, label:data.receiver.name.toUpperCase().trim()};
  }


  return (
    <ContextMissionDetailsReceiverBL.Provider
      value=
      {{
        //receiver
        rowsReceiver, setRowsReceiver,
        rowsReceiversDataGrid, setRowsReceiversDataGrid,

        //BL
        rowsBL, setRowsBL,
        newRowsBL, setNewRowsBL,
        deletedRowsBL, setDeletedRowsBL,
        reloadDataBL, setReloadDataBL,
        oldReloadDataBL, setOldReloadDataBL
      }}
    >
      {children}
    </ContextMissionDetailsReceiverBL.Provider>
  );
};

export { MissionDetailsReceiverBLProvider, ContextMissionDetailsReceiverBL };
