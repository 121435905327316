import React, { useState, useContext, useEffect } from 'react';

import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButtonCustom from 'components/page/object/LoadingButtonCustom';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";


const MissionDetailsWorkflowSaveButton = (props) => {

  //generic
  const {loading, setLoading} = useContext(ContextMissionDetails);
  const {blockEdit, setBlockEdit} = useContext(ContextMissionDetails);

  //workflow
  const { controlFieldsErrorTruncate } = useContext(ContextMissionWorkflow);
  const { controlFields, setControlFields } = useContext(ContextMissionWorkflow);
  const { controlButtons, setControlButtons } = useContext(ContextMissionWorkflow);
  const { workflowLoaded, setWorkflowLoaded } = useContext(ContextMissionWorkflow);
  const { idButtonWorkflow, setIdButtonWorkflow } = useContext(ContextMissionWorkflow);
  const { checkWorkflowData, setCheckWorkflowData } = useContext(ContextMissionWorkflow);
  const { fieldsSettingsLoaded, setFieldsSettingsLoaded } = useContext(ContextMissionWorkflow);

  //handle button
  const [show, setShow] = useState(workflowLoaded ? true : false);
  const {handleClickSave} = useContext(ContextMissionDetailsTreatment);
  const handleClickWorkflow = async (id_button) => {
    setIdButtonWorkflow(null);
    setCheckWorkflowData(true);
    await controlFieldsErrorTruncate(controlFields.map((field)=>{return field.id}));
    handleClickSave(false, id_button);
  }


  //useEffect
  useEffect(() => {
    //maj show state
    var newStateEdit = workflowLoaded ? true : false;
    if (show != newStateEdit) {
      setShow(newStateEdit);
    }
  }, [workflowLoaded]);


  return(
    <div className={!show ? 'workflowSaveButton opacity' : 'workflowSaveButton opacity show'}>
      <Box sx={{margin: 'auto'}}>
        <Box sx={{ '& > button': { m: 1 } }} className="loadingButtonBlock">
          <LoadingButtonCustom 
            buttons={controlButtons}
            index={0}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={1}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={2}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={3}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={4}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={5}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={6}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={7}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded} 
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={8}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={9}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />

          <LoadingButtonCustom 
            buttons={controlButtons}
            index={10}
            icon={<SaveIcon />}
            loadingPosition="end"
            loading={loading}
            controlFieldsStatus={fieldsSettingsLoaded}
            handleClick={handleClickWorkflow}
            disabled={loading}
          />
        </Box>
      </Box>
    </div>
  );
}

export default MissionDetailsWorkflowSaveButton
