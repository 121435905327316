import React, { useState, useContext, useEffect, forwardRef } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';

import {isPortRole, isCustomerRole} from 'components/config/Roles';
import TextFieldCustom from 'components/select/TextFieldCustom';
import MissionDetailsFormSaveButton from '../MissionDetailsFormSaveButton';

import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";
import { MissionDetailsNoteProvider, ContextMissionDetailsNote } from "./ContextMissionDetailsNote";


const MissionDetailsNote = forwardRef((props, ref) => {

  //config
  const { update } = useContext(ContextMissionDetails);
  const { noteRef } = useContext(ContextMissionDetails);
  const [JLBPortMode] = useState(isPortRole(localStorage.getItem("role")));
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextMissionDetails);

  //workflow
  const { canRead } = useContext(ContextMissionWorkflow);
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { isRequired } = useContext(ContextMissionWorkflow);
  const {controlFieldsNote, setControlFieldsNote} = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);
  const {toogleBlockOnEditAndCheckBlockOnWorkflowError} = useContext(ContextMissionWorkflow);
  const { fieldsSettingsLoaded, setFieldsSettingsLoaded } = useContext(ContextMissionWorkflow);
  const { getControlFieldsErrorMsg, setGetControlFieldsErrorMsg } = useContext(ContextMissionWorkflow);
  const { getControlFieldsErrorState, setGetControlFieldsErrorState } = useContext(ContextMissionWorkflow);

  //context
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  const {publicComment, setPublicComment} = useContext(ContextMissionDetails);
  const {internalComment, setInternalComment} = useContext(ContextMissionDetails);

  //block const
  const [onEdit, setOnEdit] = useState(false);
  const {blockEdit, setBlockEdit} = useContext(ContextMissionDetails);

  //handle
  const {handleClickBlockEdit} = useContext(ContextMissionDetailsTreatment); 


  //useEffect
  useEffect(() => {
    toogleBlockOnEditAndCheckBlockOnWorkflowError(ref, onEdit, setOnEdit, controlFieldsNote);
  }, [blockEdit, controlFieldsError]);

  
  //function interactive
  const handleChangePublicComment = (event) => setPublicComment(event.target.value);
  const handleChangeInternalComment = (event) => setInternalComment(event.target.value);

  
  if (((canWrite("internal_note") || canRead("internal_note")) 
  || (canWrite("public_note") || canRead("public_note")))) {
    return (
      <div id="block_note" className={(!onEdit) ? 'contentDetail block_details' : 'contentDetail block_details editing'}>
        <h4>Notes</h4>
      
          <div className="lineContent">
            {(!JLBCustomerMode) &&
            <FormControl className="textField-form-comment" sx={{ m: 1, width: '100%' }} size="small">
              <TextFieldCustom
                id="standard-multiline-internal"
                label="Internal note"
                multiline
                value={internalComment}
                onBlur={handleChangeInternalComment}
                variant="standard"
                fullWidth
                read={canRead("internal_note")}
                write={onEdit && canWrite("internal_note")}
                required={isRequired("internal_note")}
                controlFieldsStatus={onEdit ? fieldsSettingsLoaded : true}
                error={getControlFieldsErrorState("internal_note")}
                helperText={getControlFieldsErrorMsg("internal_note")}
              />
            </FormControl>
            }
          </div>

          <div className="lineContent">
            <FormControl className="textField-form-comment" sx={{ m: 1, width: '100%' }} size="small">
              <TextFieldCustom
                id="standard-multiline-public"
                label="Public note"
                multiline
                value={publicComment}
                onBlur={handleChangePublicComment}
                variant="standard"
                fullWidth
                read={canRead("public_note")}
                write={onEdit && canWrite("public_note")}
                required={isRequired("public_note")}
                controlFieldsStatus={onEdit ? fieldsSettingsLoaded : true}
                error={getControlFieldsErrorState("public_note")}
                helperText={getControlFieldsErrorMsg("public_note")}
              />
            </FormControl>
          </div>

          {Boolean((canWrite("internal_note") || canWrite("public_note")) && 
          !onEdit && update == 1 && loadingUpdate && !missionCanceled && !JLBCustomerMode && !JLBPortMode) &&  
          <div className="block_button">
            <LoadingButton className="edit" onClick={()=>handleClickBlockEdit(noteRef)} loadingPosition="start" startIcon={<EditIcon/>}> Edit</LoadingButton>
          </div>
          }

          <ContextMissionDetailsNote.Consumer>
          {({saveMissionDetailsNote, handleClickCancel}) => (
            <>
            {(onEdit && update == 1 && !missionCanceled) && 
            <MissionDetailsFormSaveButton blockRef={noteRef} 
              blockCanel={async ()=> await handleClickCancel()} 
              blockSave={async () => {
                //Remove focus from any focused element
                if (document.activeElement) {
                  document.activeElement.blur();
                }
                //saving
                await saveMissionDetailsNote(true);
                await handleClickCancel();
              }}
            />
            }
            </>
          )}
          </ContextMissionDetailsNote.Consumer>

      </div>
    );
  }
});

export default MissionDetailsNote;
