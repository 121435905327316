import React from 'react';
import {isInternalRole, isPortRole, isCustomerRole} from 'components/config/Roles';
import DetailField from 'components/page/object/DetailField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LinearProgress from '@mui/material/LinearProgress';
import AddElement from 'components/page/object/AddElement';

const SelectCustomRefresh = (props) => {
  const [JLBCustomerMode] = React.useState(isCustomerRole(localStorage.getItem("role")));

  var canRead = 1;
  var canWrite = 1;
  var isRequired = false;

  if (props.controlFieldsStatus) {
    canRead = props.read;
    canWrite = props.write;
    isRequired = props.required;
  } else if (typeof props.required != "undefined") {
    isRequired = props.required;
  }
  
  var padding="0px";
  var addButton=false;

  if (props.setNewElement) {
    padding="30px";
    addButton=true;
  }

  var disableNone = (typeof props.disableNone != "undefined") ? props.disableNone : false;
  if (isRequired) disableNone = "true";

  if (canWrite) {
    return (
    <FormControl sx={{ m: 1, width: '18%', paddingRight:padding }} size="small" disabled={props.disabled} error={props.error} required={isRequired}>
      <InputLabel id="demo-select-small">{props.label}</InputLabel>
      {addButton && 
      <AddElement
        {...props}
        classType="addButtonSelect1"
        disabled={props.disabledButton}
        title={props.label}
        datas={props.list}
      />}
      <Select
        labelId="select-custom-label"
        id="select-localSurveyor-id"
        value={props.value}
        label={props.label}
        error={props.error}
      >
      {!Boolean(disableNone) && (<MenuItem value="0" onClick={props.onClickSelect}><em>None</em></MenuItem>)}
      {props.list.map((dataItem) => (
        <MenuItem key={dataItem.id} value={dataItem.id} onClick={props.onClickSelect}>{dataItem.label}</MenuItem>
      ))}
      </Select>
      {(props.error && (!!props.helperText != false)) && <FormHelperText sx={{ ml: 0}}>{props.helperText}</FormHelperText>}
      <LinearProgress hidden={props.hidden}  />
    </FormControl>
    );
  }

  if (canRead) {
    var theValue = "-";
    if (props.value > 0 && props.list) {
      var itemId = props.list.findIndex(obj => obj.id === Number(props.value));
      if (itemId >= 0) {
        theValue = props.list[itemId].label.toUpperCase().trim();
      }
    }

    return (<DetailField title={props.label} content={theValue} size={!JLBCustomerMode ? '':'large'} canRead={canRead} />);
  }
}
export default SelectCustomRefresh
